import { getColorByType, getDiningText, getPoiType } from "../../util";

import { HighlightsIndexPOIType } from "../Weekly/type";
import { IndexPOIType } from "./types";
import KakaoNaviIcon from "../../assets/Trip/kakaoNavi.png";
import PoiBlog from "./PoiBlog";
import { ReactElement } from "react";
import Slider from "react-slick";
import { ReactComponent as StarIcon } from "../../assets/Trip/star.svg";
import { ReactComponent as StarWhite } from "../../assets/Trip/star-white.svg";
import TmapIcon from "../../assets/Trip/tmap.jpg";
import { ReactComponent as XWIcon } from "../../assets/Trip/xW.svg";

const Info = ({
  title,
  children,
}: {
  title: string;
  children: ReactElement;
}) => (
  <div className="bg-gray-100 mt-4">
    <div className="mt-3 pt-3 w-full">
      <div className="bg-white pt-3 pb-6 w-full">
        <div className="font-medium text-lg pl-4">{title}</div>
        {children}
      </div>
    </div>
  </div>
);

const Rating = ({
  url,
  rating,
  count,
  title,
}: {
  url: string | null;
  rating: string;
  count: number | null;
  title: string;
}) => (
  <a
    className="flex-1"
    href={url ? url : "/#"}
    target="_blank"
    onClick={(e) => {
      if (!url) {
        e.preventDefault();
      }
    }}
  >
    <div className="text-xs text-gray-800 font-medium">{title}</div>
    <div className="text-xs flex item-center mt-1">
      <StarIcon className="h-4" />
      <div className="ml-1 text-gray-900 font-bold">{rating}</div>
      <div className="text-gray-500 font-medium">{`/5 ${
        count ? `(${count.toLocaleString()})` : ""
      }`}</div>
    </div>
  </a>
);

const removeHtmlTagOfTitle = (title: string): string =>
  title.replace("<b>", "").replace("</b>", "");

const PoiPage = ({
  poi,
  close,
}: {
  poi: IndexPOIType | HighlightsIndexPOIType;
  close: () => void;
}) => {
  return (
    <div className="w-full sm:w-screen sm:flex sm:justify-center h-full">
      <div
        className={`w-full sm:w-4/5 sm:max-w-3xl sm:px-12 h-full relative`}
        style={{ maxHeight: "calc(100vh - 14rem)" }}
      >
        <button className="absolute -top-6 right-1 sm:right-14" onClick={close}>
          <XWIcon />
        </button>
        <div
          className={`w-full h-full ${
            poi.reservationUrl ? "pb-10 sm:pb-14" : ""
          } overflow-y-auto pt-6 bg-white rounded-md`}
          style={{ maxHeight: "calc(100vh - 14rem)" }}
        >
          <div className="flex items-start px-4">
            <div
              className={`ml-1.5 px-2.5 h-8 rounded flex items-center justify-center border border-${getColorByType(
                poi.poiType
              )}`}
            >
              {poi.index}
            </div>
            <div
              className={`ml-1.5 h-8 px-2.5 rounded flex items-center bg-${getColorByType(
                poi.poiType
              )} text-white`}
            >
              {getDiningText(poi.poiType, poi.type)}
            </div>
            <div className="flex-1"></div>
            <a
              className="sm:hidden"
              target="_blank"
              href={`https://apis.openapi.sk.com/tmap/app/routes?appKey=${process.env.REACT_APP_TMAP_APP_KEY}&name=${poi.name}&lon=${poi.longitude}&lat=${poi.latitude}`}
            >
              <img className="h-8 w-8 ml-2" src={TmapIcon} />
            </a>
            {navigator.userAgent !== "YodaReactNative" && (
              <button
                className="sm:hidden"
                onClick={() => {
                  // @ts-ignore
                  if (!Kakao.isInitialized()) {
                    // @ts-ignore
                    Kakao.init(process.env.REACT_APP_KAKAO_CLIENT_ID);
                  }
                  // @ts-ignore
                  Kakao.Navi.start({
                    name: poi.name,
                    // @ts-ignore
                    x: parseFloat(poi.longitude),
                    // @ts-ignore
                    y: parseFloat(poi.latitude),
                    coordType: "wgs84",
                  });
                }}
              >
                <img className="h-8 w-8 ml-2" src={KakaoNaviIcon} />
              </button>
            )}
          </div>
          <div className="mt-2.5 px-4">
            <img
              className="max-w-full max-h-40 mx-auto"
              src={poi.detail.mainImageUrl}
            />
          </div>
          <div className="mt-2.5 font-bold text-lg px-4">
            {poi.name}
            <span className="ml-1 text-sm text-gray-400 font-medium">
              {getPoiType(poi)}
            </span>
          </div>
          {poi.detail.description && (
            <div className="mt-1 text-sm text-gray-800 px-4">
              {poi.detail.description}
            </div>
          )}
          {(poi.detail.naverRating !== null ||
            poi.detail.googleRating !== null) && (
            <div className="px-4">
              <div className="mt-12 flex items-center h-14 px-4 w-full bg-gray-100">
                {poi.detail.naverRating !== null && (
                  <Rating
                    url={poi.naverUrl}
                    rating={poi.detail.naverRating}
                    count={poi.detail.naverRatingCount}
                    title="네이버"
                  />
                )}
                {poi.detail.googleRating !== null && (
                  <Rating
                    url={poi.googleUrl}
                    rating={poi.detail.googleRating}
                    count={poi.detail.googleRatingCount}
                    title="구글"
                  />
                )}
              </div>
            </div>
          )}
          {poi.detail.recommendationTags.length > 0 && (
            <Info title="이래서 골랐어요">
              <div className="flex flex-wrap w-full pt-1 text-sm text-white px-4">
                {poi.detail.recommendationTags.map((tag) => {
                  return (
                    <div
                      className={`${
                        tag.type === "covidFree" ||
                        (tag.tag === "농림축산식품부 안심식당" &&
                          tag.type === "source")
                          ? "bg-yoda-primary"
                          : "bg-gray-400"
                      } rounded-full py-2 px-5 mt-2 mr-3 text-center flex items-center`}
                      style={{ minWidth: "3rem" }}
                    >
                      {tag.type === "source" && (
                        <StarWhite className="h-3.5 w-3.5 sm:h-5 sm:w-5 mr-1 sm:mr-1.5" />
                      )}
                      <div>{tag.tag}</div>
                    </div>
                  );
                })}
              </div>
            </Info>
          )}
          <Info title="일반정보">
            <div className="font-medium text-sm mt-3 px-4">
              {poi.address && (
                <div className="leading-8">
                  주소{" "}
                  <span className="font-normal text-gray-800">
                    {poi.address}
                  </span>
                </div>
              )}
              {poi.detail.telephone && (
                <div className="leading-8">
                  번호{" "}
                  <span className="font-normal text-gray-800">
                    <a
                      className="underline"
                      href={`tel:${poi.detail.telephone}`}
                    >
                      {poi.detail.telephone}
                    </a>
                  </span>
                </div>
              )}
              {poi.detail.openHours && (
                <div className="leading-8">
                  시간{" "}
                  <span className="font-normal text-gray-800">
                    {poi.detail.openHours}
                  </span>
                </div>
              )}
              {poi.websiteUrl && (
                <div className="leading-8">
                  웹{" "}
                  <span className="font-normal text-gray-800">
                    <a
                      className="underline"
                      href={
                        poi.websiteUrl.includes("http")
                          ? poi.websiteUrl
                          : `http://${poi.websiteUrl}`
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {poi.websiteUrl}
                    </a>
                  </span>
                </div>
              )}
            </div>
          </Info>
          {poi.detail.articleUrls.length > 0 && (
            <Info title="기사와 영상">
              <div className="font-medium text-sm mt-3 px-4">
                {poi.detail.articleUrls.map((article) => (
                  <a href={article.url} target="_blank">
                    <div className="flex w-full mb-3">
                      <img
                        src={article.imageUrl}
                        alt="미리보기"
                        className="w-24 h-14 object-cover rounded"
                      />
                      <div className="flex-1 min-w-0 pl-3 text-sm">
                        <div className="truncate">{`여다가 고른 ${
                          article.type === "youtube" ? "영상" : "기사"
                        }`}</div>
                        <div className="text-gray-400 line-clamp-2 font-normal">
                          {article.title}
                        </div>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </Info>
          )}
          {poi.detail.menu !== null &&
            poi.detail.menu.type === "object" &&
            poi.detail.menu.content !== null &&
            poi.detail.menu.content.length > 0 && (
              <Info title="메뉴">
                <div className="font-medium text-sm mt-1.5 px-4">
                  {poi.detail.menu.content.map((menu) => (
                    <div
                      key={menu.name}
                      className="flex items-center font-medium text-sm"
                    >
                      <div className="mr-2.5" style={{ maxWidth: "50%" }}>
                        {menu.name}
                      </div>
                      <div className="flex-grow border-t border-dashed border-gray-200"></div>
                      <div
                        className="ml-2.5 text-yoda-secondary"
                        style={{ maxWidth: "50%" }}
                      >
                        {menu.price}
                      </div>
                    </div>
                  ))}
                </div>
              </Info>
            )}
          {poi.detail.naverBlogs.length > 0 ? (
            <Info title="Blog">
              <Slider
                dots={true}
                infinite={true}
                speed={500}
                slidesToShow={1}
                slidesToScroll={1}
                accessibility={true}
                arrows={false}
                className="w-full h-full overflow-visible pb-6 pl-0 pr-0 px-4"
                rows={3}
              >
                {poi.detail.naverBlogs.map((blog, blogIdx) => (
                  <PoiBlog
                    title={removeHtmlTagOfTitle(blog.title)}
                    link={blog.url}
                    thumbnail={blog.imageUrl}
                    id={String(blogIdx)}
                  />
                ))}
              </Slider>
            </Info>
          ) : null}
        </div>
        {poi.reservationUrl && (
          <a
            href={
              poi.reservationUrl.includes("http")
                ? poi.reservationUrl
                : `http://${poi.reservationUrl}`
            }
            target="_blank"
            rel="noreferrer"
          >
            <div className="w-full px-4 sm:px-16 absolute left-0 bottom-3 sm:bottom-5">
              <div className="w-full h-10 rounded bg-yoda-secondary opacity-80 text-white text-center leading-10">
                예약하기
              </div>
            </div>
          </a>
        )}
      </div>
    </div>
  );
};
export default PoiPage;
