import { ReactComponent as CarBIcon } from "../../assets/Trip/transportation/carB.svg";
import { ReactComponent as CarWIcon } from "../../assets/Trip/transportation/carW.svg";
import { ReactComponent as FlightBIcon } from "../../assets/Trip/transportation/flightB.svg";
import { ReactComponent as FlightWIcon } from "../../assets/Trip/transportation/flightW.svg";
import { ReactComponent as LeftIcon } from "../../assets/Trip/transportation/left.svg";
import React from "react";
import { ReactComponent as RightIcon } from "../../assets/Trip/transportation/right.svg";
import { ReactComponent as TaxiBIcon } from "../../assets/Trip/transportation/taxiB.svg";
import { ReactComponent as TaxiWIcon } from "../../assets/Trip/transportation/taxiW.svg";
import { ReactComponent as TrainBIcon } from "../../assets/Trip/transportation/trainB.svg";
import { ReactComponent as TrainWIcon } from "../../assets/Trip/transportation/trainW.svg";
import { ReactComponent as WalkBIcon } from "../../assets/Trip/transportation/walkB.svg";
import { ReactComponent as WalkWIcon } from "../../assets/Trip/transportation/walkW.svg";

const getIcon = (type: number, color: string) => {
  // "car", "bus", "train", "flight", "walk"
  if (color === "black") {
    if (type === 1) return CarBIcon;
    else if (type === 4) return FlightBIcon;
    else if (type === 5) return WalkBIcon;
    else if (type === 6) return TaxiBIcon;
    else return TrainBIcon;
  } else {
    if (type === 1) return CarWIcon;
    else if (type === 4) return FlightWIcon;
    else if (type === 5) return WalkWIcon;
    else if (type === 6) return TaxiWIcon;
    else return TrainWIcon;
  }
};

const Transportation = ({ type }: { type: number }) => {
  const Icon = getIcon(type, "black");
  return (
    <div className="flex items-center">
      <LeftIcon className="h-1 w-1 mr-1" />
      <div className="w-8 h-8 rounded-full border border-gray-400">
        <div className="flex w-full h-full items-center justify-center">
          <Icon className="h-4" />
        </div>
      </div>
      <RightIcon className="h-1 w-1 ml-1" />
    </div>
  );
};

export default Transportation;
