import React from "react";

const SurveyItem = ({
  whitespace,
  title,
  text,
}: {
  whitespace: string;
  title: string;
  text: string;
}) => {
  return (
    <div className="px-4">
      <div className="mt-5 text-sm text-gray-900 font-medium">{title}</div>
      <div
        className={`mt-2 px-2.5 w-full leading-9 bg-gray-100 text-gray-800 text-sm ${whitespace}`}
      >
        {text}
      </div>
    </div>
  );
};

export default SurveyItem;
