import { IndexBlockType, IndexPOIType } from "./types";
import { getColorByType, getDiningText } from "../../util";
import { useEffect, useState } from "react";

import AnimateHeight from "react-animate-height";
import { ReactComponent as DownIcon } from "../../assets/Trip/down.svg";
import { ReactComponent as UpIcon } from "../../assets/Trip/up.svg";

const getTransportationString = (type: number) => {
  // "car", "bus", "train", "flight", "walk"
  if (type === 1) return "자동차";
  else if (type === 5) return "도보";
  // else if (type === 4) return "비행기";
  else return "대중교통";
};

const BriefBlock = ({
  block,
  setPoi,
  setShowAlternativeIds,
  index,
}: {
  block: IndexBlockType;
  setPoi: (detailBlock: IndexPOIType) => void;
  setShowAlternativeIds: (ids: (prevState: number[]) => number[]) => void;
  index: number;
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    if (drawerOpen) {
      setShowAlternativeIds((ids) => {
        const newIds = [...ids];
        block.alternatives.forEach((b) => {
          newIds.push(b.id);
        });
        return newIds;
      });
    } else {
      setShowAlternativeIds((ids) => {
        const newIds = [...ids];
        block.alternatives.forEach((b) => {
          const index = newIds.findIndex((id) => id === b.id);
          newIds.splice(index, 1);
        });
        return newIds;
      });
    }
  }, [drawerOpen]);

  return (
    <div id={`brief-block-${index}`} className="w-full flex flex-col">
      <div className="ml-9 w-14 h-6">
        {index > 0 && (
          <div className="h-full border-r border-gray-400 w-px mx-auto"></div>
        )}
      </div>
      <button
        className="w-full px-4 text-left"
        onClick={() => setPoi(block.main)}
      >
        <div className="pl-5">
          <div className="flex items-start">
            <div className="self-stretch flex flex-col relative">
              <div className="w-14 h-14">
                <img
                  className="w-full h-full rounded-full object-cover"
                  src={block.main.detail.mainImageUrl}
                />
              </div>
              {block.traveltime !== null && (
                <div
                  className="flex-grow border-r border-gray-400 w-px mx-auto"
                  style={{ minHeight: "3.5rem" }}
                ></div>
              )}
              {block.traveltime !== null && block.transportation !== null && (
                <div className="absolute bottom-1 w-full flex justify-center">
                  <div className="px-2 py-1 rounded-full bg-gray-100 text-gray-900 text-xs font-medium text-center">
                    <div style={{ fontSize: "0.5rem" }}>
                      {getTransportationString(block.transportation)}
                    </div>
                    <div>{block.traveltime}분</div>
                  </div>
                </div>
              )}
            </div>
            <div className="ml-2.5 flex-grow">
              <div className="flex items-center font-medium text-sm">
                {block.main.index}.
                <span
                  className={`ml-1.5 text-${getColorByType(
                    block.main.poiType
                  )}`}
                >
                  {getDiningText(block.main.poiType, block.main.type)}
                </span>
              </div>
              <div className="mt-2.5 font-medium">{block.main.name}</div>
            </div>
          </div>
        </div>
      </button>
      {block.alternatives.length > 0 && (
        <div className="pt-2 h-full relative">
          {block.traveltime !== null && (
            <div className="absolute top-0 left-9 h-full w-14 z-10">
              <div className="h-full border-r border-gray-400 w-px mx-auto"></div>
            </div>
          )}
          <button
            className="relative py-1 w-full items-center flex justify-center bg-gray-50 text-gray-400 rounded-t-xl"
            onClick={() => setDrawerOpen((open) => !open)}
          >
            <div className="text-xs">다른 후보 더보기</div>
            {drawerOpen ? (
              <UpIcon className="ml-2 h-3" />
            ) : (
              <DownIcon className="ml-2 h-3" />
            )}
          </button>
        </div>
      )}
      <AnimateHeight height={drawerOpen ? "auto" : 0}>
        <div className="pt-2 relative">
          {block.traveltime !== null && (
            <div className="absolute top-0 left-9 bottom-0 h-full w-14">
              <div className="h-full border-r border-gray-400 w-px mx-auto"></div>
            </div>
          )}
          {block.alternatives.map((alt) => (
            <button className="w-full" onClick={() => setPoi(alt)}>
              <div className="pl-14 pb-5 w-full">
                <div className="pl-5">
                  <div className="flex items-start">
                    <div className="self-stretch flex flex-col relative">
                      <div className="w-14 h-14">
                        <img
                          className="w-full h-full rounded-full object-cover"
                          src={alt.detail.mainImageUrl}
                        />
                      </div>
                    </div>
                    <div className="ml-2.5 flex-grow">
                      <div className="flex items-center font-medium text-sm">
                        {alt.index}
                        <span
                          className={`ml-1.5 text-${getColorByType(
                            alt.poiType
                          )}`}
                        >
                          {getDiningText(alt.poiType, alt.type)}
                        </span>
                      </div>
                      <div className="mt-2.5 font-medium text-left">
                        {alt.name}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </button>
          ))}
        </div>
      </AnimateHeight>
    </div>
  );
};

export default BriefBlock;
