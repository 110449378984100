import { ReactElement } from "react";
import useOnclickOutside from "react-cool-onclickoutside";

const DialogConfirm = ({
  content,
  closeHandler,
  onClickYes,
  onClickOuter,
  hideYes,
  buttonText,
}: {
  content: ReactElement;
  closeHandler: () => void;
  onClickYes?: () => void;
  onClickOuter?: () => void;
  hideYes?: boolean;
  buttonText?: string;
}) => {
  const ref = useOnclickOutside((e) => {
    if (e.type !== "mousedown") {
      return;
    }
    if (onClickOuter) {
      onClickOuter();
    }
    closeHandler();
  });
  return (
    <div ref={ref} className="z-50 max-w-full px-5 sm:px-0 break-all">
      {content}
      <div
        className={`flex rounded-b sm:rounded-b-md h-14 w-full items-center bg-yoda-primary text-white ${
          hideYes === true ? `hidden` : ""
        }`}
      >
        <button
          className="h-full w-full"
          onClick={() => {
            if (onClickYes) {
              onClickYes();
            }
            closeHandler();
          }}
        >
          {buttonText || "확인"}
        </button>
      </div>
    </div>
  );
};

export default DialogConfirm;
