import { History, Location } from "history";
import { MainAction, MainContext } from "../../contexts/MainContext";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { useContext, useEffect } from "react";

import AppSample from "../Native/AppSample";
import AppTrip from "../Native/AppTrip";
import Axios from "axios";
import DialogContainer from "../DialogContainer";
import MBTIBackground from "../../assets/MBTI/background.png";
import Main from "../Main/Main";
import Questions from "../MBTI/Questions";
import Result from "../MBTI/Result";
import { SurveyProvider } from "../../contexts/SurveyContext";
import UseAxios from "../../hooks/UseAxios";
import amplitude from "amplitude-js";
import { getMe } from "../../api/Accounts";
import useTracking from "../../hooks/useTracking";

const SignoutHandler = (
  dispatch: React.Dispatch<MainAction>,
  location: Location<unknown>,
  history: History<unknown>
) => {
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    window.ReactNativeWebView?.postMessage(accessToken + "_LOGOUT");
  }
  localStorage.removeItem("survey_trip");
  localStorage.removeItem("accessToken");
  dispatch({
    type: "SIGNOUT",
  });
  const surveyReg = /survey\/\d+/;
  if (
    location.pathname.includes("/mypage") ||
    surveyReg.test(location.pathname)
  ) {
    history.push("/login");
  }
};

const Wrapper = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const history = useHistory();
  const [, dispatch] = useContext(MainContext);
  useEffect(() => {
    const callGetMe = async () => {
      try {
        dispatch({ type: "UPDATE_GET_ME_DONE", value: false });
        UseAxios();
        const { data } = await getMe();

        if (data && data.accessToken) {
          localStorage.setItem("accessToken", data.accessToken);
          dispatch({ type: "UPDATE_USER", value: data });

          Axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${data.accessToken}`;
        } else {
          SignoutHandler(dispatch, location, history);
        }
      } catch (e) {
        SignoutHandler(dispatch, location, history);
      } finally {
        dispatch({ type: "UPDATE_GET_ME_DONE", value: true });
      }
    };
    callGetMe();
  }, []);
  useTracking();

  return <div className="h-full">{children}</div>;
};

function App() {
  useEffect(() => {
    amplitude
      .getInstance()
      .init(process.env.REACT_APP_AMPLITUDE_API_KEY!, undefined, {
        includeUtm: true,
        includeReferrer: true,
      });
  }, []);

  return (
    <Router>
      <Wrapper>
        <SurveyProvider>
          <Switch>
            <Route path="/mbti">
              <div
                className="fixed h-full w-full z-30 bg-white bg-cover"
                style={{ backgroundImage: `url(${MBTIBackground})` }}
              >
                <Route exact path="/mbti">
                  <Questions />
                </Route>
                <Route path="/mbti/result/:mbti">
                  <Result />
                </Route>
              </div>
            </Route>
            <Route path="/app">
              <div className="fixed left-0 top-0 h-screen w-screen z-30 bg-white font-NotoSansKR">
                <Route path="/app/trip/:id">
                  <AppTrip />
                </Route>
                <Route path="/app/sample-trip/:id">
                  <AppSample />
                </Route>
                <Route path="/app/weekly-trip/:id">
                  <AppSample weekly={true} />
                </Route>
              </div>
            </Route>
            <Route path="/">
              <Main />
            </Route>
          </Switch>
        </SurveyProvider>
        <DialogContainer />
      </Wrapper>
    </Router>
  );
}

export default App;
