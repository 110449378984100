import { ReactElement } from "react";

const DialogYesNo = ({
  content,
  closeHandler,
  onClickYes,
  yesText,
  noText,
}: {
  content: ReactElement;
  closeHandler: () => void;
  onClickYes: () => void;
  yesText?: string;
  noText?: string;
}) => {
  return (
    <div className="z-50 max-w-full px-5 sm:px-0 break-all">
      {content}
      <div className="flex h-14 w-full items-center text-gray-400">
        <button
          className="h-full w-1/2 rounded-bl sm:rounded-bl-md bg-gray-100 hover:bg-yoda-primary hover:text-white"
          onClick={() => {
            onClickYes();
            closeHandler();
          }}
        >
          {yesText || "네"}
        </button>
        <div className="h-full w-px bg-gray-300"></div>
        <button
          className="h-full w-1/2 rounded-br sm:rounded-br-md bg-gray-100 hover:bg-yoda-primary hover:text-white"
          onClick={closeHandler}
        >
          {noText || "아니오"}
        </button>
      </div>
    </div>
  );
};

export default DialogYesNo;
