import Camp from "../assets/Survey/accomm/camping.png";
import CampHigh from "../assets/Survey/accomm/camping@3x.png";
import CampMedium from "../assets/Survey/accomm/camping@2x.png";
import Eowol from "../assets/Survey/jeju/6-Eowol.png";
import EowolHigh from "../assets/Survey/jeju/6-Eowol@3x.png";
import EowolMedium from "../assets/Survey/jeju/6-Eowol@2x.png";
import GuestHouse from "../assets/Survey/accomm/guesthouse.png";
import GuestHouseHigh from "../assets/Survey/accomm/guesthouse@3x.png";
import GuestHouseMedium from "../assets/Survey/accomm/guesthouse@2x.png";
import Halla from "../assets/Survey/jeju/7-Mt.Halla.png";
import HallaHigh from "../assets/Survey/jeju/7-Mt.Halla@3x.png";
import HallaMedium from "../assets/Survey/jeju/7-Mt.Halla@2x.png";
import Hamdeok from "../assets/Survey/jeju/2-Hamdeok.png";
import HamdeokHigh from "../assets/Survey/jeju/2-Hamdeok@3x.png";
import HamdeokMedium from "../assets/Survey/jeju/2-Hamdeok@2x.png";
import Hotel from "../assets/Survey/accomm/hotel.png";
import HotelHigh from "../assets/Survey/accomm/hotel@3x.png";
import HotelMedium from "../assets/Survey/accomm/hotel@2x.png";
import Hyeopjae from "../assets/Survey/jeju/5-Hyeopjae.png";
import HyeopjaeHigh from "../assets/Survey/jeju/5-Hyeopjae@3x.png";
import HyeopjaeMedium from "../assets/Survey/jeju/5-Hyeopjae@2x.png";
import Jejusi from "../assets/Survey/jeju/1-Jeju_si.png";
import JejusiHigh from "../assets/Survey/jeju/1-Jeju_si@3x.png";
import JejusiMedium from "../assets/Survey/jeju/1-Jeju_si@2x.png";
import Pension from "../assets/Survey/accomm/pension.png";
import PensionHigh from "../assets/Survey/accomm/pension@3x.png";
import PensionMedium from "../assets/Survey/accomm/pension@2x.png";
import Seoguipo from "../assets/Survey/jeju/4-Seoguipo.png";
import SeoguipoHigh from "../assets/Survey/jeju/4-Seoguipo@3x.png";
import SeoguipoMedium from "../assets/Survey/jeju/4-Seoguipo@2x.png";
import Seongsan from "../assets/Survey/jeju/3-seongsan.png";
import SeongsanHigh from "../assets/Survey/jeju/3-seongsan@3x.png";
import SeongsanMedium from "../assets/Survey/jeju/3-seongsan@2x.png";
import Udo from "../assets/Survey/jeju/3-1-u_do.png";
import UdoHigh from "../assets/Survey/jeju/3-1-u_do@3x.png";
import UdoMedium from "../assets/Survey/jeju/3-1-u_do@2x.png";
// import Pets from "../assets/Survey/party/pets.png";
// import PetsHigh from "../assets/Survey/party/pets@3x.png";
// import PetsMedium from "../assets/Survey/party/pets@2x.png";
import YodaPick from "../assets/Survey/accomm/random.png";
import YodaPickHigh from "../assets/Survey/accomm/random@3x.png";
import YodaPickMedium from "../assets/Survey/accomm/random@2x.png";

const adultsAgeOptions = [
  {
    name: "20대",
    value: 2,
  },
  {
    name: "30대",
    value: 3,
  },
  {
    name: "40대",
    value: 4,
  },
  {
    name: "50대",
    value: 5,
  },
  {
    name: "60대",
    value: 6,
  },
  {
    name: "70대",
    value: 7,
  },
  {
    name: "80대 이상",
    value: 8,
  },
];

const kidsAgeOptions = [
  { name: "24개월 미만", value: 11 },
  { name: "24개월 ~ 48개월", value: 12 },
  { name: "48개월 ~ 미취학아동", value: 13 },
  { name: "초등학생", value: 14 },
  { name: "중학생", value: 15 },
  { name: "고등학생", value: 16 },
];

const transportOptions = [
  {
    name: "직접 운전",
    value: ["driving"],
  },
  {
    name: "대중교통",
    value: ["flight", "train", "bus"],
  },
];
const transTimeOptions = [
  { name: "1.5시간 이내", value: "under90" },
  { name: `1.5시간 ~\n3시간`, value: "under180" },
  { name: "3시간 이상", value: "over180" },
];

const budgetOptions = [
  {
    name: "10만원 이하",
    value: "under10",
    order: 0,
  },
  {
    name: "10만원대",
    value: "10to20",
    order: 1,
  },
  {
    name: "20만원대",
    value: "over20",
    order: 2,
  },
  {
    name: "제한없음",
    value: "best",
    order: 3,
  },
];

const accommOptions = [
  {
    name: "호텔/리조트",
    value: "hotel",
    image: Hotel,
    imageM: HotelMedium,
    imageH: HotelHigh,
  },
  {
    name: "독채 펜션",
    value: "pension",
    image: Pension,
    imageM: PensionMedium,
    imageH: PensionHigh,
  },
  {
    name: "캠핑/글램핑",
    value: "camping",
    image: Camp,
    imageM: CampMedium,
    imageH: CampHigh,
  },
  {
    name: "게스트하우스\n/민박",
    value: "guesthouse",
    image: GuestHouse,
    imageM: GuestHouseMedium,
    imageH: GuestHouseHigh,
  },
  {
    name: "여다가\n골라주세요",
    value: "anything",
    image: YodaPick,
    imageM: YodaPickMedium,
    imageH: YodaPickHigh,
  },
];

const JejuCities = [
  {
    name: "애월",
    lat: 33.45878668026053,
    lng: 126.41116585620934,
    className: "jeju-eowol",
    low: Eowol,
    medium: EowolMedium,
    high: EowolHigh,
  },
  {
    name: "한림/협재",
    lat: 33.370937041193784,
    lng: 126.26212287820016,
    className: "jeju-hyeopjae",
    low: Hyeopjae,
    medium: HyeopjaeMedium,
    high: HyeopjaeHigh,
  },

  {
    name: "제주시",
    lat: 33.491362290439554,
    lng: 126.52871387207614,
    className: "jeju-jejusi",
    low: Jejusi,
    medium: JejusiMedium,
    high: JejusiHigh,
  },
  {
    name: "한라산 지구",
    lat: 33.3615016,
    lng: 126.529991,
    className: "jeju-halla",
    low: Halla,
    medium: HallaMedium,
    high: HallaHigh,
  },
  {
    name: "서귀포/중문",
    lat: 33.276255672621936,
    lng: 126.66747121182942,
    className: "jeju-seoguipo",
    low: Seoguipo,
    medium: SeoguipoMedium,
    high: SeoguipoHigh,
  },
  {
    name: "함덕/구좌",
    lat: 33.51900426778219,
    lng: 126.8152135,
    className: "jeju-hamdeok",
    low: Hamdeok,
    medium: HamdeokMedium,
    high: HamdeokHigh,
  },
  {
    name: "우도",
    lat: 33.5063521,
    lng: 126.953161,
    className: "jeju-udo",
    low: Udo,
    medium: UdoMedium,
    high: UdoHigh,
  },
  {
    name: "성산",
    lat: 33.4420984,
    lng: 126.910985,
    className: "jeju-seongsan",
    low: Seongsan,
    medium: SeongsanMedium,
    high: SeongsanHigh,
  },
];

export interface InterestType {
  value: string;
  name: string;
  imageUrl: string;
  imageUrlHigh: string;
  imageUrlMedium: string;
  children: InterestType[];
}
export interface ParentInterestType extends InterestType {
  description?: string;
}
export interface DestTextType {
  name: string;
  isTransitOkay?: boolean;
  covidFree: boolean;
  isActive: boolean;
}
export interface DestMapCityType {
  name: string;
  latitude: number;
  longitude: number;
  driveDistLimit: "under90" | "under180" | "over180";
  transitDistLimit: "under90" | "under180" | "over180";
  covidFree: boolean;
}
export interface DestFoodActType {
  location: string;
  name: string;
  description: string;
  poiId: number;
  value: string;
  imageUrl: string;
  budgetLimit: string;
  driveDistLimit: "under90" | "under180" | "over180";
  transitDistLimit: "under90" | "under180" | "over180";
  covidFree: boolean;
}

export type SubDest = {
  name: string;
  coords: {
    latitude: number;
    longitude: number;
  };
};

export {
  adultsAgeOptions,
  kidsAgeOptions,
  transportOptions,
  transTimeOptions,
  budgetOptions,
  accommOptions,
  JejuCities,
};
