import React, { Dispatch, createContext, useReducer } from "react";
import { SubDest, transTimeOptions } from "../util/surveyOptions";

import _ from "lodash";

export type SurveyState = {
  id?: number;
  surveyId?: string;
  covidFree?: boolean;

  dateFixed?: boolean;
  from: string;
  to: string;
  fromMonth: number;
  length: number;
  // 1-1

  adults: number;
  adultsAge: number[];
  kids: number;
  kidsAge: number[];
  kidsMinAge: number;
  kidsMaxAge: number;
  // 1-2

  departLocation: string;
  departCoords?: { lat: number; lng: number };
  // 2-1

  transportTypes: string[];
  transportTimes: string[];
  // 2-2

  destDescription?: string;
  destLocation?: string;
  destFromWeeklyContents?: boolean;
  destName: string;
  destDetermined: boolean;
  destType?: "map" | "food" | "activity" | "anything";
  // 2-3

  destCity: string;
  // 2-4-1
  destFood: number;
  // 2-4-2
  destAct: number;
  // 2-4-3

  destVisitedDegree?: 1 | 2 | 3;
  // 2-5

  accommAddr: string;
  accommCoords?: { lat: number; lng: number };
  accommTypes: string[];
  accommBudget: string[];
  // 3-1

  interestTypes: string[];
  // 3-2

  interestPriority: string[];
  // 3-3

  isPopular?: boolean;
  isBusy?: boolean;
  isInsta?: boolean;
  // 4-1

  favoriteFoodTypes?: string[];
  favoriteDrinkTypes?: string[];
  hateFoodTypes?: string[];
  // 4-2

  gender: number;
  age: number;

  subDests: SubDest[];
};
export type SurveyAction =
  | {
      type: "UPDATE_SURVEY";
      value: SurveyState;
    }
  | {
      type: "UPDATE_SURVEY_ID";
      value: {
        surveyId: string;
      };
    }
  | {
      type: "UPDATE_COVID_FREE";
      value: {
        covidFree: boolean;
      };
    }
  | { type: "UPDATE_DATE_FIXED"; value: boolean }
  | {
      type: "UPDATE_DATE";
      value: {
        from: string;
        to: string;
        fromMonth: number;
        length: number;
      };
    }
  | {
      type: "UPDATE_COMPANION";
      value: {
        adults: number; // 3
        adultsAge: number[];
        kids: number; // 2
        kidsAge: number[];
        kidsMinAge: number;
        kidsMaxAge: number;
      };
    }
  | {
      type: "UPDATE_DEPART_LOCATION";
      value: {
        departLocation: string;
        departCoords: { lat: number; lng: number };
      };
    }
  | {
      type: "UPDATE_SURVEY_FROM_WEEKLY";
      value: {
        destDescription: string;
        destLocation: string;
        destAct: number;
        interestPriority: string[];
      };
    }
  | {
      type: "UPDATE_TRANSPORT_TYPES";
      value: {
        transportTypes: string[];
        transportTimes: string[];
      };
    }
  | {
      type: "UPDATE_DESTINATION";
      value: {
        destName: string;
        destDetermined: boolean;
        destType?: "map" | "food" | "activity" | "anything";
        destCity: string;
        destFood: number;
        destAct: number;
      };
    }
  | {
      type: "UPDATE_DESTINATION_VISITED_DEGREE";
      value: {
        destVisitedDegree?: 1 | 2 | 3;
      };
    }
  | {
      type: "UPDATE_ACCOMM";
      value: {
        accommAddr: string;
        accommCoords?: { lat: number; lng: number };
        accommTypes: string[];
        accommBudget: string[];
      };
    }
  | {
      type: "UPDATE_INTEREST_TYPES";
      value: {
        interestTypes: string[];
      };
    }
  | {
      type: "UPDATE_INTEREST_PRIORITY";
      value: {
        interestPriority: string[];
      };
    }
  | {
      type: "UPDATE_IS_POPULAR";
      value: {
        isPopular: boolean;
      };
    }
  | {
      type: "UPDATE_IS_BUSY";
      value: {
        isBusy: boolean;
      };
    }
  | {
      type: "UPDATE_IS_INSTA";
      value: {
        isInsta: boolean;
      };
    }
  | {
      type: "UPDATE_FOOD_PREFERENCE";
      value: {
        favoriteFoodTypes: string[];
        favoriteDrinkTypes: string[];
      };
    }
  | {
      type: "UPDATE_PERSONAL";
      value: {
        gender: number;
        age: number;
      };
    }
  | {
      type: "UPDATE_SUB_DESTS";
      value: { subDests: SubDest[] };
    };

type SurveyReducer = React.Reducer<SurveyState, SurveyAction>;

const initialState: React.ReducerState<SurveyReducer> = {
  surveyId: undefined,
  covidFree: undefined,

  dateFixed: undefined,
  from: "",
  to: "",
  fromMonth: 0,
  length: 0,
  // 1-1

  adults: 0,
  adultsAge: [],
  kids: 0,
  kidsAge: [],
  kidsMinAge: 0,
  kidsMaxAge: 0,
  // 1-2

  departLocation: "",
  departCoords: undefined,
  // 2-1

  transportTypes: [],
  transportTimes: transTimeOptions.map((option) => option.value),
  // 2-2

  destFromWeeklyContents: false,
  destName: "",
  destDetermined: false,
  destType: undefined,
  // 2-3

  destCity: "",
  // 2-4-1
  destFood: 0,
  // 2-4-2
  destAct: 0,
  // 2-4-3

  destVisitedDegree: undefined,
  // 2-5

  accommAddr: "",
  accommCoords: undefined,
  accommTypes: [],
  accommBudget: [],
  // 3-1

  interestTypes: [],
  // 3-2

  interestPriority: [],
  // 3-3

  isPopular: undefined,
  isBusy: undefined,
  isInsta: undefined,
  // 4-1

  favoriteFoodTypes: undefined,
  favoriteDrinkTypes: undefined,
  hateFoodTypes: [],
  // 4-2

  gender: 0,
  age: 0,

  subDests: [],
};

const reducer: SurveyReducer = (state = initialState, action: SurveyAction) => {
  switch (action.type) {
    case "UPDATE_SURVEY":
      return { ...initialState, ...action.value };
    case "UPDATE_DATE_FIXED":
      return { ...state, ...{ dateFixed: action.value } };
    case "UPDATE_SURVEY_ID":
    case "UPDATE_COVID_FREE":
    case "UPDATE_DATE":
    case "UPDATE_COMPANION":
    case "UPDATE_DEPART_LOCATION":
    case "UPDATE_TRANSPORT_TYPES":
    case "UPDATE_DESTINATION_VISITED_DEGREE":
    case "UPDATE_ACCOMM":
    case "UPDATE_INTEREST_TYPES":
    case "UPDATE_INTEREST_PRIORITY":
    case "UPDATE_IS_POPULAR":
    case "UPDATE_IS_BUSY":
    case "UPDATE_IS_INSTA":
    case "UPDATE_FOOD_PREFERENCE":
    case "UPDATE_PERSONAL":
    case "UPDATE_SUB_DESTS":
      return { ...state, ...action.value };
    case "UPDATE_SURVEY_FROM_WEEKLY":
      return {
        ...state,
        ...action.value,
        covidFree: false,
        transportTypes: ["driving"],
        transportTimes: ["under90", "under180", "over180"],
        destFromWeeklyContents: true,
        destName: "",
        destDetermined: false,
        destType: "activity",
        destCity: "",
        destFood: 0,
        destVisitedDegree: undefined,
        accommAddr: "",
        accommCoords: undefined,
        interestTypes: [],
        subDests: [],
      };
    case "UPDATE_DESTINATION":
      let destVisitedDegree = undefined;
      if (
        _.isEqual(
          {
            destName: state.destName,
            destDetermined: state.destDetermined,
            destType: state.destType,
            destCity: state.destCity,
            destFood: state.destFood,
            destAct: state.destAct,
          },
          action.value
        )
      ) {
        destVisitedDegree = state.destVisitedDegree;
      }
      let subDests = [...state.subDests];
      if (
        action.value.destName !== "제주특별자치도" &&
        action.value.destCity !== "제주"
      ) {
        subDests = [];
      }
      return {
        ...state,
        ...action.value,
        destVisitedDegree,
        subDests,
        destFromWeeklyContents: false,
      };

    default:
      return state;
  }
};

const SurveyContext = createContext<[SurveyState, Dispatch<SurveyAction>]>([
  initialState,
  () => initialState,
]);

const SurveyProvider: React.FC<{}> = ({ children }) => {
  const value = useReducer(reducer, initialState);
  return (
    <SurveyContext.Provider value={value}>{children}</SurveyContext.Provider>
  );
};

export { SurveyContext, SurveyProvider, initialState };
