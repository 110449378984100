import './main.css';

import {
  altPins,
  mainDots,
  mainPins,
  presentations,
  yodaPickScreenshots,
} from './util';
import { useCallback, useEffect, useMemo, useState } from 'react';

import AppLogoLarge from '../../assets/Main/retire/app_logo_large.svg';
import AppLogoSmall from '../../assets/Main/retire/app_logo_small.svg';
import { ReactComponent as Blog } from '../../assets/Main/retire/sns/blog.svg';
import BoldDiagonal from '../../assets/Main/retire/bold_diagonal.svg';
import Check from '../../assets/Main/retire/presentaion/check.svg';
import { ReactComponent as Facebook } from '../../assets/Main/retire/sns/facebook.svg';
import { ReactComponent as Insta } from '../../assets/Main/retire/sns/instagram.svg';
import PhoneSurveyScreenshot from '../../assets/Main/retire/phone_survey_screenshot.png';
import Shadow from '../../assets/Main/retire/survey_shadow.png';
import { ReactComponent as Storicity } from '../../assets/Main/retire/storicity_logo.svg';
import ThinDiagonal from '../../assets/Main/retire/thin_diagonal.svg';
import TripScreenshot from '../../assets/Main/retire/phone_trip_screenshot.png';
import YodaKoreanLogo from '../../assets/Main/retire/yoda_korean_logo.svg';
import YodaKoreanLogoPrimary from '../../assets/Main/retire/yoda_korean_logo_primary.svg';
import YodaLogo from '../../assets/Main/retire/yoda_logo.svg';
import { ReactComponent as Youtube } from '../../assets/Main/retire/sns/youtube.svg';
import amplitude from 'amplitude-js';
import { callMainPageTracking } from '../../util/tracking';

const desktopWidth = 1024;

const Main = () => {
  const [clientWidth, setClientWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', () => {
      const { innerWidth } = window;
      setClientWidth(innerWidth);
    });

    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);

  useEffect(() => {
    callMainPageTracking();
    amplitude.getInstance().logEvent('mainpage opened');
  }, []);

  const DiagonalComponent = useMemo(() => {
    if (clientWidth >= 1024) {
      return <img className='w-full h-full' src={BoldDiagonal} />;
    } else {
      return (
        <img
          className='w-full max-w-[138px] max-h-[142px] md:max-w-[310px] md:max-h-[320px]'
          src={ThinDiagonal}
        />
      );
    }
  }, [clientWidth]);

  const getDiagonalSize = useCallback(
    (aElement: HTMLElement, bElement: HTMLElement) => {
      const { offsetLeft: aLeft, offsetTop: aTop } = aElement;
      const { offsetLeft: bLeft, offsetTop: bTop } = bElement;

      const width = Math.abs(aLeft - bLeft);
      const height = Math.abs(aTop - bTop);
      const top = Math.min(bTop, aTop);
      const left = Math.min(bLeft, aLeft);

      return { width, height, top, left };
    },
    []
  );

  const [pinkDot, setPinkDot] = useState<HTMLElement | null>(null);
  const [blue1Dot, setBlue1Dot] = useState<HTMLElement | null>(null);
  const [yellowDot, setYellowDot] = useState<HTMLElement | null>(null);
  const [blue2Dot, setBlue2Dot] = useState<HTMLElement | null>(null);
  useEffect(() => {
    const _pinkDot = document.getElementById('main-pink-dot');
    const _blue1Dot = document.getElementById('main-blue-1-dot');
    const _yellowDot = document.getElementById('main-yellow-dot');
    const _blue2Dot = document.getElementById('main-blue-2-dot');
    setPinkDot(_pinkDot);
    setBlue1Dot(_blue1Dot);
    setYellowDot(_yellowDot);
    setBlue2Dot(_blue2Dot);
  });

  const firstDiagonalSize = useMemo(() => {
    if (pinkDot && blue1Dot) {
      return getDiagonalSize(pinkDot, blue1Dot);
    }
    return {};
  }, [clientWidth, pinkDot, blue1Dot]);

  const secondDiagonalSize = useMemo(() => {
    if (yellowDot && blue1Dot) {
      return getDiagonalSize(yellowDot, blue1Dot);
    }
    return {};
  }, [clientWidth, yellowDot, blue1Dot]);

  const thirdDiagonalSize = useMemo(() => {
    if (yellowDot && blue2Dot) {
      return getDiagonalSize(yellowDot, blue2Dot);
    }
    return {};
  }, [clientWidth, yellowDot, blue2Dot]);

  return (
    <div className='relative w-full'>
      <div
        id='main-header'
        style={{ borderBottomWidth: 0.5, borderColor: '#E1E1E1' }}
        className='sticky top-0 z-50 flex h-[56px] lg:h-[81px] px-8 md:px-18  lg:px-45 bg-white  bg-opacity-80 justify-between items-center'
      >
        <div className='flex items-center'>
          <img src={YodaLogo} className='w-6 h-6 lg:w-9 lg:h-9 object-cover' />
          <img src={YodaKoreanLogo} id='korean-logo' />
        </div>
        <div
          className='text-sm lg:text-xl leading-3 lg:leading-5 flex items-center'
          style={{ fontFamily: 'Noto Sans KR', fontWeight: 700 }}
        >
          <a
            target='_blank'
            href='https://yodatrip.oopy.io/'
            className='text-yoda-gray-5 lg:text-yoda-black-2 block  px-3 py-2 lg:px-5 lg:py-4'
          >
            채용공고
          </a>
          <a
            target='_blank'
            href='https://yodareactnative.page.link/ELQn'
            className=' text-white  bg-yoda-primary px-3 py-3 lg:px-5 lg:py-4 rounded-full ml-3 flex items-center'
          >
            <div>다운로드</div>
          </a>
        </div>
      </div>
      <div className='first-main-container flex relative'>
        <div className='text-yoda-primary flex absolute title-container w-full justify-center items-center'>
          <div className='phone-heading-bold-1-24 md:tab-heading-bold-1-40 lg:pc-heading-bold-1-55'>
            주말 나들이
          </div>
          <img src={YodaKoreanLogoPrimary} id='korean-logo-primary' />
          <div className='phone-heading-bold-1-24 md:tab-heading-bold-1-40 lg:pc-heading-bold-1-55'>
            하나로 끝
          </div>
        </div>
        {altPins.map((pin) => (
          <img src={pin.svg} className={`${pin.className} absolute`} />
        ))}
        {mainPins.map((pin) => (
          <img src={pin.svg} className='absolute z-10' id={pin.id} />
        ))}
        {mainDots.map((dot) => (
          <div className='absolute' id={dot} />
        ))}
        <svg style={firstDiagonalSize} className='absolute'>
          <line
            stroke-dasharray='5, 5'
            x1='0'
            y1='100%'
            x2='100%'
            y2='0'
            style={{ stroke: '#C4C4C4', strokeWidth: 2 }}
          />
        </svg>
        <svg style={secondDiagonalSize} className='absolute'>
          <line
            stroke-dasharray='5, 5'
            x1='0'
            y1='0'
            x2='100%'
            y2='100%'
            style={{ stroke: '#C4C4C4', strokeWidth: 2 }}
          />
        </svg>
        <svg style={thirdDiagonalSize} className='absolute'>
          <line
            stroke-dasharray='5, 5'
            x1='0'
            y1='100%'
            x2='100%'
            y2='0'
            style={{ stroke: '#C4C4C4', strokeWidth: 2 }}
          />
        </svg>
      </div>
      <div className='flex  items-center flex-col justify-between '>
        <div className='flex relative flex-col w-full lg:flex-row  justify-center lg:justify-between  max-w-[322px] md:max-w-[744px]  lg:max-w-[1100px]'>
          <div className='basis-full pt-[105px] pb-16  md:pt-[181px] md:pb-20 lg:pt-[169px] pl-8 md:pl-18 phone-heading-bold-2-21 text-yoda-black-3 md:tab-heading-bold-2-31 lg:pc-heading-bold-2-42'>
            <div>1분만에</div>
            <div>맞춤형으로 제작되는</div>
            <div>가장 편한 여행일정</div>
          </div>
          <div className='w-full flex justify-end'>
            <img src={TripScreenshot} className=' md:max-w-[542px] lg:mt-28' />
          </div>
        </div>
      </div>
      <div className='relative flex flex-col justify-between items-center  bg-yoda-primary '>
        <div className='z-30 lg:z-10  text-white h-full lg:pb-32 max-w-[360px] md:max-w-[744px] lg:max-w-[1150px]  phone-heading-bold-2-21  md:tab-heading-bold-2-31 lg:pc-heading-bold-2-42 px-8 md:px-18 lg:px-12 w-full'>
          <div className='white-pre pt-[105px] md:pt-[132px] lg:pt-[169px] pb-5 md:pb-[18px] lg:pb-[50px] '>
            <div>계획이 번거로운</div>
            <div>아이와의 나들이도</div>
            <div>반려견과의 여행도</div>
          </div>
          <div className='w-full flex items-end justify-between '>
            {DiagonalComponent}
            <div className='w-full text-right'>여다에서는 쉽게</div>
          </div>
          <div className='flex lg:hidden justify-center'>
            <img
              src={PhoneSurveyScreenshot}
              className='mt-10 w-full max-w-[332px] md:max-w-[561px]'
            />
          </div>
        </div>
        <div className='lg:max-w-[1024px] w-full absolute bottom-0'>
          <div className='flex justify-center lg:justify-start '>
            <img
              src={PhoneSurveyScreenshot}
              className={`z-30 ${
                clientWidth === desktopWidth ? 'ml-[37px]' : 'ml-[110px]'
              } hidden lg:block max-w-[332px] md:max-w-[561px]`}
            />
            <img
              src={Shadow}
              className='z-20 hidden lg:left-[55px] md:block absolute bottom-0 max-w-[700px]'
            />
          </div>
        </div>
      </div>
      <div className='w-full relative bg-yoda-gray-0 flex flex-col justify-center items-center'>
        <div className='w-full max-w-[286px] md:max-w-[604px] lg:max-w-[1080px] pb-[149px] md:pb-[174px] lg:pb-[201px]'>
          <div className='phone-heading-bold-2-21 pt-[105px] pb-2 md:pb-0 md:pt-[239px] lg:pt-[214px] md:tab-heading-bold-2-31 lg:pc-heading-bold-2-42 text-center text-yoda-black-2'>
            <div>장소의 퀄리티 뿐만 아니라</div>
            <div>영업시간과 동선까지 고려한 추천</div>
          </div>
          <div className='flex flex-col md:flex-row items-center md:mt-[57px] md:justify-between lg:mt-[139px]'>
            {presentations.map((item, index) => (
              <div key={index} className='mt-[84px] md:mt-0'>
                <div
                  className='p-[17px] lg:p-8 bg-white flex rounded-3xl'
                  style={{ boxShadow: '2px 8px 23px rgba(0, 0, 0, 0.03)' }}
                >
                  <img className='w-[147px] lg:w-[235px]' src={item.svg} />
                </div>
                <div className='flex items-center mt-6 lg:mt-[50px] justify-center'>
                  <div className='medium-card'>{item.text}</div>
                  <img
                    src={Check}
                    className='w-5 lg:w-[33px] ml-[2.5px] lg:ml-[5px]'
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='w-full relative bg-white flex flex-col items-center pt-[105px] md:pt-[107px] lg:pt-[167px]'>
        <div className='phone-heading-bold-2-21  md:tab-heading-bold-2-31 lg:pc-heading-bold-2-42 text-yoda-black-3 pl-8 md:pl-18 lg:pl-12  lg:max-w-[1175px] w-full '>
          <div>매주 보내드리는</div>
          <div>맞춤형 여행일정과</div>
          <div>장소 추천</div>
        </div>
        <div className='lg:hidden pl-[40.5px] h-full md:pl-[46.5px] lg:pl-0 pt-[60px] md:pt-[107px] lg:pt-[91px] pb-[86px] md:pb-[207px] lg:pb-[137px] w-full overflow-scroll flex flex-row justify-start lg:justify-center  lg:items-center lg:max-w-[1175px]'>
          {yodaPickScreenshots.map((item, index) => (
            <img
              className={`yodaPick-screenshot w-[228px] mx-[25.5px] lg:block ${
                index === 2 && 'pr-[25.5px] w-[253.5px] '
              }`}
              key={index}
              src={item.img}
            />
          ))}
        </div>
        <div className='hidden lg:flex pl-[40.5px] h-full md:pl-[46.5px] lg:pl-0 pt-[60px] md:pt-[107px] lg:pt-[91px] pb-[86px] md:pb-[207px] lg:pb-[137px] w-full overflow-scroll  flex-row justify-start lg:justify-center  lg:items-center lg:max-w-[1175px]'>
          {yodaPickScreenshots.map((item, index) => (
            <div key={index} className='yodaPick-screenshot px-[32px]'>
              <img src={item.img} className='w-full max-w-[317px] ' />
            </div>
          ))}
        </div>
      </div>
      <div className='w-full relative bg-yoda-gray-0 pt-[68px] pb-[101px] lg:pt-32 lg:pb-[110px] '>
        <div></div>
        <div className='font-NotoSans font-medium text-base leading-6 text-yoda-black-2 lg:text-2xl lg:leading-10 text-center'>
          <div>바로 지금,</div>
          <div>1분 만에 주말 나들이를 만들어 보세요!</div>
        </div>
        <div className='w-full flex flex-col items-center text-white mt-10 lg:mt-[58px]'>
          <img className='hidden lg:block' src={AppLogoLarge} />
          <img className='block lg:hidden' src={AppLogoSmall} />
          <a
            target='_blank'
            href='https://yodareactnative.page.link/ELQn'
            style={{ backgroundColor: '#4287FF' }}
            className='rounded-full font-bold px-4 py-3 lg:px-5 lg:py-[17px] font-NotoSans text-base leading-4 lg:text-[22px] lg:leading-[22px] mt-8 lg:mt-10'
          >
            다운로드
          </a>
        </div>
      </div>
      <div
        style={{ backgroundColor: '#2052D1' }}
        className='w-full relative flex flex-col  md:flex-row-reverse md:justify-start pt-[50px] pb-[64px] md:pt-16'
      >
        <div className='w-full px-6 md:pr-18 lg:pr-52 flex flex-col md:items-end'>
          <div
            className='text-white font-medium text-[15px] md:text-[17px] leading-6 md:text-right'
            style={{ letterSpacing: -0.75 }}
          >
            SNS에서 여다의 소식을 만나보세요!
          </div>
          <div className='flex flex-row mt-3 md:mt-6 md:justify-end'>
            <a
              href='https://www.instagram.com/yoda.trip'
              target='_blank'
              className='mr-[8px]'
            >
              <Insta />
            </a>
            <a
              href='https://blog.naver.com/yodatrip'
              target='_blank'
              className='mx-[8px]'
            >
              <Blog />
            </a>
            <a
              href='https://www.youtube.com/channel/UChsVMvwYCevAX30X_O7dt0w'
              target='_blank'
              className='mx-[8px]'
            >
              <Youtube />
            </a>
            <a
              href='https://www.facebook.com/yodatrip'
              target='_blank'
              className='ml-[8px]'
            >
              <Facebook />
            </a>
          </div>
        </div>
        <div className='w-full px-6 mt-16 md:mt-0 md:pl-18 lg:pl-52'>
          <Storicity />
          <div className='font-NotoSans text-[11px] font-normal leading-5 text-white opacity-70 mt-4'>
            <div>
              (주)스토리시티 | 대표 : 박상욱 | 개인정보책임관리자 : 최승훈
            </div>
            <div>사업자 등록번호 : 547-87-01606</div>
            <div>통신판매업 신고번호 : 2020-서울중구-1676</div>
            <div>서울시 종로구 청계천로 85 삼일빌딩 9F | 070-7122-4017</div>
          </div>
          <div className='font-NotoSans font-normal text-[11px] leading-4 text-white opacity-90 flex flex-row mt-4'>
            <a
              href='https://www.notion.so/a3c03a8acac74f7ea123954c8d1b55d5'
              target='_blank'
              rel='noreferrer'
              className='block'
            >
              개인정보처리방침
            </a>
            <div className='mx-2 w-[1px] h-3 text-white opacity-90'>|</div>
            <a
              href='https://www.notion.so/a626542ecc9d4587aa831fc660549e3d'
              target='_blank'
              className='block'
            >
              이용약관
            </a>
            <div className='mx-2 w-[1px] h-3 text-white opacity-90'>|</div>
            <a href='mailto:support@stori.city' className='block'>
              support@stori.city
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
