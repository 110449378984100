import useOnclickOutside from "react-cool-onclickoutside";

const DialogSwitch = ({
  content,
  closeHandler,
  onClickYes,
}: {
  content: string;
  closeHandler: () => void;
  onClickYes?: () => void;
}) => {
  const ref = useOnclickOutside((e) => {
    if (e.type !== "mousedown") {
      return;
    }
    closeHandler();
  });

  return (
    <div
      className="w-screen h-screen fixed top-0 left-0"
      style={{
        zIndex: 999,
        backgroundColor: "rgba(16, 16, 16, 0.7)",
      }}
    >
      <div className="h-full flex justify-center items-center">
        <div
          ref={ref}
          className="min-h-24 sm:min-h-36 bg-white px-4 sm:px-6 py-3 sm:py-5 rounded sm:rounded-md min-w-64 flex flex-col justify-between"
        >
          <div className="break-words whitespace-pre-line body-regular-14">
            {content}
          </div>
          <div className="flex justify-end">
            <button onClick={closeHandler} className="pr-9 sm:pr-14">
              <div className="text-right text-yoda-green-dark btn-bold-14">
                아니오
              </div>
            </button>
            <button
              onClick={() => {
                closeHandler();
                if (onClickYes) {
                  onClickYes();
                }
              }}
            >
              <div className="text-right text-yoda-green-dark btn-bold-14">
                네
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DialogSwitch;
