import ESTPimg from "../../assets/MBTI/Characters/ESTP.png";
import ESTJimg from "../../assets/MBTI/Characters/ESTJ.png";
import ENFJimg from "../../assets/MBTI/Characters/ENFJ.png";
import ENFPimg from "../../assets/MBTI/Characters/ENFP.png";
import ENTJimg from "../../assets/MBTI/Characters/ENTJ.png";
import ENTPimg from "../../assets/MBTI/Characters/ENTP.png";
import ESFPimg from "../../assets/MBTI/Characters/ESFP.png";
import ESFJimg from "../../assets/MBTI/Characters/ESFJ.png";
import INTJimg from "../../assets/MBTI/Characters/INTJ.png";
import INTPimg from "../../assets/MBTI/Characters/INTP.png";
import INFPimg from "../../assets/MBTI/Characters/INFP.png";
import ISFJimg from "../../assets/MBTI/Characters/ISFJ.png";
import ISTJimg from "../../assets/MBTI/Characters/ISTJ.png";
import ISTPimg from "../../assets/MBTI/Characters/ISTP.png";
import ISFPimg from "../../assets/MBTI/Characters/ISFP.png";
import INFJimg from "../../assets/MBTI/Characters/INFJ.png";
import ESTPbg from "../../assets/MBTI/Characters/ESTP_bg.png";
import ESTJbg from "../../assets/MBTI/Characters/ESTJ_bg.png";
import ENFJbg from "../../assets/MBTI/Characters/ENFJ_bg.png";
import ENFPbg from "../../assets/MBTI/Characters/ENFP_bg.png";
import ENTJbg from "../../assets/MBTI/Characters/ENTJ_bg.png";
import ENTPbg from "../../assets/MBTI/Characters/ENTP_bg.png";
import ESFPbg from "../../assets/MBTI/Characters/ESFP_bg.png";
import ESFJbg from "../../assets/MBTI/Characters/ESFJ_bg.png";
import INTJbg from "../../assets/MBTI/Characters/INTJ_bg.png";
import INTPbg from "../../assets/MBTI/Characters/INTP_bg.png";
import INFPbg from "../../assets/MBTI/Characters/INFP_bg.png";
import ISFJbg from "../../assets/MBTI/Characters/ISFJ_bg.png";
import ISTJbg from "../../assets/MBTI/Characters/ISTJ_bg.png";
import ISTPbg from "../../assets/MBTI/Characters/ISTP_bg.png";
import ISFPbg from "../../assets/MBTI/Characters/ISFP_bg.png";
import INFJbg from "../../assets/MBTI/Characters/INFJ_bg.png";
import ESTPcity from "../../assets/MBTI/Characters/ESTP_city.png";
import ESTJcity from "../../assets/MBTI/Characters/ESTJ_city.png";
import ENFJcity from "../../assets/MBTI/Characters/ENFJ_city.png";
import ENFPcity from "../../assets/MBTI/Characters/ENFP_city.png";
import ENTJcity from "../../assets/MBTI/Characters/ENTJ_city.png";
import ENTPcity from "../../assets/MBTI/Characters/ENTP_city.png";
import ESFPcity from "../../assets/MBTI/Characters/ESFP_city.png";
import ESFJcity from "../../assets/MBTI/Characters/ESFJ_city.png";
import INTJcity from "../../assets/MBTI/Characters/INTJ_city.png";
import INTPcity from "../../assets/MBTI/Characters/INTP_city.png";
import INFPcity from "../../assets/MBTI/Characters/INFP_city.png";
import ISFJcity from "../../assets/MBTI/Characters/ISFJ_city.png";
import ISTJcity from "../../assets/MBTI/Characters/ISTJ_city.png";
import ISTPcity from "../../assets/MBTI/Characters/ISTP_city.png";
import ISFPcity from "../../assets/MBTI/Characters/ISFP_city.png";
import INFJcity from "../../assets/MBTI/Characters/INFJ_city.png";
import ESTPresult from "../../assets/MBTI/Characters/ESTP_result.png";
import ESTJresult from "../../assets/MBTI/Characters/ESTJ_result.png";
import ENFJresult from "../../assets/MBTI/Characters/ENFJ_result.png";
import ENFPresult from "../../assets/MBTI/Characters/ENFP_result.png";
import ENTJresult from "../../assets/MBTI/Characters/ENTJ_result.png";
import ENTPresult from "../../assets/MBTI/Characters/ENTP_result.png";
import ESFPresult from "../../assets/MBTI/Characters/ESFP_result.png";
import ESFJresult from "../../assets/MBTI/Characters/ESFJ_result.png";
import INTJresult from "../../assets/MBTI/Characters/INTJ_result.png";
import INTPresult from "../../assets/MBTI/Characters/INTP_result.png";
import INFPresult from "../../assets/MBTI/Characters/INFP_result.png";
import ISFJresult from "../../assets/MBTI/Characters/ISFJ_result.png";
import ISTJresult from "../../assets/MBTI/Characters/ISTJ_result.png";
import ISTPresult from "../../assets/MBTI/Characters/ISTP_result.png";
import ISFPresult from "../../assets/MBTI/Characters/ISFP_result.png";
import INFJresult from "../../assets/MBTI/Characters/INFJ_result.png";

export type MBTIType =
  | "ESTP"
  | "ESTJ"
  | "ENFJ"
  | "ENFP"
  | "ENTJ"
  | "ENTP"
  | "ESFP"
  | "ESFJ"
  | "INTJ"
  | "INTP"
  | "INFP"
  | "ISFJ"
  | "ISTJ"
  | "ISTP"
  | "ISFP"
  | "INFJ";

export const _getPersonalCharacterImg = (MBTI: MBTIType) => {
  switch (MBTI) {
    case "ESTP":
      return ESTPimg;
    case "ESTJ":
      return ESTJimg;
    case "ENFJ":
      return ENFJimg;
    case "ENFP":
      return ENFPimg;
    case "ENTJ":
      return ENTJimg;
    case "ENTP":
      return ENTPimg;
    case "ESFP":
      return ESFPimg;
    case "ESFJ":
      return ESFJimg;
    case "INTJ":
      return INTJimg;
    case "INTP":
      return INTPimg;
    case "INFP":
      return INFPimg;
    case "ISFJ":
      return ISFJimg;
    case "ISTJ":
      return ISTJimg;
    case "ISTP":
      return ISTPimg;
    case "ISFP":
      return ISFPimg;
    case "INFJ":
      return INFJimg;
  }
};

export const _getPersonalCharacterTitle = (MBTI: MBTIType) => {
  switch (MBTI) {
    case "ESTP":
      return "예술을 즐기는 꿩";
    case "ESTJ":
      return "똑부러진 동백꽃";
    case "ENFJ":
      return "본투비트래블러 매";
    case "ENFP":
      return "여행 좀 즐길 줄 아는 오징어";
    case "ENTJ":
      return "카리스마 철철 까치";
    case "ENTP":
      return "꽃보다 청춘🌸 코스모스";
    case "ESFP":
      return "자유로운 영혼을 가진 소나무";
    case "ESFJ":
      return "사려깊은 잣";
    case "INTJ":
      return "최고의 여행메이트 곰";
    case "INTP":
      return "도도한 매력의 꽃게";
    case "INFP":
      return "감성적인 유자";
    case "ISFJ":
      return "'계획대로 되고 있어' 사과나무";
    case "ISTJ":
      return "'돌다리도 두들겨보고' 흑두루미";
    case "ISTP":
      return "모험가의 자질을 가진 개나리";
    case "ISFP":
      return "청산에 살어리랏다 수달";
    case "INFJ":
      return "내적 흥 충만한 백로";
  }
};

export const _getPersonalCharacter = (MBTI: MBTIType) => {
  switch (MBTI) {
    case "ESTP":
      return "꿩과";
    case "ESTJ":
      return "동백꽃과";
    case "ENFJ":
      return "매와";
    case "ENFP":
      return "오징어와";
    case "ENTJ":
      return "까치와";
    case "ENTP":
      return "코스모스와";
    case "ESFP":
      return "소나무와";
    case "ESFJ":
      return "잣나무와";
    case "INTJ":
      return "곰과";
    case "INTP":
      return "꽃게와";
    case "INFP":
      return "개나리와";
    case "ISFJ":
      return "사과나무와";
    case "ISTJ":
      return "흑두루미와";
    case "ISTP":
      return "유자와";
    case "ISFP":
      return "수달과";
    case "INFJ":
      return "백로와";
  }
};

export const _getPersonalDescription = (MBTI: MBTIType) => {
  switch (MBTI) {
    case "ESTP":
      return `⦁ 순발력과 위기에 대처하는 능력이 뛰어나요! 여행 중 갑작스럽게 계획이 틀어져도 당황하지 않고 빠르게 대안을 찾아요.
      
⦁ 스릴 넘치고 짜릿한 거 좋아해요! 패러글라이딩, 카트, 서핑 등 활동적인 액티비티를 즐겨요.🎢

⦁ 예술적인 감각이 뛰어나 아름다운 공간을 즐기지만, 어설프게 감성팔이하는 SNS용 관광지는 별로예요!

⦁ 여행 전이나 여행 중, 일행 사이에 마찰이 생기면 내가 나서서 중재해요. 즐거우려고 온 여행인데, 괜히 싸울 필요 없잖아요-
      
⦁ 여행에서 쇼핑을 빼놓을 수 없어요. 누가 나한테 쇼핑하라고 백만원만 줬으면..누구보다 잘 쓸 수 있는데!🛍`;
    case "ESTJ":
      return `⦁ 여행 가기 전, 계획은 필수! 숙소와 맛집, 갈 곳들은 물론이고 교통 수단과 어쩌면 플랜B 마련까지 똑부러지게 여행 계획을 세워요.

⦁ 그래서 여행 전부터 정산하기까지, 내가 일행 사이에서 리더 역할을 할 때가 많아요.

⦁ 아무 준비 없이 떠나는 즉흥 여행은 조금 꺼려져요. 어디 가서 뭘 하고 올 지 명확해야 해요. 애매모호한건 딱 싫어요!🗒

⦁ 숙소에 체크인하면 짐부터 가지런히 풀고 나서 쉬어야 해요. 다음 날 일어나서도 습관적으로 이불을 개는 정리정돈의 왕이에요.`;
    case "ENFJ":
      return `⦁ 여행이 너무 좋아요! 여행 만족도가 가장 높게 나타나는 유형이예요.😆

⦁  바로 계획 마스터- 친구들과 여행 갈 때, 계획 짜는 것 만큼은 자신있어요. 시간 단위로 꼼꼼히 일정을 만들고, 앞장서서 교통수단과 숙소를 예약해요.

⦁ 내가 열심히 짠 계획을 누가 마음에 들지 않는다고 하면 상처받아요. 여행 중에도 누가 늦게 일어나 계획에 차질이 생기거나 갑자기 일정과 다른 곳에 가자고 하면 화도 나요. 내가 어떻게 짠 계획인데!

⦁ 혼자 여행하는 것보단 다른 사람들과 함께 여행하는 것이 더 좋아요👫

⦁ 조용하고 정적인 여행도 좋지만, 온 몸으로 즐길 수 있는 여행이 더 즐거워요. 평소에 할 수 없는 신기한 경험을 하거나 색다른 추억을 만들고 싶어요.`;
    case "ENFP":
      return `⦁ ‘우리 여행 갈래?’ 친구들 사이에서 가장 먼저 여행 이야기를 꺼내는 사람, 그게 바로 저예요! 여행가서 하고싶은 것도 많아서 단톡방에 가고싶은 장소들을 셀 수도 없을만큼 많이 올려요!
      
⦁ 하지만 그 곳들을 모두 갈 수 있도록 계획을 체계적으로 짜는 건 잘 못해요. 일단 늘어놓고 나면 어떻게 되지 않을까요?
      
⦁ 여행 가서 돈 아끼는거 아니라고 배웠습니다. 맛있는 거 먹고 좋은 데서 자고, 다 그러려고 돈 버는거죠!🤑
      
⦁ 이거 하자! 저것도 할래? 재밌어 보이는게 너무 많아요! 남들보다 여행을 더욱 다채롭고 풍부하게 경험해요.
      
⦁ 인싸인데 아싸예요. 무슨 느낌인지 알겠나요? 친구들과 함께 가는 여행도 좋아하지만 하루 정도는 혼자 있을 시간이 필요해요.`;
    case "ENTJ":
      return `⦁ 여행 가자고 말만 하고 막상 아무도 나서지 않는 이 상황, 참을 수 없어요. 차라리 내가 리드하겠어요!
      
⦁ 호기심이 많아 여행 중 마주치는 새로운 것들에 흥미를 느껴요. 가끔은 여행 도중 번뜩이는 아이디어가 떠오르기도 해요.
      
⦁ 가만히 앉아 쉬기보다는 부지런히 돌아다닐 수 있는 활동적인 여행지를 선호해요.🪂
      
⦁ 여행 가서 쇼핑하는거 좋아해요! 평소엔 쓰지도 않는 냉장고 자석도 여행지에서 보면 왜이렇게 예뻐보일까요? 소비는 언제나 짜릿해요💰`;
    case "ENTP":
      return `⦁ 여럿이서 가는 여행도 즐겁지만, 독립심이 강해 혼자 가는 여행을 더 좋아해요.
      
⦁ 어느 날 갑자기 훌쩍 떠나는 여행을 좋아해요! 여행 생각이 들면 우선 비행기(버스, 기차) 티켓부터 예매하고 봐요. 상상만 해도 신나지 않나요?
      
⦁ 다들 가는 유명한 관광지, 줄서서 기다려먹는 맛집은 싫어요! 나만 알게 될 숨은 보석 같은 곳들을 찾아내는 게 즐거워요.💎
      
⦁ 다재다능하고 창의력이 넘쳐요. 안목이 뛰어나 여행에서 힐링만 하기보단 영감을 얻을 수 있는 새롭고 다양한 경험들을 하고 싶어요.👨🏻‍🎨`;
    case "ESFP":
      return `⦁ 떠나는 건 너무 좋아하는데 그 전까지가 너무 귀찮아요! 여행 계획 짜고 짐 싸고.. 미루고 미루다가 더 이상 미룰 수 없을 때 시작해요
      
⦁ 그래서 아무런 계획 없이 즉흥적으로 떠나는 여행이 좋아요! 왠지 더 설레는 기분이 들지 않나요?
      
⦁ 당신을 슈퍼 인싸로 임명합니다. 처음 보는 사람에게도 말 잘 걸어요. 여행 중 택시 타고 가는 10분 만에 기사님과 베프 되어본 적, 있으시죠?👍
      
⦁ 호텔 안에만 있기 보다는 밖에 나가서 노는게 훨씬 재밌어요!
      
⦁ 여행 중 갑자기 비가 오거나 한 시간 걸려 달려온 맛집이 문을 닫아도 쉽게 멘붕이 오지 않아요. 비가 오면 운치 있어 좋고 맛집이 문을 닫은 덕에 더 맛있는 식당을 찾게 될 수도 있죠!`;
    case "ESFJ":
      return `⦁ 혼자서 가는 여행은 너무 외로워요. 여행은 함께 가야죠!
      
⦁ 여행 일정을 짤 때에는 친구들이 가고 싶어하는 곳으로 최대한 맞춰주는 편이에요. 난 어디든 좋아요!
      
⦁ 비가 오면 어쩌지? 교통편이 갑자기 지연되면 어쩌지? 여행 가기 전 걱정이 많은 편이에요. 아직 일어나지도 않은 일인데 말예요
      
⦁ 여행 중 마음에 안드는게 있어도 말하지 않고 참는 편이에요. 즐겁자고 놀러간건데 싸우고 싶지 않아요
      
⦁ 남들보다 감수성이 풍부해요. 여행 가서 바라보는 도시의 야경과 산 속의 별빛들, 밤바다의 향기 모두 너무 낭만적이지 않나요?🌙`;
    case "INTJ":
      return `⦁ 혼자 떠나는 여행도 좋아요. 아무도 없는 오롯이 나를 위한 시간이 필요해요.
      
⦁ 하지만 나는 누구에게나 최고의 여행 파트너랍니다. 왜냐구요?
    -매사에 완벽을 추구해요. 여행 계획도 꼼꼼히 세우는 걸 좋아하죠.
    -원칙을 중요시하고 돈 관리도 소홀하게 하지 않아요. 100원까지 꼼꼼하게!🪙
    -단체 활동을 즐기는 편은 아니지만, 막상 모이면 내가 조장일때가 많아요
      
⦁ 평범함은 거부해요! 부산에서 돼지국밥 먹는거 말고, 남들이 안 가본 곳에 가보고 싶어요.`;
    case "INTP":
      return `⦁ 많은 사람이 함께하는 여행보다는 아주 친한 친구 몇 명과 떠나는 여행을 더 좋아해요.
      
⦁ 아주 친한 친구들에게도 먼저 여행 가자고 연락하는 스타일은 아녜요. 하지만 떠나자고 하면 언제든 함께 떠날 준비가 되어있어요!
      
⦁ 가끔 마음이 왔다 갔다 해요. 어제까진 그게 좋았는데 오늘 갑자기 다른게 더 끌릴 수 있는 거잖아요?
      
⦁ 쉿, 다른 사람들에게는 비밀인데 사실 전 감수성이 아주 풍부하답니다. 반짝이는 야경을 보면 왠지 마음이 울렁거려요🌙`;
    case "INFP":
      return `⦁ 여행 계획은 완벽하게 세워야죠! 그런데 너무 귀찮아서 전날까지 아무 계획이 없을 때도 있어요🗒
      
⦁ 어느 날 갑자기 떠나는 상상을 하기도 해요. 딱 오늘처럼 햇볕 좋은 날, 반차내고 제주도로 도망가고 싶어요!🛫
      
⦁ 여행 중 친구에게 마음에 안드는 게 있어도 웬만하면 참고 넘어가요. 잔소리하는 것도 싫고 괜히 싸워서 감정소모하는 것도 싫어요. 그냥 내가 한 번 참고 말지!
      
⦁ 외로운건 싫어요. 나랑 같이 여행 가줄 사람 없나요?
      
⦁ 이 시대의 로맨티스트, 그게 바로 나예요. 분위기에 약해요. 별빛 반짝이는 밤하늘을 함께 바라보면 금방 사랑에 빠질지도 몰라요💕`;
    case "ISFJ":
      return `⦁ 계획 세우는 거 좋아해요! 여행 계획은 더 좋아요! (근데 왜 이렇게 귀찮죠….?)📄
      
⦁ 완벽주의 성향이 있어서 여행도 계획 대로 흘러가야 편하게 즐길 수 있어요
      
⦁ 하지만 나는 위기 대처 능력도 강해요! 일정이 틀어지면 금방 해결책을 찾아낸답니다.
      
⦁ 돈 관리는 나에게 맡겨주세요! 친구들과의 여행에서는 종종 총무 역할을 해요💳`;
    case "ISTJ":
      return `⦁ ‘돌다리도 두들겨보고 건너라’ 나의 좌우명이에요. 매사에 신중하고 책임감이 강해요. 이번 여행에 어디를 갈지, 어디가 정말 맛집인지 고를때도 신중하게!🧐
      
⦁ 그래서 즉흥적인 여행보다는 계획이 짜여져 있는 여행을 좋아해요.📋
      
⦁ 사람 많고 시끄러운 곳..별로 좋아하지 않아요. 마음 편히 쉴 수 있는 조용하고 고즈넉한 곳이 좋아요🍃
      
⦁ 말 많은 사람과 함께 다니면 기 빨려요...대화의 속도가 맞는 사람과 함께하고 싶어요.`;
    case "ISTP":
      return `⦁ 단체여행은 정신없어요! 여럿이서 떠나는 것 보다는 내가 가장 좋아하는 한 사람과 떠나는 여행이 더 좋아요
      
⦁ 하지만 여럿이서 여행을 가게 된다면 함께 즐겨줄 수는 있답니다!
      
⦁ 짜릿한 모험을 좋아해요! 정적인 곳 보다는 활동적이고 재밌는 게 많은 여행지가 좋아요
      
⦁ 계획이 있다면 그건 모험이 아니죠. 충동적으로 떠나는 즉흥 여행, 상상만 해도 신나요!
      
⦁ 예술가의 자질을 타고났어요! 미적 감각이 뛰어나고, 만들기 처럼 손으로 하는 것들을 잘해요.👩‍🎨`;
    case "ISFP":
      return `⦁ 여행을 마음 먹으면 우선 차분하게 계획을 세우기 시작해요. 숙소 예약부터 날씨 체크까지 꼼꼼하고 완벽하게!📋
      
⦁ 하지만 당일에 일정이 흐트러져도 괜찮아요. 다른 방법을 찾아내면 되니까요
      
⦁ 바쁘게 돌아다니는 여행보다는 여유를 즐길 수 있는 여행이 좋아요. 여행의 가장 큰 목적은 힐링이죠-😌
      
⦁ 높은 건물들이 늘어선 곳 보다는 자연의 아름다움을 담뿍 담은 여행지를 좋아해요.🌱
      
⦁ 둥글둥글한 성격을 가지고 있어서 여행, 누구와도 함께 갈 수 있어요`;
    case "INFJ":
      return `⦁ 무작정 떠나기보단 계획적인 여행을 선호해요.
      
⦁ 함께 여행가는 친구들, 보통은 정해져있어요. 그 친구들과는 단단한 우정을 자랑해요.
      
⦁ 평소에 말이 많거나 활발한 성격은 아니지만, 놀러가면 또 곧 잘 논답니다! 활동적인 것들도 싫지 않아요
      
⦁ 그리고 사실 관심받고 싶은 마음도 조금 있어요. 앗, 이건 비밀이에요!🤫`;
  }
};

export const _getBackgroundColor = (MBTI: MBTIType) => {
  switch (MBTI) {
    case "ESTP":
      return "#e28666";
    case "ESTJ":
      return "#d35454";
    case "ENFJ":
      return "#7f5f52";
    case "ENFP":
      return "#f7857c";
    case "ENTJ":
      return "#41415b";
    case "ENTP":
      return "#cea8ef";
    case "ESFP":
      return "#2a6623";
    case "ESFJ":
      return "#ffaeae";
    case "INTJ":
      return "#ddcda8";
    case "INTP":
      return "#fcc2ff";
    case "INFP":
      return "#ffe45f";
    case "ISFJ":
      return "#90b75e";
    case "ISTJ":
      return "#93c3db";
    case "ISTP":
      return "#f7b521";
    case "ISFP":
      return "#5ab5bf";
    case "INFJ":
      return "#ff9566";
  }
};

export const _getBackgroundTop = (MBTI: MBTIType) => {
  switch (MBTI) {
    case "ESTP":
      return ESTPbg;
    case "ESTJ":
      return ESTJbg;
    case "ENFJ":
      return ENFJbg;
    case "ENFP":
      return ENFPbg;
    case "ENTJ":
      return ENTJbg;
    case "ENTP":
      return ENTPbg;
    case "ESFP":
      return ESFPbg;
    case "ESFJ":
      return ESFJbg;
    case "INTJ":
      return INTJbg;
    case "INTP":
      return INTPbg;
    case "INFP":
      return INFPbg;
    case "ISFJ":
      return ISFJbg;
    case "ISTJ":
      return ISTJbg;
    case "ISTP":
      return ISTPbg;
    case "ISFP":
      return ISFPbg;
    case "INFJ":
      return INFJbg;
  }
};

export const _getCityImg = (MBTI: MBTIType) => {
  switch (MBTI) {
    case "ESTP":
      return ESTPcity;
    case "ESTJ":
      return ESTJcity;
    case "ENFJ":
      return ENFJcity;
    case "ENFP":
      return ENFPcity;
    case "ENTJ":
      return ENTJcity;
    case "ENTP":
      return ENTPcity;
    case "ESFP":
      return ESFPcity;
    case "ESFJ":
      return ESFJcity;
    case "INTJ":
      return INTJcity;
    case "INTP":
      return INTPcity;
    case "INFP":
      return INFPcity;
    case "ISFJ":
      return ISFJcity;
    case "ISTJ":
      return ISTJcity;
    case "ISTP":
      return ISTPcity;
    case "ISFP":
      return ISFPcity;
    case "INFJ":
      return INFJcity;
  }
};

export const _getResultImg = (MBTI: MBTIType) => {
  switch (MBTI) {
    case "ESTP":
      return ESTPresult;
    case "ESTJ":
      return ESTJresult;
    case "ENFJ":
      return ENFJresult;
    case "ENFP":
      return ENFPresult;
    case "ENTJ":
      return ENTJresult;
    case "ENTP":
      return ENTPresult;
    case "ESFP":
      return ESFPresult;
    case "ESFJ":
      return ESFJresult;
    case "INTJ":
      return INTJresult;
    case "INTP":
      return INTPresult;
    case "INFP":
      return INFPresult;
    case "ISFJ":
      return ISFJresult;
    case "ISTJ":
      return ISTJresult;
    case "ISTP":
      return ISTPresult;
    case "ISFP":
      return ISFPresult;
    case "INFJ":
      return INFJresult;
  }
};

export const _getCityName = (MBTI: MBTIType) => {
  switch (MBTI) {
    case "ESTP":
      return "원주";
    case "ESTJ":
      return "통영";
    case "ENFJ":
      return "문경";
    case "ENFP":
      return "속초";
    case "ENTJ":
      return "단양";
    case "ENTP":
      return "파주";
    case "ESFP":
      return "양양";
    case "ESFJ":
      return "가평";
    case "INTJ":
      return "공주/부여";
    case "INTP":
      return "태안/서산";
    case "INFP":
      return "거제";
    case "ISFJ":
      return "제천/충주";
    case "ISTJ":
      return "순천/보성";
    case "ISTP":
      return "춘천";
    case "ISFP":
      return "인제";
    case "INFJ":
      return "남해";
  }
};

export const _getCityDesc = (MBTI: MBTIType) => {
  switch (MBTI) {
    case "ESTP":
      return "예술과 자연이 공존하는 도시";
    case "ESTJ":
      return "제대로 즐기려면 계획은 필수!";
    case "ENFJ":
      return "자연속에서 즐기는 짜릿함";
    case "ENFP":
      return "황홀한 풍경과 먹을거리, 무엇하나 놓칠 수 없다면";
    case "ENTJ":
      return "소백산 바람을 온 몸으로 느낄 수 있는";
    case "ENTP":
      return "나만 알고 싶은 예술과 문학의 도시";
    case "ESFP":
      return "인싸들의 성지, 서피비치가 있는";
    case "ESFJ":
      return "감성적인 여행 스팟의 집합체";
    case "INTJ":
      return "나 홀로 떠나는 백제로의 여행";
    case "INTP":
      return "바다부터 문화재까지 서해의 모든 아름다움이 있는 곳";
    case "INFP":
      return "한가롭고 청청한";
    case "ISFJ":
      return "맑은 바람과 밝은 달이 드리운 도시";
    case "ISTJ":
      return "드넓은 갈대밭과 녹차밭이 아름다운 생태문화지";
    case "ISTP":
      return "이따가 닭갈비 먹으러 갈래?";
    case "ISFP":
      return "신비로운 풍광을 담고 있는";
    case "INFJ":
      return "해맞이하기 가장 좋은 도시";
  }
};

export const _getCityDescription = (MBTI: MBTIType) => {
  switch (MBTI) {
    case "ESTP":
      return `✏️<b>예술을 즐기는 꿩</b>들과 가장 잘 어울리는 우리나라 여행지는 치악산 자락에 자리한 원주입니다.\n\n예술과 자연의 아름다움을 사랑하는 동시에 짜릿한 스릴을 즐기는 <b>꿩</b>들은, 원주의 숨겨진 보석같은 스팟들을 좋아할 거예요.`;
    case "ESTJ":
      return `✏️<b>똑부러진 동백꽃</b>들과 가장 잘 어울리는 우리나라 여행지는 이순신 장군의 도시, 통영입니다.\n\n통영은 액티비티부터 관광지까지 다채로운 여행을 할 수 있는 곳이기 때문에, 모두 놓치지 않고 경험하기 위해서 꼼꼼한 사전 예약과 철저한 시간 계획은 필수!! 똑부러진 동백꽃의 꼼꼼한 계획성이 꼭 필요하답니다.`;
    case "ENFJ":
      return `✏️문경은 자연이 선사하는 시원한 풍경과 이를 색다르게 즐길 수 있는 다양한 체험들이 공존하는 곳으로, 친구들과 함께 온 몸으로 즐길 수 있는 여행을 하고싶은 <b>본투비트래블러 매</b>에게 딱 맞는 여행지입니다.\n\n짜릿한 어트랙션부터 별빛 수놓은 하늘 아래 캠핑까지 친구들과 함께하다 보면 오래도록 기억에 남는 추억을 만들 수 있을거예요.`;
    case "ENFP":
      return `✏️늘 하고 싶은게 많은 <b>여행 좀 즐길 줄 아는 오징어</b>에게는 산과 바다를 동시에 만끽할 수 있는 속초가 제격이에요. 가고싶은 곳은 너무 많은데 막상 계획 짜려고 하면 귀찮죠?\n\n속초는 계획 없이 당장 떠나도 좋은 꿀 같은 여행지랍니다. 꼭 가야하는 관광 스팟들이 서로 가까이 위치하고 있어 이것도 하고 싶고 저것도 하고싶은 오징어들이 뚜벅이로 여행하기에도 무리가 없어요!`;
    case "ENTJ":
      return `✏️"아무도 안해? 그럼 내가 해!" <b>카리스마 철철 까치</b>에게 추천하고 싶은 여행지는 한반도의 기상이 담고 있는 곳, 단양입니다.\n\n짚와이어부터 패러글라이딩까지 역동적인 액티비티와 함께 우리나라의 산과 바람을 담은 풍경을 즐기다 보면 새로운 영감을 얻을 수 있을거예요.`;
    case "ENTP":
      return `✏️어느 날 혼자 훌쩍 떠나고 싶은 <b>꽃보다 청춘🌸코스모스</b>에게 추천하는 여행지는 파주입니다. 파주는 우리에게 익숙한 느낌의 여행지는 아니지만, 다들 가는 유명한 곳보다 숨은 보석 같은 곳들을 좋아하는 코스모스에겐 그 어디보다 완벽한 여행지가 될 거예요.\n\n서울과 멀지 않은 곳에 위치하고 있어 여행 생각이 나는 지금, 당장이라도 떠날 수 있는 곳이랍니다.`;
    case "ESFP":
      return `✏️"흰 천과 바람만 있으면 어디든 갈 수 있어" <b>자유로운 영혼을 가진 소나무</b>에게 추천하는 여행지는 양양입니다.\n\n별 다른 계획 없이 떠나도 좋아요. 열심히 달려 도착한 곳 어디든 푸르른 바다와 깨끗한 바람이 소나무를 환하게 반겨줄거예요.`;
    case "ESFJ":
      return `✏️남들보다 풍부한 감수성을 가지고 있는 <b>사려깊은 잣</b>에게 딱 어울리는 여행지는 낭만 도시의 대명사, 가평이에요.\n\n가평은 누구와 가도 좋지만, 사랑하는 사람과 함께라면 도시 구석구석 묻어나는 로맨틱한 향기에 취해버릴지도 몰라요.`;
    case "INTJ":
      return `✏️꼼꼼한 계획성과 적당한 모험심까지, 여행을 제대로 즐길 줄 아는 <b>최고의 여행메이트 곰</b>은 혼자가도 함께가도 좋은 여행지 공주, 그리고 부여와 잘 어울려요.\n\n역사가 살아 숨쉬는 두 도시로 떠나보시는 건 어떨까요?`;
    case "INTP":
      return `✏️겉으로는 새침해보여도 속은 누구보다 여린 <b>도도한 매력의 꽃게</b>와 잘 어울리는 여행지는 서쪽의 아름다움을 담뿍 담은 태안과 서산이에요.\n\n가까운 사람들과 함께 자연 속으로 떠나는 한가로운 여행, 어떠세요? 도시에서는 잘 보이지 않던 별빛을 바라보고 있으면 마음이 저절로 몽글몽글해질거예요!`;
    case "INFP":
      return `✏️이 시대의 마지막 로맨티스트, <b>감성적인 유자</b>와 꼭 닮은 여행지는 거제입니다. 섬을 감싸며 부는 바람이 상쾌해서 신선도 내려와 반한 거제로 내가 가장 좋아하는 사람과 단 둘이 떠나는 여행, 상상만으로도 설레이지 않나요?\n\n거제는 섬을 둘러싼 바다와 하늘만으로도 충분한 곳이라, 어느 날 생각 없이 훌쩍 떠나기에도 참 좋답니다.`;
    case "ISFJ":
      return `✏️<b>'계획대로 되고 있어' 사과나무</b>에게 추천하는 여행지는 사계절 수려한 풍경을 자랑하는 제천과 충주입니다.\n\n서로 가까이 위치한 두 도시는 한꺼번에 둘러보고 오기에 좋아 사과나무의 꼼꼼한 계획성이 빛을 발하는 여행지이기도 하답니다!`;
    case "ISTJ":
      return `✏️모든 일에 차분하고 꼼꼼한 <b>'돌다리도 두들겨보고' 흑두루미</b>와 잘 어울리는 여행지는 잔잔한 자연이 펼쳐진 순천과 보성이에요.\n\n고즈넉한 매력의 두 도시는 복잡한 마음을 편안히 기대기에 참 좋답니다.`;
    case "ISTP":
      return `✏️"짜릿함을 찾아 무작정 떠나는게 좋아!" <b>모험가의 자질을 가진 개나리</b>에게 딱 어울리는 여행지는 닭갈비의 고장 춘천이에요.\n\n춘천은 기차를 타면 서울에서 1시간 만에 도착할 수 있어 문득 여행생각이 날 때 즉흥적으로 떠나기에도 좋아요. `;
    case "ISFP":
      return `✏️자연의 고즈넉함에 매료된 <b>청산에 살어리랏다 수달</b>들은 산과 하천이 아름답게 어우러진 인제를 사랑할 수밖에 없을거예요.\n\n사람 많은 관광지를 찾아 꼭 바쁘게 돌아다니지 않아도 괜찮아요. 머리 아픈 도시를 떠나있는 것, 그 자체로 멋진 여행인걸요!`;
    case "INFJ":
      return `✏️수줍음이 많지만, 사실 누구보다 신나는 걸 좋아하는 내적 흥 충만한 백로에게는 눈부신 바다 옆에 자리잡은 마을, 남해에 있는 여행지들이 어울려요.\n\n아침부터 밤까지 아름다운 풍경을 즐기며 여행하다 보면 친구들과 오래도록 기억에 남는 추억을 만들 수 있을거예요.`;
  }
};

export const _getHighlights = (MBTI: MBTIType) => {
  switch (MBTI) {
    case "ESTP":
      return [
        {
          name: "뮤지엄산",
          description:
            "세계적인 건축가 안도 타다오가 설계한 전원형 뮤지엄 <b>#뮤지엄산</b>은 예술적 소양이 뛰어난 여행자님께 영감을 선물할거예요.",
          insta: [
            {
              link: "https://www.instagram.com/p/CMdww4isFLz/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTP_0_0.jpeg",
              authorName: "@332mg",
            },
            {
              link: "https://www.instagram.com/p/CGwbANmpymL/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTP_0_1.jpeg",
              authorName: "@museumsan_official",
            },
            {
              link: "https://www.instagram.com/p/BucksyGjHkX/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTP_0_2.jpeg",
              authorName: "@museumsan_official",
            },
            {
              link: "https://www.instagram.com/p/B4bc4VMpGp0/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTP_0_3.jpeg",
              authorName: "@museumsan_official",
            },
            {
              link: "https://www.instagram.com/p/B-LjHQbJJzV/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTP_0_4.jpeg",
              authorName: "@museumsan_official",
            },
          ],
        },
        {
          name: "소금산 출렁다리",
          description:
            "보기만 해도 아찔한 <b>#소금산출렁다리</b> 한 가운데서 여행자님의 용기를 마음껏 뽐내보시는 건 어떤가요?",
          insta: [
            {
              link: "https://www.instagram.com/p/CG9Cx8PFCy9/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTP_1_0.jpeg",
              authorName: "@1994.8p__",
            },
            {
              link: "https://www.instagram.com/p/CHNeBTClVCq/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTP_1_1.jpeg",
              authorName: "@kwondh27",
            },
            {
              link: "https://www.instagram.com/p/CEtsmzSsZwx/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTP_1_2.jpeg",
              authorName: "@hallyutrail",
            },
            {
              link: "https://www.instagram.com/p/CNIHqGbJ1gJ/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTP_1_3.jpeg",
              authorName: "@eunwon_chun",
            },
            {
              link: "https://www.instagram.com/p/CMJ_3FUDBvN/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTP_1_4.jpeg",
              authorName: "@kwon_dajung",
            },
          ],
        },
        {
          name: "원주레일바이크",
          description:
            "원주의 아름다운 자연 풍경을 한 눈에 담을 수 있는 <b>#원주레일바이크</b>, 친구들과 열심히 페달을 굴리다보면 펼쳐지는 장관이 저절로 추억으로 남을거예요",
          insta: [
            {
              link: "https://www.instagram.com/p/CFloyutBZr4/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTP_2_0.jpeg",
              authorName: "@hani___ee",
            },
            {
              link: "https://www.instagram.com/p/CNEeirQnQR9/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTP_2_1.jpeg",
              authorName: "@listen1ju",
            },
            {
              link: "https://www.instagram.com/p/CD-59oqHjh2/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTP_2_2.jpeg",
              authorName: "@seo_yunju",
            },
            {
              link: "https://www.instagram.com/p/CG1rkzlBHRr/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTP_2_3.jpeg",
              authorName: "@nobbooo",
            },
          ],
        },
        {
          name: "복숭아빵",
          description:
            "돌아오면서 <b>#복숭아빵</b> 한 박스는 필수! 아시죠? 귀여운 미니 복숭아 모양을 하고 있어 선물하기에도 좋아요",
          insta: [
            {
              link: "https://www.instagram.com/p/B6W-MNjjzuv/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTP_3_0.jpeg",
              authorName: "@gmarketstory",
            },
            {
              link: "https://www.instagram.com/p/CGWKwCLJSgQ/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTP_3_1.jpeg",
              authorName: "@plainn__",
            },
            {
              link: "https://www.instagram.com/p/CGQ4oxrF76l/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTP_3_2.jpeg",
              authorName: "@metamjang",
            },
          ],
        },
      ];
    case "ESTJ":
      return [
        {
          name: "이순신공원",
          description:
            "<b>#이순신공원</b>에는 웅장한 한려수도와 이순신 장군의 기운이 서려 있어요. 탁 트인 바다 전경을 바라보며 산책길을 걷다보면 복잡하던 머리를 비울 수 있을거예요.",
          insta: [
            {
              link: "https://www.instagram.com/p/CFotE3yHhMR/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTJ_0_0.jpeg",
              authorName: "@bonaa__j",
            },
            {
              link: "https://www.instagram.com/p/CNFEl8ZMdKm/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTJ_0_1.jpeg",
              authorName: "@09._.photo",
            },
            {
              link: "https://www.instagram.com/p/CNSWhPsnn2e/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTJ_0_2.jpeg",
              authorName: "@daily_mingdi",
            },
            {
              link: "https://www.instagram.com/p/CMg6mhaMuiG/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTJ_0_3.jpeg",
              authorName: "@juno_pic_",
            },
            {
              link: "https://www.instagram.com/p/CNKFoJ4Fsa7/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTJ_0_4.jpeg",
              authorName: "@latte13",
            },
          ],
        },
        {
          name: "충렬사",
          description:
            "이어서 <b>#충렬사</b>로 향하면 정당에 모시고 있는 이순신 장군의 위패와  전시 중인 다양한 유물들을 볼 수 있어요. 충렬사는 가을에 방문하면 커다란 은행나무에 노오란 잎이 풍성하게 돋아 더욱 아름다워요.",
          insta: [
            {
              link: "https://www.instagram.com/p/CNUFmM8ljwr/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTJ_1_0.jpeg",
              authorName: "@tong0tour",
            },
            {
              link: "https://www.instagram.com/p/CIm6sqCFqkT/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTJ_1_1.jpeg",
              authorName: "@stay_in_red",
            },
            {
              link: "https://www.instagram.com/p/CHmYgFCsdoL/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTJ_1_2.jpeg",
              authorName: "@yetiyoon_s",
            },
            {
              link: "https://www.instagram.com/p/CHj_ynaFvHv/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTJ_1_3.jpeg",
              authorName: "@leenaninani",
            },
            {
              link: "https://www.instagram.com/p/BtSX_eYgFmP/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTJ_1_4.jpeg",
              authorName: "@tongyeongsi",
            },
          ],
        },
        {
          name: "스카이라인 루지",
          description:
            "<b>#스카이라인 루지</b>, 혹시 들어보셨나요? 경사와 중력만으로 내려가는 놀이기구라니 시시하겠지 생각하셨다면 큰 오산입니다. 타보면 예상보다 빠른 스피드에 깜짝 놀랄지도 몰라요.",
          insta: [
            {
              link: "https://www.instagram.com/p/CHPJUsTFqe6/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTJ_2_0.jpeg",
              authorName: "@skylinelugetongyeong",
            },
            {
              link: "https://www.instagram.com/p/CFLegLFIHkt/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTJ_2_1.jpeg",
              authorName: "@skylinelugetongyeong",
            },
            {
              link: "https://www.instagram.com/p/CJQqiBGnRIP/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTJ_2_2.jpeg",
              authorName: "@eyes__pretty",
            },
            {
              link: "https://www.instagram.com/p/CLA-iV5FMOh/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTJ_2_3.jpeg",
              authorName: "@withuzza",
            },
            {
              link: "https://www.instagram.com/p/CKANAhYlWmA/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTJ_2_4.jpeg",
              authorName: "@ku_qcoach",
            },
          ],
        },
        {
          name: "소매물도",
          description:
            "<b>#소매물도</b>는 통영에서 가장 아름답기로 유명한 섬으로, 파도가 만들어낸 바위절벽과 옥색의 바다를 감상할 수 있어요. 소매물도는 통영과 거제에서 배를 타고 들어가야 하기 때문에 예약은 필수! 몽돌길을 통해 등대섬까지 가기 위해서는 하루에 두 번, ‘신비의 바닷길'이 열리는 썰물 시간을 잘 맞춰가야 한답니다.",
          insta: [
            {
              link: "https://www.instagram.com/p/CDIlP8ls7Yh/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTJ_3_0.jpeg",
              authorName: "@young_flower_ing",
            },
            {
              link: "https://www.instagram.com/p/CCFrCYujzwM/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTJ_3_1.jpeg",
              authorName: "@mongle_jyh",
            },
            {
              link: "https://www.instagram.com/p/CB2bi6hjmY8/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTJ_3_2.jpeg",
              authorName: "@leesubong4511",
            },
            {
              link: "https://www.instagram.com/p/B5E46UGg-ub/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTJ_3_3.jpeg",
              authorName: "@from_nature___",
            },
            {
              link: "https://www.instagram.com/p/CF6fdwpsxyf/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESTJ_3_4.jpeg",
              authorName: "@baebae.photo",
            },
          ],
        },
      ];
    case "ENFJ":
      return [
        {
          name: "문경짚라인",
          description:
            "<b>#문경짚라인</b>은 산을 색다르게 경험할 수 있는 어트랙션입니다. 마치 타잔이 된 듯, 줄 하나에 내 몸을 맡기고 아홉 개의 코스를 따라 산 이곳저곳을 날아보세요!",
          insta: [
            {
              link: "https://www.instagram.com/p/Bjg7YMZn2Tn/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFJ_0_0.jpeg",
              authorName: "@yeoseungmi7",
            },
            {
              link: "https://www.instagram.com/p/CDgdwhoD13N/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFJ_0_1.jpeg",
              authorName: "@daily_nail_365",
            },
            {
              link: "https://www.instagram.com/p/CErZa6mHjFW/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFJ_0_2.jpeg",
              authorName: "@cutesun7",
            },
            {
              link: "https://www.instagram.com/p/CEBYOWVnmzE/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFJ_0_3.jpeg",
              authorName: "@ziplinemungyeong",
            },
            {
              link: "https://www.instagram.com/p/B_HQyuAnqho/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFJ_0_4.jpeg",
              authorName: "@ziplinemungyeong",
            },
          ],
        },
        {
          name: "문경관광사격장",
          description:
            "빵야빵야🔫 <b>#문경관광사격장</b>에서는 클레이사격과 공기소총사격, 평소에 해보지 않은 실탄사격까지 다양한 사격체험을 할 수 있는 곳이에요. 과녁을 명중하며 스트레스를 날려보세요!",
          insta: [
            {
              link: "https://www.instagram.com/p/B3wTCmInnaj/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFJ_1_0.jpeg",
              authorName: "@kamtae__",
            },
            {
              link: "https://www.instagram.com/p/Bx4wQC5FUwG/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFJ_1_1.jpeg",
              authorName: "@ssong_ddong",
            },
            {
              link: "https://www.instagram.com/p/CFmLTSojKmA/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFJ_1_2.jpeg",
              authorName: "@badday79",
            },
            {
              link: "https://www.instagram.com/p/CFWm0lCM1Qo/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFJ_1_3.jpeg",
              authorName: "@jo__sungeun",
            },
            {
              link: "https://www.instagram.com/p/B47DDABhjjd/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFJ_1_4.jpeg",
              authorName: "@94_.32",
            },
          ],
        },
        {
          name: "문경단산모노레일/패러글라이딩",
          description:
            "단산 위에 놓인 철길을 따라 움직이는 <b>#문경단산모노레일</b>. 편도로 레일을 타고 올라간 후, 패러글라이딩으로 산을 내려올 수도 있어요",
          insta: [
            {
              link: "https://www.instagram.com/p/CHDOhpgM0kC/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFJ_2_0.jpeg",
              authorName: "@k_levi03",
            },
            {
              link: "https://www.instagram.com/p/CLdTxp2nVnF/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFJ_2_1.jpeg",
              authorName: "@travel_behappyhere",
            },
            {
              link: "https://www.instagram.com/p/CH1UFbzgbqK/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFJ_2_2.jpeg",
              authorName: "@wakawaka_titi",
            },
            {
              link: "https://www.instagram.com/p/CNKo5AzFsGw/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFJ_2_3.jpeg",
              authorName: "@wongeun_hyerim",
            },
            {
              link: "https://www.instagram.com/p/CNFKdi-F2Zd/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFJ_2_4.jpeg",
              authorName: "@oneway3988",
            },
          ],
        },
        {
          name: "단산오토캠핑장",
          description:
            "<b>#단산오토캠핑장</b>은 여느 캠핑장들과는 달리 해발 960미터 산 정상에 위치해있어요. 하늘 바로 아래에 텐트를 치고, 달빛전망대에서 밤하늘을 바라보며 낭만적인 하룻밤을 보낼 수 있답니다.",
          insta: [
            {
              link: "https://www.instagram.com/p/CHQWMp8FeRu/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFJ_3_0.jpeg",
              authorName: "@twin_jidoong",
            },
            {
              link: "https://www.instagram.com/p/CHRjLMZld_0/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFJ_3_1.jpeg",
              authorName: "@seolmom34",
            },
            {
              link: "https://www.instagram.com/p/CGY0DqjlIGO/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFJ_3_2.jpeg",
              authorName: "@buendia_paella",
            },
            {
              link: "https://www.instagram.com/p/BsFbQian4_o/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFJ_3_3.jpeg",
              authorName: "@son01064808516",
            },
          ],
        },
      ];
    case "ENFP":
      return [
        {
          name: "아바이마을",
          description:
            "속초에 가면 가장 먼저 먹어야 하는 것, 오징어순대가 있는 <b>#아바이마을</b>에 방문해보세요. 마을에 들어가려면 ‘갯배'를 타야하는데, 내가 직접 쇠줄을 잡아당겨 배를 움직이는 이색적인 체험을 할 수 있어요.",
          insta: [
            {
              link: "https://www.instagram.com/p/CIvBoyLsQiB/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFP_0_0.jpeg",
              authorName: "@crossjwon_tripic",
            },
            {
              link: "https://www.instagram.com/p/CFn4ZDMl5r_/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFP_0_1.jpeg",
              authorName: "@soolanzoo",
            },
            {
              link: "https://www.instagram.com/p/CIhk_Fbs33h/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFP_0_2.jpeg",
              authorName: "@crossjwon_tripic",
            },
            {
              link: "https://www.instagram.com/p/CLJFKj3M_IV/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFP_0_3.jpeg",
              authorName: "@kimhakjaelandscape",
            },
            {
              link: "https://www.instagram.com/p/CFTevKDs5ma/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFP_0_4.jpeg",
              authorName: "@h.sun20207",
            },
          ],
        },
        {
          name: "영금정",
          description:
            "동명항 끝자락에 있는 <b>#영금정</b>은 과거 파도가 바위에 부딪히며 내는 소리가 마치 아름다운 거문고 소리와 같다고 하여 붙여진 이름이래요. 일제강점기에 바위를 폭파해 지금은 그 선율을 들을 수 없지만, 탁 트인 동해 전경과 바다에 비친 일출, 일몰 풍경은 여전히 신비롭답니다.",
          insta: [
            {
              link: "https://www.instagram.com/p/CGXKZPLMD-R/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFP_1_0.jpeg",
              authorName: "@foto_jinb",
            },
            {
              link: "https://www.instagram.com/p/CK8bNuBl9hj/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFP_1_1.jpeg",
              authorName: "@1994.8p__",
            },
            {
              link: "https://www.instagram.com/p/CL01pITMfvU/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFP_1_2.jpeg",
              authorName: "@sullyn____",
            },
            {
              link: "https://www.instagram.com/p/CLbgTSqAgLg/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFP_1_3.jpeg",
              authorName: "@k_jjini_",
            },
            {
              link: "https://www.instagram.com/p/CM3WSdXnpkO/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFP_1_4.jpeg",
              authorName: "@sido_rae_",
            },
          ],
        },
        {
          name: "바다향기로",
          description:
            "<b>#바다향기로</b>는 여행 둘째 날 아침, 친구들이 잠에서 깨기 전에 혼자 아침바람 냄새를 맡으며 거닐기 좋은 산책로예요.",
          insta: [
            {
              link: "https://www.instagram.com/p/CIH0_VSMZ2z/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFP_2_0.jpeg",
              authorName: "@for.comma",
            },
            {
              link: "https://www.instagram.com/p/B-AEjJqhfW6/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFP_2_1.jpeg",
              authorName: "@108trouble",
            },
            {
              link: "https://www.instagram.com/p/CM9F2tOlFv3/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFP_2_2.jpeg",
              authorName: "@ann840603",
            },
            {
              link: "https://www.instagram.com/p/CNBv_y2BFC0/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFP_2_3.jpeg",
              authorName: "@jiyeyun",
            },
            {
              link: "https://www.instagram.com/p/CLrnenplskw/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFP_2_4.jpeg",
              authorName: "@ba_da_mam",
            },
          ],
        },
        {
          name: "봉브레드마늘바게트",
          description:
            "집으로 돌아오기 전, 빈 손은 아쉬우니 촉촉한 마늘크림이 가득한 <b>#봉브레드마늘바게트</b>도 하나 사들고 오자구요!",
          insta: [
            {
              link: "https://www.instagram.com/p/CDablG-FQHY/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFP_3_0.jpeg",
              authorName: "@___j.0",
            },
            {
              link: "https://www.instagram.com/p/CGAQzipF0S7/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFP_3_1.jpeg",
              authorName: "@nobody_can_stop_me22",
            },
            {
              link: "https://www.instagram.com/p/CMVzjomF_tX/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFP_3_2.jpeg",
              authorName: "@uni110111",
            },
            {
              link: "https://www.instagram.com/p/BnBT2zXAvBm/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFP_3_3.jpeg",
              authorName: "@dwae.g.1",
            },
            {
              link: "https://www.instagram.com/p/CHsVrUrHgay/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENFP_3_4.jpeg",
              authorName: "@cook_king1011",
            },
          ],
        },
      ];
    case "ENTJ":
      return [
        {
          name: "카페산, 패러글라이딩",
          description:
            "단양은 비가 내리는 날이 많지 않고 기후가 온화한 양방산이 위치해 있는 덕에 <b>#패러글라이딩</b>의 성지라고 불리우는 곳입니다. 날개 없이 하늘을 날아보는 일. 상상만으로도 짜릿하지 않나요? 활공장 근처에 위치한 <b>#카페산</b>은 맛있는 빵과 인생샷 명소로도 유명해요.",
          insta: [
            {
              link: "https://www.instagram.com/p/CHIU7uBs_nb/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTJ_0_0.jpeg",
              authorName: "@lee_seung_pyo",
            },
            {
              link: "https://www.instagram.com/p/CL6PYh_ArMn/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTJ_0_1.jpeg",
              authorName: "@wani_do",
            },
            {
              link: "https://www.instagram.com/p/CH47svQsezZ/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTJ_0_2.jpeg",
              authorName: "@gohome_masil",
            },
            {
              link: "https://www.instagram.com/p/CMEhzUsFm3N/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTJ_0_3.jpeg",
              authorName: "@so__________yulmom",
            },
            {
              link: "https://www.instagram.com/p/CNeCgqejpmq/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTJ_0_4.jpeg",
              authorName: "@mingtto._",
            },
          ],
        },
        {
          name: "만천하 테마파크 / 스카이워크",
          description:
            "<b>#만천하테마파크</b>에서는 짚와이어, 알파인코스터 등 평소에 즐기기 어려운 액티비티와 남한강 절벽 위 투명한 유리를 걷는 특별한 경험을 할 수 있어요. 누가누가 더 용감한지 친구들과 내기해봐요!",
          insta: [
            {
              link: "https://www.instagram.com/p/CHq-Pb4MBX7/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTJ_1_0.jpeg",
              authorName: "@gohome_masil",
            },
            {
              link: "https://www.instagram.com/p/CI4lmypMw_x/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTJ_1_1.jpeg",
              authorName: "@kimhakjaelandscape",
            },
            {
              link: "https://www.instagram.com/p/CJa4UK7sDxE/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTJ_1_2.jpeg",
              authorName: "@gungs_view.w",
            },
            {
              link: "https://www.instagram.com/p/CNhkSzCljvq/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTJ_1_3.jpeg",
              authorName: "@m.h.jeon",
            },
            {
              link: "https://www.instagram.com/p/CNecuBFDKHV/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTJ_1_4.jpeg",
              authorName: "@jina_7480",
            },
          ],
        },
        {
          name: "수양개빛터널",
          description:
            "몇십년간 방치되어 어두컴컴했던 터널을 반짝이는 빛과 미디어로 가득 채워 재탄생시킨 <b>수양개빛터널</b>에 방문해보시는 건 어떤가요? 밤에 방문하면 야외에 조성된 비밀의 정원까지 더욱 다채로운 조형물을 감상할 수 있어요.",
          insta: [
            {
              link: "https://www.instagram.com/p/CIQcypUlIlK/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTJ_2_0.jpeg",
              authorName: "@lib_era_me",
            },
            {
              link: "https://www.instagram.com/p/CBDGMSnDvjk/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTJ_2_1.jpeg",
              authorName: "@__hyenii_",
            },
            {
              link: "https://www.instagram.com/p/CNfL6jWhklX/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTJ_2_2.jpeg",
              authorName: "@ss_yeon_0225",
            },
            {
              link: "https://www.instagram.com/p/CNctemHhovu/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTJ_2_3.jpeg",
              authorName: "@fedfit_xx__",
            },
            {
              link: "https://www.instagram.com/p/CM4gv8FBxTZ/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTJ_2_4.jpeg",
              authorName: "@choi_brother7",
            },
          ],
        },
        {
          name: "도담삼봉과 석문",
          description:
            "단양의 수려한 경치를 일컫는 '단양 팔경' 중에서도 제1경으로 꼽히는 <b>도담삼봉</b>은 과거 정도전이 사랑했던 곳이에요. 세 개의 봉우리 중 중앙에 정자를 짓고 시를 읊고, 호 까지 삼봉이라고 지을 정도라니, 그 풍경이 궁금하지 않으신가요?",
          insta: [
            {
              link: "https://www.instagram.com/p/CH66EEEsOx4/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTJ_3_0.jpeg",
              authorName: "@namchosun_landscapes",
            },
            {
              link: "https://www.instagram.com/p/CJGO4X7s7nV/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTJ_3_1.jpeg",
              authorName: "@gohome_masil",
            },
            {
              link: "https://www.instagram.com/p/CG3AHJlM945/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTJ_3_2.jpeg",
              authorName: "@lovestream_korea",
            },
            {
              link: "https://www.instagram.com/p/CNh0jDqr-8g/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTJ_3_3.jpeg",
              authorName: "@1989_rpm_",
            },
            {
              link: "https://www.instagram.com/p/CNh5XBigenS/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTJ_3_4.jpeg",
              authorName: "@y.___.y_96",
            },
          ],
        },
      ];
    case "ENTP":
      return [
        {
          name: "헤이리예술마을",
          description:
            "<b>#헤이리예술마을</b>은 380여명의 예술인들이 모여 만든 마을이에요. 건물과 조형물 하나하나가 모두 조형미를 갖추고 있어 마을 전체가 마치 하나의 큰 예술작품 같답니다. 창의력이 넘치는 코스모스에게는 마을 구석구석까지도 영감이 되어줄거예요.",
          insta: [
            {
              link: "https://www.instagram.com/p/CMcEidnMZ6h/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTP_0_0.jpeg",
              authorName: "@implanter_",
            },
            {
              link: "https://www.instagram.com/p/CNUVocqJI9P/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTP_0_1.jpeg",
              authorName: "@suuuuu.za",
            },
            {
              link: "https://www.instagram.com/p/CM_VIJcJlUk/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTP_0_2.jpeg",
              authorName: "@chae_chae__",
            },
            {
              link: "https://www.instagram.com/p/CM6yficMzln/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTP_0_3.jpeg",
              authorName: "@gyuya_",
            },
            {
              link: "https://www.instagram.com/p/CNPsFNrFD4F/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTP_0_4.jpeg",
              authorName: "@sunday_luna",
            },
          ],
        },
        {
          name: "평화누리공원",
          description:
            "<b>#평화누리공원</b>은 알록달록한 바람개비들이 세워져있는 ‘바람의 언덕'으로 유명한 곳입니다. 드넓은 잔디언덕에 속이 뻥 뚫리는 기분을 느낄 수 있어요.",
          insta: [
            {
              link: "https://www.instagram.com/p/CFeSbpcg9ad/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTP_1_0.jpeg",
              authorName: "@travel_behappyhere",
            },
            {
              link: "https://www.instagram.com/p/CFgQxmhA2HR/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTP_1_1.jpeg",
              authorName: "@__1_seul__",
            },
            {
              link: "https://www.instagram.com/p/CGB6LMzM0SZ/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTP_1_2.jpeg",
              authorName: "@simbakoong",
            },
            {
              link: "https://www.instagram.com/p/CFETX2UpjNW/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTP_1_3.jpeg",
              authorName: "@bonmelie_m",
            },
            {
              link: "https://www.instagram.com/p/B9wOMgJnEI7/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTP_1_4.jpeg",
              authorName: "@chsj1109",
            },
          ],
        },
        {
          name: "파주출판도시",
          description:
            "서점에서 나는 책의 향기를 좋아하는 분이라면 <b>#파주출판도시</b>를 사랑할 수밖에 없을거예요. 출판의 역사를 엿볼 수 있는 ‘활자의 숲’과 세상 모든 지식이 모여있는 ‘지혜의 숲'에서 문학을 오감으로 느껴보세요.",
          insta: [
            {
              link: "https://www.instagram.com/p/CIF5aQhsWLV/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTP_2_0.jpeg",
              authorName: "@colorny",
            },
            {
              link: "https://www.instagram.com/p/CGCqA0ll6pN/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTP_2_1.jpeg",
              authorName: "@davely06",
            },
            {
              link: "https://www.instagram.com/p/CKYTZuLp5I4/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTP_2_2.jpeg",
              authorName: "@mimesis_art_museum",
            },
            {
              link: "https://www.instagram.com/p/CAU157TlSvf/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTP_2_3.jpeg",
              authorName: "@eunphoto_style",
            },
            {
              link: "https://www.instagram.com/p/B91UV82DyPp/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTP_2_4.jpeg",
              authorName: "@goldssyy",
            },
          ],
        },
        {
          name: "프로방스마을",
          description:
            "알록달록한 지중해의 감성, <b>#프로방스마을</b>에서도 느낄 수 있어요. 동화 속에 들어온 듯 몽글몽글한 풍경을 자랑하는 프로방스마을은 빚축제 기간이면 낮보다 밤에 더욱 아름답답니다.",
          insta: [
            {
              link: "https://www.instagram.com/p/B5jzUtjgRbf/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTP_3_0.jpeg",
              authorName: "@_junho_choi_",
            },
            {
              link: "https://www.instagram.com/p/CF7CQXFlR-G/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTP_3_1.jpeg",
              authorName: "@seo_jini04",
            },
            {
              link: "https://www.instagram.com/p/B0VisqnHIu4/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTP_3_2.jpeg",
              authorName: "@bloom213",
            },
            {
              link: "https://www.instagram.com/p/CJTSbI-slKM/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ENTP_3_3.jpeg",
              authorName: "@oooctwooo",
            },
          ],
        },
      ];
    case "ESFP":
      return [
        {
          name: "서피비치",
          description:
            "이번 여름에는 서핑에 도전해보는 거 어때요? 처음 보는 높은 파도에 겁 먹은 것도 한 순간, 바닷물 몇 번 먹고 나면 어느새 신나게 파도를 타고 있을거예요! 이국적으로 꾸며진 <b>#서피비치</b>는 서핑을 하지 않더라도 해변을 거닐기에 참 좋답니다.",
          insta: [
            {
              link: "https://www.instagram.com/p/CHaT9gpsElo/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFP_0_0.jpeg",
              authorName: "@for.comma",
            },
            {
              link: "https://www.instagram.com/p/CM9VE4ElF0h/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFP_0_1.jpeg",
              authorName: "@simnew_",
            },
            {
              link: "https://www.instagram.com/p/CMdme4JHeH9/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFP_0_2.jpeg",
              authorName: "@sweetylife_lim",
            },
            {
              link: "https://www.instagram.com/p/CNocGytF-zy/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFP_0_3.jpeg",
              authorName: "@its_rattan",
            },
            {
              link: "https://www.instagram.com/p/CNmmBUZMTHr/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFP_0_4.jpeg",
              authorName: "@dancer_eunjung",
            },
          ],
        },
        {
          name: "하조대",
          description:
            "기다란 수염을 늘어뜨린 신선이 있을 것만 같은 <b>하조대</b>. 바닷가 위에 세워진 기이한 모양의 절벽과 꼭대기에 뿌리내린 소나무가 아름다운 곳이에요. 전망대에 올라 일출을 감상하거나 하조대해변부터 기사문항까지 이어지는 길을 따라 드라이브를 즐기기에도 좋아요.",
          insta: [
            {
              link: "https://www.instagram.com/p/CEyeCDODVHh/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFP_1_0.jpeg",
              authorName: "@official_ytk",
            },
            {
              link: "https://www.instagram.com/p/CNlzYGzMPZm/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFP_1_1.jpeg",
              authorName: "@green_purple_jin",
            },
            {
              link: "https://www.instagram.com/p/CNkLUUZHCh6/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFP_1_2.jpeg",
              authorName: "@minjuita",
            },
            {
              link: "https://www.instagram.com/p/CNjLDJdFRXl/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFP_1_3.jpeg",
              authorName: "@yeonjoo722",
            },
            {
              link: "https://www.instagram.com/p/CNhc9Q4L9fK/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFP_1_4.jpeg",
              authorName: "@pink_panther_song",
            },
          ],
        },
        {
          name: "낙산사",
          description:
            "동해 바다를 품은 사찰, <b>#낙산사</b>는 관동8경 중 하나인 만큼 더할 나위 없는 훌륭한 경치를 자랑하는 곳이에요. 해안 절벽 끝에 있는 홍련암에서 소원을 빌면 이루어진다는 전설이 있어요. 이루고 싶은게 있다면 소원 쪽지를 남겨보세요!",
          insta: [
            {
              link: "https://www.instagram.com/p/CLYlP7Qs_LL/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFP_2_0.jpeg",
              authorName: "@seoulful_official_korea",
            },
            {
              link: "https://www.instagram.com/p/CNov1ETABFL/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFP_2_1.jpeg",
              authorName: "@sum_mer0814",
            },
            {
              link: "https://www.instagram.com/p/CNmeZQILW4k/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFP_2_2.jpeg",
              authorName: "@pic.viewk",
            },
            {
              link: "https://www.instagram.com/p/CNjdR_mlNks/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFP_2_3.jpeg",
              authorName: "@by_skshin",
            },
            {
              link: "https://www.instagram.com/p/CNkFal8F53s/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFP_2_4.jpeg",
              authorName: "@kimminsik2003",
            },
          ],
        },
        {
          name: "남대천",
          description:
            "맑은 물과 긴 길이를 자랑하는 <b>#남대천</b>은 가을마다 연어가 돌아오는 연어회귀하천이래요. 봄에 피는 벚꽃부터 노오란 가을의 억새까지 계절마다 다른 빛깔을 자랑하는 남대천에서 한가로운 산책을 즐겨보세요.",
          insta: [
            {
              link: "https://www.instagram.com/p/CGjWebEFRJm/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFP_3_0.jpeg",
              authorName: "@visit_gangwon",
            },
            {
              link: "https://www.instagram.com/p/CIFdE4EH103/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFP_3_1.jpeg",
              authorName: "@su.__.jin",
            },
            {
              link: "https://www.instagram.com/p/CC5uj0IhG_9/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFP_3_2.jpeg",
              authorName: "@jsspro7",
            },
            {
              link: "https://www.instagram.com/p/CExnKdKlmS2/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFP_3_3.jpeg",
              authorName: "@achimnara_ahn_youngkook",
            },
            {
              link: "https://www.instagram.com/p/CDm1m3-loxY/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFP_3_4.jpeg",
              authorName: "@achimnara_ahn_youngkook",
            },
          ],
        },
      ];
    case "ESFJ":
      return [
        {
          name: "쁘띠프랑스",
          description:
            "어릴 적, 동화 속의 주인공이 되기를 꿈꾼 적이 있나요? 그렇다면 <b>#쁘띠프랑스</b>에 방문해보시는 걸 추천드려요. 알록달록하고 아기자기한 마을을 거닐며 프랑스의 문화를 체험하다보면 어느새 다시 어린 시절로 돌아간 듯 동심을 되찾을 수 있을거예요.",
          insta: [
            {
              link: "https://www.instagram.com/p/CMl_wj1lm5w/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFJ_0_0.jpeg",
              authorName: "@nobl.travel",
            },
            {
              link: "https://www.instagram.com/p/CNnWCMvFc2u/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFJ_0_1.jpeg",
              authorName: "@_s_sunmi_",
            },
            {
              link: "https://www.instagram.com/p/CNmi2SYlZQF/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFJ_0_2.jpeg",
              authorName: "@_bysj1019",
            },
            {
              link: "https://www.instagram.com/p/CNhw0hEHRmc/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFJ_0_3.jpeg",
              authorName: "@jjangyu0320",
            },
            {
              link: "https://www.instagram.com/p/CNhMr_0pzhf/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFJ_0_4.jpeg",
              authorName: "@joyfulhwang",
            },
          ],
        },
        {
          name: "아침고요수목원",
          description:
            "<b>#아침고요수목원</b>은 사계절 각각 다른 꽃과 나무들로 다채로운 매력을 뽐내는 수목원이에요. 겨우내 밤이면 ‘오색별빛정원전'을 관람할 수 있는데, 숲 속을 찬란히 수놓은 빛이 아름다워 유명한 축제랍니다.",
          insta: [
            {
              link: "https://www.instagram.com/p/CMT2BEtpFBA/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFJ_1_0.jpeg",
              authorName: "@_gsooooo",
            },
            {
              link: "https://www.instagram.com/p/CKBlsDRJ7ql/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFJ_1_1.jpeg",
              authorName: "@jeongeun_jo",
            },
            {
              link: "https://www.instagram.com/p/CNoxn2CFMQh/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFJ_1_2.jpeg",
              authorName: "@jju._.hoa",
            },
            {
              link: "https://www.instagram.com/p/CNmc2nurXQ5/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFJ_1_3.jpeg",
              authorName: "@jinah0823",
            },
            {
              link: "https://www.instagram.com/p/CJIUYB2HmIU/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFJ_1_4.jpeg",
              authorName: "@amy_sunset96",
            },
          ],
        },
        {
          name: "자라섬캠핑장",
          description:
            "호텔도 좋지만, ‘낭만' 하면 역시 캠핑이죠! <b>#자라섬오토캠핑장</b>은 자연속에 어우러져 캠핑과 카라반캠핑을 즐길 수 있는 곳입니다. 오직 둘 만을 위한 공간에, 캠핑의 꽃 고기구이까지! 쏟아지는 별빛을 천장삼아 잠들어보세요.",
          insta: [
            {
              link: "https://www.instagram.com/p/B4jcW2hlGr6/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFJ_2_0.jpeg",
              authorName: "@crabbit486",
            },
            {
              link: "https://www.instagram.com/p/B17_gAwgNPc/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFJ_2_1.jpeg",
              authorName: "@floravin_",
            },
            {
              link: "https://www.instagram.com/p/CNRIIXYDIJi/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFJ_2_2.jpeg",
              authorName: "@starberry16",
            },
            {
              link: "https://www.instagram.com/p/CMyNCwSnSZj/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFJ_2_3.jpeg",
              authorName: "@yoo__happy",
            },
            {
              link: "https://www.instagram.com/p/CMoiLhehlE4/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFJ_2_4.jpeg",
              authorName: "@camping_mhyl",
            },
          ],
        },
        {
          name: "더스테이힐링파크",
          description:
            "만일 갑자기 비가 온다면 걱정하지 말고 <b>#더스테이힐링파크</b>에 방문해보세요. 더스테이힐링파크 내부에는 베이커리와 편집샵, 갤러리가 있는 복합문화공간 나인블럭이 자리하고 있어요. 넓은 공간에 눈과 입을 만족시키는 소품과 빵이 가득하답니다!",
          insta: [
            {
              link: "https://www.instagram.com/p/CG8jevHMma5/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFJ_3_0.jpeg",
              authorName: "@__sunnypic__",
            },
            {
              link: "https://www.instagram.com/p/CGjhcrmHMUw/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFJ_3_1.jpeg",
              authorName: "@ssu.ni2",
            },
            {
              link: "https://www.instagram.com/p/CDymRfNFHY7/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFJ_3_2.jpeg",
              authorName: "@jenna.yenna",
            },
            {
              link: "https://www.instagram.com/p/CNjq71jFMzv/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ESFJ_3_3.jpeg",
              authorName: "@seulaaaaaaaa_",
            },
          ],
        },
      ];
    case "INTJ":
      return [
        {
          name: "공산성",
          description:
            "<b>#공산성</b>은 유네스코 세계문화유산에 등재된 웅진의 성이에요. 가벼운 저녁 산책을 하기에도 좋고, 주말 11~5시에 방문한다면 매 시간 진행되는 수문병 근무교대식도 볼 수 있어요.",
          insta: [
            {
              link: "https://www.instagram.com/p/CGhawQUHb1e/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTJ_0_0.jpeg",
              authorName: "@luv__haze",
            },
            {
              link: "https://www.instagram.com/p/CFdqiDFn_Gv/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTJ_0_1.jpeg",
              authorName: "@ojnuii",
            },
            {
              link: "https://www.instagram.com/p/CMoVN2_scV9/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTJ_0_2.jpeg",
              authorName: "@wun.andonly",
            },
            {
              link: "https://www.instagram.com/p/CEXv_X5FtqP/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTJ_0_3.jpeg",
              authorName: "@geniefilm_",
            },
            {
              link: "https://www.instagram.com/p/CICbwAJH6O7/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTJ_0_4.jpeg",
              authorName: "@dada._.e_",
            },
          ],
        },
        {
          name: "부소산성",
          description:
            "<b>#부소산성</b>은 무르익은 가을에 방문하기에 가장 좋아요. 붉은 빛의 단풍들을 감상하고 바스락거리는 낙엽을 밟으며 걸을 수 있답니다. 길을 따라 가다보면 어렸을 적 배웠던 삼천궁녀의 전설이 깃든 낙화암이 있고 내려오는 길에는 백마강을 건너는 황포돛배를 탈 수도 있어요.",
          insta: [
            {
              link: "https://www.instagram.com/p/CEEub54sAC-/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTJ_1_0.jpeg",
              authorName: "@ingun.hong",
            },
            {
              link: "https://www.instagram.com/p/B_C3H1vAbCj/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTJ_1_1.jpeg",
              authorName: "@phonecammer",
            },
            {
              link: "https://www.instagram.com/p/CCHr5m0natI/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTJ_1_2.jpeg",
              authorName: "@angel_.k0412",
            },
            {
              link: "https://www.instagram.com/p/CNn28bUrok4/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTJ_1_3.jpeg",
              authorName: "@ohblue225",
            },
            {
              link: "https://www.instagram.com/p/CNnOX_UnMlX/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTJ_1_4.jpeg",
              authorName: "@zunebugy",
            },
          ],
        },
        {
          name: "궁남지",
          description:
            "서동과 선화공주의 사랑이야기, 혹시 알고 계신가요? <b>#궁남지</b>는 그 이야기의 배경이 되는 우리나라 최초의 인공 연못이에요. 한여름에는 수려한 연꽃을 볼 수 있고, 겨울에는 설경이 아름다워 촬영지로도 유명한 곳이랍니다.",
          insta: [
            {
              link: "https://www.instagram.com/p/CHjiihGgZVi/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTJ_2_0.jpeg",
              authorName: "@travel_behappyhere",
            },
            {
              link: "https://www.instagram.com/p/CEYOiqspxNk/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTJ_2_1.jpeg",
              authorName: "@ines_song",
            },
            {
              link: "https://www.instagram.com/p/CNnzjt0Asgz/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTJ_2_2.jpeg",
              authorName: "@kimsongho",
            },
            {
              link: "https://www.instagram.com/p/CNhbgGeHbSs/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTJ_2_3.jpeg",
              authorName: "@luv__haze",
            },
            {
              link: "https://www.instagram.com/p/CNkMpJbsLWe/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTJ_2_4.jpeg",
              authorName: "@gukbap_lover",
            },
          ],
        },
        {
          name: "마곡사",
          description:
            "마지막으로 소개할 <b>#마곡사</b>는 공주 시내에서도 20km 떨어진 곳에 자리잡고 있어 누구에게도 방해받지 않고 나만의 시간을 보낼 수 있는 스팟이에요. 타종 체험과 스님과의 차담 등 다양한 프로그램이 있어 템플스테이 장소로도 인기가 많아요.",
          insta: [
            {
              link: "https://www.instagram.com/p/CK_al52pepk/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTJ_3_0.jpeg",
              authorName: "@hanok357",
            },
            {
              link: "https://www.instagram.com/p/CGMeHemFR_o/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTJ_3_1.jpeg",
              authorName: "@lh.eee",
            },
            {
              link: "https://www.instagram.com/p/CHROnsTsJEw/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTJ_3_2.jpeg",
              authorName: "@_hyeunyoung",
            },
            {
              link: "https://www.instagram.com/p/CNmvd3-soD1/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTJ_3_3.jpeg",
              authorName: "@nail_drawing101",
            },
            {
              link: "https://www.instagram.com/p/CNj55Ztsb7p/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTJ_3_4.jpeg",
              authorName: "@minji__minji__",
            },
          ],
        },
      ];
    case "INTP":
      return [
        {
          name: "천리포수목원",
          description:
            "<b>#천리포수목원</b>은 잔잔한 바닷가 옆에 자리잡아 한적하게 즐기기에 좋은 곳이에요. 사계절 모두 다른 매력을 뽐내지만, 그 중에서도 다양한 수국이 수려한 모습으로 피어나는 여름에 방문하기 가장 좋습니다.",
          insta: [
            {
              link: "https://www.instagram.com/p/CBfQ-MjjhuA/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTP_0_0.jpeg",
              authorName: "@lo_como",
            },
            {
              link: "https://www.instagram.com/p/CCRKFY7FWN7/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTP_0_1.jpeg",
              authorName: "@coedam_hh",
            },
            {
              link: "https://www.instagram.com/p/CCGg46BF0Rh/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTP_0_2.jpeg",
              authorName: "@bona_jung",
            },
            {
              link: "https://www.instagram.com/p/CCNUbgxMnnk/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTP_0_3.jpeg",
              authorName: "@jinwook_86",
            },
          ],
        },
        {
          name: "꽃지해수욕장",
          description:
            "<b>#꽃지해수욕장</b>은 할미바위와 할아비바위 사이로 떨어지는 노을 풍경이 아름답기로 유명한 해변이에요. 평소에 해보지 못한 특별한 체험을 하고 싶다면 양동이와 호미를 챙겨 갯벌 체험을 해보는 것도 좋아요.",
          insta: [
            {
              link: "https://www.instagram.com/p/CHFQ-Hth8Og/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTP_1_0.jpeg",
              authorName: "@camper_tory",
            },
            {
              link: "https://www.instagram.com/p/CLwDZk-swHf/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTP_1_1.jpeg",
              authorName: "@dreamean._.j",
            },
            {
              link: "https://www.instagram.com/p/CLHHMlWMDuV/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTP_1_2.jpeg",
              authorName: "@kim_jaeduck",
            },
            {
              link: "https://www.instagram.com/p/CJcDqi1MJ2S/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTP_1_3.jpeg",
              authorName: "@sungpil.k",
            },
            {
              link: "https://www.instagram.com/p/CNljbfwJZo2/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTP_1_4.jpeg",
              authorName: "@unnn._.h",
            },
          ],
        },
        {
          name: "신두리해안사구",
          description:
            "우리나라에도 사막이 있다는 사실, 혹시 알고 계셨나요? <b>#신두리해안사구</b>에서는 바람이 도와 만들어낸 광활한 모래 언덕이 펼쳐져 있어 이국적인 사막의 정서를 느낄 수 있어요. 척척한 모래뿐인 사막들과는 달리, 모래 아래에 토양이 있어 다른 곳에서는 볼 수 없는 희귀한 식물들과 동물들도 만나볼 수 있어요.",
          insta: [
            {
              link: "https://www.instagram.com/p/CHKy9kzMzfN/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTP_2_0.jpeg",
              authorName: "@bgram_pic",
            },
            {
              link: "https://www.instagram.com/p/CNlVwWIs_wr/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTP_2_1.jpeg",
              authorName: "@travel._.oil",
            },
            {
              link: "https://www.instagram.com/p/CI3EiRUB9rw/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTP_2_2.jpeg",
              authorName: "@wangjeon_luv",
            },
            {
              link: "https://www.instagram.com/p/CNn_59hMljq/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTP_2_3.jpeg",
              authorName: "@_hyeunyoung",
            },
            {
              link: "https://www.instagram.com/p/CNhs2oZlJF8/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTP_2_4.jpeg",
              authorName: "@bin2ya_",
            },
          ],
        },
        {
          name: "간월도",
          description:
            "서산으로 넘어와 <b>#간월도</b>로 향하면, 작은 섬을 마주할 수 있어요. 섬 안에 자리잡고 있는 소박한 사찰과 바다가 만들어내는 풍경이 밤에 더욱 아름다운 곳이랍니다! 가기 전 물때 체크는 필수!랍니다",
          insta: [
            {
              link: "https://www.instagram.com/p/B-WOOxGDuOd/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTP_3_0.jpeg",
              authorName: "@eun_jin_story",
            },
            {
              link: "https://www.instagram.com/p/CMlPqnuMXSB/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTP_3_1.jpeg",
              authorName: "@lucete_hhk",
            },
            {
              link: "https://www.instagram.com/p/B_KZr1cjLcG/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTP_3_2.jpeg",
              authorName: "@seoyeon_skyblue",
            },
            {
              link: "https://www.instagram.com/p/B8uc-DzJjZf/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTP_3_3.jpeg",
              authorName: "@photorime",
            },
            {
              link: "https://www.instagram.com/p/CFUPgDrspnX/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INTP_3_4.jpeg",
              authorName: "@shepherds_boy",
            },
          ],
        },
      ];
    case "INFP":
      return [
        {
          name: "외도보타니아",
          description:
            "<b>#외도보타니아</b>는 거제도에서 유람선을 타고 들어갈 수 있어요. 섬 전체가 커다란 하나의 정원처럼 조성되어 있는 이 곳은 연인들의 여행지로 사랑받는 곳이랍니다.",
          insta: [
            {
              link: "https://www.instagram.com/p/CJHc17tMyZy/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFP_0_0.jpeg",
              authorName: "@kimhakjaelandscape",
            },
            {
              link: "https://www.instagram.com/p/CNhAKnKJjma/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFP_0_1.jpeg",
              authorName: "@fcmoinjh",
            },
            {
              link: "https://www.instagram.com/p/CMt4_jXh2eg/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFP_0_2.jpeg",
              authorName: "@girasol0812",
            },
            {
              link: "https://www.instagram.com/p/CIpiiffBceL/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFP_0_3.jpeg",
              authorName: "@oedo_pangpang",
            },
          ],
        },
        {
          name: "바람의언덕",
          description:
            "<b>#바람의언덕</b>은 맑은 거제의 바다를 가장 널리 조망할 수 있는 곳이에요. 언덕 위에 있는 커다란 풍차 앞에서 인증샷은 필수예요!",
          insta: [
            {
              link: "https://www.instagram.com/p/CLtbBwFsOg3/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFP_1_0.jpeg",
              authorName: "@ryufill_foto",
            },
            {
              link: "https://www.instagram.com/p/CL7t0yzhALe/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFP_1_1.jpeg",
              authorName: "@ssonjh_0321",
            },
            {
              link: "https://www.instagram.com/p/CMoaKtvhKwC/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFP_1_2.jpeg",
              authorName: "@travel_behappyhere",
            },
            {
              link: "https://www.instagram.com/p/CEJZVFUH6KH/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFP_1_3.jpeg",
              authorName: "@cho_yhhh",
            },
          ],
        },
        {
          name: "매미성",
          description:
            "<b>#매미성</b>은 그 의미를 알고 나면 더욱 뜻깊은 스팟이에요. 2003년 태풍 매미가 지나간 후, 백순삼씨가 자연재해로부터 작물을 지켜야겠다는 생각으로 홀로 쌓아올린 곳이거든요. 설계도 한 장 없이 지었다고는 믿을 수 없을만큼 아름답고 몽돌해변과 어우러지는 모습이 매력적인 성이에요.",
          insta: [
            {
              link: "https://www.instagram.com/p/CNintzhH2sn/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFP_2_0.jpeg",
              authorName: "@kh35000",
            },
            {
              link: "https://www.instagram.com/p/CNoYP8NLylH/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFP_2_1.jpeg",
              authorName: "@rebecca_sseul",
            },
            {
              link: "https://www.instagram.com/p/CNbag8wDi2T/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFP_2_2.jpeg",
              authorName: "@jeongvely_95",
            },
            {
              link: "https://www.instagram.com/p/CNoHfwdhEqf/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFP_2_3.jpeg",
              authorName: "@youngsun._",
            },
            {
              link: "https://www.instagram.com/p/CNmiK43HuMb/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFP_2_4.jpeg",
              authorName: "@__mirash",
            },
          ],
        },
        {
          name: "거제식물원",
          description:
            "아뿔싸, 여행 중 갑자기 비가 온다면 <b>#거제식물원</b>에 방문해보시는건 어떤가요? 유리온실 전부가 커다란 투명 돔 안에 들어있는 거제식물원은 귀여운 새둥지 포토존으로도 유명한 곳이에요.",
          insta: [
            {
              link: "https://www.instagram.com/p/CHkFvh4nWM3/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFP_3_0.jpeg",
              authorName: "@bee_1114_",
            },
            {
              link: "https://www.instagram.com/p/CBOXdvOj_56/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFP_3_1.jpeg",
              authorName: "@izylove79",
            },
            {
              link: "https://www.instagram.com/p/CGwJtBKDVR3/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFP_3_2.jpeg",
              authorName: "@thankyou_flower",
            },
            {
              link: "https://www.instagram.com/p/CM_FlMLscYn/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFP_3_3.jpeg",
              authorName: "@sooo_sta",
            },
            {
              link: "https://www.instagram.com/p/CHJk2bElty3/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFP_3_4.jpeg",
              authorName: "@onlydandelion",
            },
          ],
        },
      ];
    case "ISFJ":
      return [
        {
          name: "의림지",
          description:
            "삼한시대에 만들어진 <b>#의림지</b>는 가볍게 산책하기 좋은 여행지에요. 넓은 저수지를 둘러싼 다양한 나무들은 각기 다른 매력을 뽐내고, 길다란 용추폭포 위에는 유리 전망대가 있어 시원한 물줄기를 발 밑에 두고 감상할 수 있답니다!",
          insta: [
            {
              link: "https://www.instagram.com/p/CNoEPazMXi6/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFJ_0_0.jpeg",
              authorName: "@hong____pic",
            },
            {
              link: "https://www.instagram.com/p/CI3LUEIsC6Y/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFJ_0_1.jpeg",
              authorName: "@z1_yooon_film",
            },
            {
              link: "https://www.instagram.com/p/CHgmmIZHyx3/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFJ_0_2.jpeg",
              authorName: "@luminousyoon",
            },
            {
              link: "https://www.instagram.com/p/CJFlxIwMBmb/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFJ_0_3.jpeg",
              authorName: "@cheol_film",
            },
            {
              link: "https://www.instagram.com/p/CKQgSrAMH7G/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFJ_0_4.jpeg",
              authorName: "@cindy_cindy_1004",
            },
          ],
        },
        {
          name: "청풍문화재단지",
          description:
            "충주호가 내려다보이는 언덕 위 자리한 <b>#청풍문화재단지</b>는 다양한 유적지와 함께 계절마다 다른 풍경을 둘러보기 좋아요. 고즈넉한 아름다움이 있는 전통 마을을 한가로이 즐겨보세요",
          insta: [
            {
              link: "https://www.instagram.com/p/CHHyxaWsUXD/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFJ_1_0.jpeg",
              authorName: "@b_taeng",
            },
            {
              link: "https://www.instagram.com/p/CBhYk4rHnUD/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFJ_1_1.jpeg",
              authorName: "@korea.fly.landscape",
            },
            {
              link: "https://www.instagram.com/p/CHJ19pSMk6z/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFJ_1_2.jpeg",
              authorName: "@cholong_photo",
            },
            {
              link: "https://www.instagram.com/p/CEQne1gMtik/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFJ_1_3.jpeg",
              authorName: "@docu_lee",
            },
            {
              link: "https://www.instagram.com/p/CFv5HlvBwe1/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFJ_1_4.jpeg",
              authorName: "@yooneunhwa83",
            },
          ],
        },
        {
          name: "수안보온천",
          description:
            "일상의 무게에 쌓였던 피로를 <b>#수안보온천</b>에서 날려보세요! 아름다운 자연 속에 자리한 노천탕에 몸을 담그고 나면 그 동안의 피로가 온데간데 없이 사라져있을 거예요.",
          insta: [
            {
              link: "https://www.instagram.com/p/CD3YQLvH0-D/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFJ_2_0.jpeg",
              authorName: "@suanboparkhotel",
            },
            {
              link: "https://www.instagram.com/p/B_ZBlsTHS5n/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFJ_2_1.jpeg",
              authorName: "@suanboparkhotel",
            },
            {
              link: "https://www.instagram.com/p/B96bzeznglr/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFJ_2_2.jpeg",
              authorName: "@suanboparkhotel",
            },
            {
              link: "https://www.instagram.com/p/BuwDXgEDuZZ/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFJ_2_3.jpeg",
              authorName: "@suanboparkhotel",
            },
            {
              link: "https://www.instagram.com/p/CNm88ywl1NO/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFJ_2_4.jpeg",
              authorName: "@minji_so_happy",
            },
          ],
        },
        {
          name: "탄금대",
          description:
            "남한강의 절경을 즐길 수 있는 <b>#탄금대</b>에 방문해보시는 건 어떤가요? 서울로 올라오는 길목에 있어 돌아오는 길 여행의 아쉬움을 달래기에 좋아요. 근처에 왕갈비탕 맛집이 있으니 출출하다면 함께 들러보세요!",
          insta: [
            {
              link: "https://www.instagram.com/p/CJ970_wMomH/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFJ_3_0.jpeg",
              authorName: "@dan_am201609",
            },
            {
              link: "https://www.instagram.com/p/Bm14yw0l0Ud/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFJ_3_1.jpeg",
              authorName: "@kjk9316",
            },
            {
              link: "https://www.instagram.com/p/CNgVWMjFIUS/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFJ_3_2.jpeg",
              authorName: "@luvyoonn",
            },
            {
              link: "https://www.instagram.com/p/CNjLnqzlQRp/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFJ_3_3.jpeg",
              authorName: "@jimin_0204",
            },
            {
              link: "https://www.instagram.com/p/CGKfPNQD47q/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFJ_3_4.jpeg",
              authorName: "@sangtaghan",
            },
          ],
        },
      ];
    case "ISTJ":
      return [
        {
          name: "순천만국가정원",
          description:
            "우리나라 1호 국가정원인 <b>#순천만국가정원</b>에는 정원이라고 믿을 수 없는 커다란 넓이의 공간에 다양한 테마의 정원이 조성되어 있어요. 다 둘러보려면 세 시간은 필요하다고 하니 얼른얼른 출발하자구요!",
          insta: [
            {
              link: "https://www.instagram.com/p/CNoqkA9B15h/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTJ_0_0.jpeg",
              authorName: "@__flower_deer",
            },
            {
              link: "https://www.instagram.com/p/CNm1rJAHK5t/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTJ_0_1.jpeg",
              authorName: "@dawn_star72.00",
            },
            {
              link: "https://www.instagram.com/p/CNmm_75HtVQ/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTJ_0_2.jpeg",
              authorName: "@februar.crescent",
            },
            {
              link: "https://www.instagram.com/p/CNmei04ll79/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTJ_0_3.jpeg",
              authorName: "@leeyunheee",
            },
            {
              link: "https://www.instagram.com/p/CNkXUcvB648/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTJ_0_4.jpeg",
              authorName: "@urim__o",
            },
          ],
        },
        {
          name: "순천만습지",
          description:
            "교과서에서 한번쯤 보았을 <b>#순천만습지</b>. 실제로 만나보면 광활한 자연의 모습에 감탄을 감출 수 없을거예요. 자전거를 타고 둘러보아도 좋고, 걷다가 도착한 정자에 앉아 그저 지는 해를 바라보기만 해도 좋아요.",
          insta: [
            {
              link: "https://www.instagram.com/p/CMAMHGxMXDk/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTJ_1_0.jpeg",
              authorName: "@tae_yeo._n",
            },
            {
              link: "https://www.instagram.com/p/CJ_E7KYs3_b/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTJ_1_1.jpeg",
              authorName: "@kiwi_shot.1126",
            },
            {
              link: "https://www.instagram.com/p/CHpacYcs-vY/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTJ_1_2.jpeg",
              authorName: "@ph_hye_",
            },
            {
              link: "https://www.instagram.com/p/CNjVSANj9l4/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTJ_1_3.jpeg",
              authorName: "@baejurang",
            },
            {
              link: "https://www.instagram.com/p/CKAJcVksSWV/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTJ_1_4.jpeg",
              authorName: "@story._.foto",
            },
          ],
        },
        {
          name: "낙안읍성",
          description:
            "조선시대 삶의 모습이 그대로 녹아든 <b>#낙안읍성</b>은 단순히 전시하기 위한 민속촌이 아니라 실제 주민들이 살고 있어 과거와 현재가 공존하는 마을이에요. 마을 안에서는 다양한 남도음식을 맛볼 수 있고 초가집 민박체험도 가능하답니다",
          insta: [
            {
              link: "https://www.instagram.com/p/CFwh2avnh8f/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTJ_2_0.jpeg",
              authorName: "@ondo.pic",
            },
            {
              link: "https://www.instagram.com/p/B_pASBwHfkX/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTJ_2_1.jpeg",
              authorName: "@luv_dao__",
            },
            {
              link: "https://www.instagram.com/p/CGpF0eLs8LW/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTJ_2_2.jpeg",
              authorName: "@cherry144629",
            },
            {
              link: "https://www.instagram.com/p/CJupxvwMTWJ/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTJ_2_3.jpeg",
              authorName: "@cherry144629",
            },
            {
              link: "https://www.instagram.com/p/CFMephVJEPx/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTJ_2_4.jpeg",
              authorName: "@jeongeun_jo",
            },
          ],
        },
        {
          name: "대한다원",
          description:
            "끝없이 펼쳐진 초록 빛깔의 차밭 풍경, <b>#대한다원</b>에서 볼 수 있어요. 녹색 카페트를 깔아놓은 듯한 차밭을 둘러본 후에는 '한국의 가장 아름다운 길'로 꼽힌 삼나무 길을 따라서 내려와 대한다원의 명물 '녹차아이스크림'까지 꼭 먹고와야 해요!",
          insta: [
            {
              link: "https://www.instagram.com/p/B_3drovHUhL/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTJ_3_0.jpeg",
              authorName: "@b.bomii",
            },
            {
              link: "https://www.instagram.com/p/CBO1aQhDz9q/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTJ_3_1.jpeg",
              authorName: "@red._.nine",
            },
            {
              link: "https://www.instagram.com/p/B-f4qB1A12x/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTJ_3_2.jpeg",
              authorName: "@juwon_kim",
            },
            {
              link: "https://www.instagram.com/p/CCFlISdjq5K/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTJ_3_3.jpeg",
              authorName: "@seonsu_photo",
            },
            {
              link: "https://www.instagram.com/p/CB1elgYDhKb/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTJ_3_4.jpeg",
              authorName: "@y_.o_.n_.g",
            },
          ],
        },
      ];
    case "ISTP":
      return [
        {
          name: "제이드가든",
          description:
            "화창한 날 가볍게 거닐기 좋은 <b>#제이드가든</b>입니다. 이국적인 건물과 잘 조성되어 있는 정원이 아름다운 이곳은 사랑하는 사람과 방문하기에도 참 좋아요.",
          insta: [
            {
              link: "https://www.instagram.com/p/CEGtOMQMCfI/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTP_0_0.jpeg",
              authorName: "@floravin_",
            },
            {
              link: "https://www.instagram.com/p/CNogo3ClS1Q/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTP_0_1.jpeg",
              authorName: "@arang0804",
            },
            {
              link: "https://www.instagram.com/p/CNm1AxWpQ2P/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTP_0_2.jpeg",
              authorName: "@sehoon_gardener",
            },
            {
              link: "https://www.instagram.com/p/CNmy6bBp2tk/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTP_0_3.jpeg",
              authorName: "@sehoon_gardener",
            },
            {
              link: "https://www.instagram.com/p/CNkdzhYH5bO/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTP_0_4.jpeg",
              authorName: "@lovely._.jiyul",
            },
          ],
        },
        {
          name: "남이섬",
          description:
            "자연과 사람이 함께 만들어낸 <b>#남이섬</b>에 방문해보시는 건 어떤가요? 수려한 자연과 감각적인 조형물들이 어우러져 섬 전체가 하나의 예술작품 같은 곳이에요. 걸어서 둘러보아도 좋지만 자전거를 탄다면 더 특별한 추억을 만들 수 있을거예요!",
          insta: [
            {
              link: "https://www.instagram.com/p/CL1Rg_aBALF/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTP_1_0.jpeg",
              authorName: "@mmungsse.camper",
            },
            {
              link: "https://www.instagram.com/p/CNogttvHkQt/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTP_1_1.jpeg",
              authorName: "@good_luck0017",
            },
            {
              link: "https://www.instagram.com/p/CNnBVUnn9Fr/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTP_1_2.jpeg",
              authorName: "@ssu_and_latte",
            },
            {
              link: "https://www.instagram.com/p/CNl_21HnlS7/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTP_1_3.jpeg",
              authorName: "@hyuna_ayj",
            },
          ],
        },
        {
          name: "스퀴즈브루어리",
          description:
            "맥주, 좋아하세요? <b>#스퀴즈브루어리</b>에서는 맥주가 만들어지는 과정을 눈으로 직접 보고 체험하는 양조장 투어를 할 수 있어요! 투어 후에는 갓 뽑아낸 맥주와 함께 안주까지 즐길 수 있으니 맥주 덕후들에게는 이만한 천국이 없답니다-",
          insta: [
            {
              link: "https://www.instagram.com/p/CNXhoUgglfx/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTP_2_0.jpeg",
              authorName: "@jslee906",
            },
            {
              link: "https://www.instagram.com/p/CNCt3agMu-Y/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTP_2_1.jpeg",
              authorName: "@yzgoon",
            },
            {
              link: "https://www.instagram.com/p/CMKivoNg1Mo/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTP_2_2.jpeg",
              authorName: "@nastethb",
            },
            {
              link: "https://www.instagram.com/p/CL61_NUFDDH/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTP_2_3.jpeg",
              authorName: "@h.___.n9",
            },
            {
              link: "https://www.instagram.com/p/CH4PdajJZ5i/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTP_2_4.jpeg",
              authorName: "@_minjeong_ing",
            },
          ],
        },
        {
          name: "소양강 스카이워크",
          description:
            "집으로 돌아오기 전에 마지막으로 <b>소양강스카이워크</b>에 들러보세요! 강 바로 위에 설치된 투명유리 위를 걸으며 짜릿함을 경험하고, 시원한 하늘과 소양강을 바라보며 마시는 커피 한 잔으로 여행의 아쉬움을 조금 달랠 수 있을 거예요.",
          insta: [
            {
              link: "https://www.instagram.com/p/CAcM04Ip0fX/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTP_3_0.jpeg",
              authorName: "@chuncheon_go",
            },
            {
              link: "https://www.instagram.com/p/CNleO4wFIPB/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTP_3_1.jpeg",
              authorName: "@_bbbbbang",
            },
            {
              link: "https://www.instagram.com/p/CNe8djKLWxF/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTP_3_2.jpeg",
              authorName: "@choi_sung_hoon",
            },
            {
              link: "https://www.instagram.com/p/CNegl0wDs9W/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTP_3_3.jpeg",
              authorName: "@bongbong315",
            },
            {
              link: "https://www.instagram.com/p/CNekHqTl_13/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISTP_3_4.jpeg",
              authorName: "@h.___.mi723",
            },
          ],
        },
      ];
    case "ISFP":
      return [
        {
          name: "인제원대리자작나무숲",
          description:
            "인제에는 ‘속삭이는 자작나무숲'이라는 귀여운 별명을 가진 <b>#원대리자작나무숲</b>이 있어요. 70만여 그루의 자작나무가 모여 만들어내는 하얀 세상은 어디에서도 보지 못한 풍경을 선사해줄 거예요! 원대리자작나무숲은 매년 입산 기간과 통제 기간이 변동되니, 가기 전에 확인은 필수랍니다!",
          insta: [
            {
              link: "https://www.instagram.com/p/CJPYNCDspRE/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFP_0_0.jpeg",
              authorName: "@daily_kdi",
            },
            {
              link: "https://www.instagram.com/p/CG1rrbAsMI5/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFP_0_1.jpeg",
              authorName: "@floravin_",
            },
            {
              link: "https://www.instagram.com/p/CCIe0qIM0mS/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFP_0_2.jpeg",
              authorName: "@floravin_",
            },
            {
              link: "https://www.instagram.com/p/BXnYoRsj8ly/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFP_0_3.jpeg",
              authorName: "@nam.taehyeon_",
            },
            {
              link: "https://www.instagram.com/p/BuTMHAch_3u/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFP_0_4.jpeg",
              authorName: "@v.finder_aoki",
            },
          ],
        },
        {
          name: "내린천자유래프팅",
          description:
            "친구들과 추억 만드는데 물놀이만한게 없죠! <b>#내린천자유래프팅</b>은 래프팅 스팟 중에서도 빠른 급류로 유명한 곳이에요. 인제를 여름에 방문하신다면 짜릿한 래프팅을 즐겨보시는 걸 추천해요!",
          insta: [
            {
              link: "https://www.instagram.com/p/CDYDEgcF2om/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFP_1_0.jpeg",
              authorName: "@redplum02",
            },
            {
              link: "https://www.instagram.com/p/CDTkx54lQAX/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFP_1_1.jpeg",
              authorName: "@binisports1",
            },
            {
              link: "https://www.instagram.com/p/CIMoZ9JHmbB/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFP_1_2.jpeg",
              authorName: "@canonkorea",
            },
            {
              link: "https://www.instagram.com/p/CFZEfIgBols/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFP_1_3.jpeg",
              authorName: "@twin__ski",
            },
            {
              link: "https://www.instagram.com/p/CH2VmK4piqb/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFP_1_4.jpeg",
              authorName: "@steel_cheol",
            },
          ],
        },
        {
          name: "비밀의정원",
          description:
            "<b>#비밀의정원</b>에 대해 들어보신 적이 있으신가요? 이 곳은 단풍나무와 아침 물안개가 만들어내는 몽환적인 풍경으로 사진가들에게 알음알음 알려지며 일출 명소가 되었어요. 사람의 발길이 닿지 않아 더 아름다운 비밀의 정원으로 떠나보세요.",
          insta: [
            {
              link: "https://www.instagram.com/p/CGrJbTzsjmU/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFP_2_0.jpeg",
              authorName: "@another_shots",
            },
            {
              link: "https://www.instagram.com/p/CHEV79eMeS5/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFP_2_1.jpeg",
              authorName: "@hyeongjun9920",
            },
            {
              link: "https://www.instagram.com/p/CG8jGsRsZJK/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFP_2_2.jpeg",
              authorName: "@another_shots",
            },
            {
              link: "https://www.instagram.com/p/B4Fm5HoAc5u/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFP_2_3.jpeg",
              authorName: "@k_yumint",
            },
            {
              link: "https://www.instagram.com/p/CGcDBk6M3jW/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFP_2_4.jpeg",
              authorName: "@mongle_jyh",
            },
          ],
        },
        {
          name: "백담사",
          description:
            "내설악 깊은 곳에 위치한 <b>#백담사</b>는 한용운 시인의 '님의 침묵'이 탄생한 곳으로 유명한 사찰이에요. 백담계곡에 쌓여진 수많은 돌탑이 수많은 사람들의 염원을 보여주는 이 곳, 백담사에서 템플스테이를 경험해보는 건 어떤가요?",
          insta: [
            {
              link: "https://www.instagram.com/p/CL-RcZtsl7Q/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFP_3_0.jpeg",
              authorName: "@kimhakjaelandscape",
            },
            {
              link: "https://www.instagram.com/p/CCxwqA5nJXb/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFP_3_1.jpeg",
              authorName: "@mihyang_lim",
            },
            {
              link: "https://www.instagram.com/p/CGWcnvWMfDA/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFP_3_2.jpeg",
              authorName: "@kimhakjaelandscape",
            },
            {
              link: "https://www.instagram.com/p/CM9avSnsLQD/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFP_3_3.jpeg",
              authorName: "@winnyum",
            },
            {
              link: "https://www.instagram.com/p/CMj-srADrvb/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/ISFP_3_4.jpeg",
              authorName: "@________ddu",
            },
          ],
        },
      ];
    case "INFJ":
      return [
        {
          name: "보리암",
          description:
            "금산 봉우리에 위치한 <b>#보리암</b>은 마치 신선이 되어 세상을 내려다보는 듯한 일출과 일몰 풍경으로 소문난 사찰이에요. 정상에서 한 걸음 내려오면 ‘금산산장'을 만날 수 있는데, 이곳에서는 시원한 바다뷰를 바라보며 컵라면과 파전을 먹을 수 있답니다. 비몽사몽한 일어나 불평하던 친구들도 라면 한 입에 어느새 모두 신나있을 거예요!",
          insta: [
            {
              link: "https://www.instagram.com/p/CJCnF1clSRq/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFJ_0_0.jpeg",
              authorName: "@haechuri",
            },
            {
              link: "https://www.instagram.com/p/CKN6hv1FyZM/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFJ_0_1.jpeg",
              authorName: "@sonykorea",
            },
            {
              link: "https://www.instagram.com/p/CHVFAo0s4ab/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFJ_0_2.jpeg",
              authorName: "@nomoung7073",
            },
            {
              link: "https://www.instagram.com/p/CIpmr1GFT_M/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFJ_0_3.jpeg",
              authorName: "@yolo_inyoung",
            },
            {
              link: "https://www.instagram.com/p/CJ_B1KYplzj/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFJ_0_4.jpeg",
              authorName: "@geumsansanjang",
            },
          ],
        },
        {
          name: "다랭이마을",
          description:
            "<b>#다랭이마을</b>은 바다를 마주하고 있는 계단식 논과 그 주변을 둘러싼 경관이 빼어난 마을이에요. 그 중에서도 봄의 다랭이마을에는 유채꽃과 벚꽃이 만개해 마치 그림 같은 풍경을 자랑해요.",
          insta: [
            {
              link: "https://www.instagram.com/p/CLqPFhUM6Uk/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFJ_1_0.jpeg",
              authorName: "@monster_travel__",
            },
            {
              link: "https://www.instagram.com/p/CNKHNK1Foe2/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFJ_1_1.jpeg",
              authorName: "@1994.8p__",
            },
            {
              link: "https://www.instagram.com/p/CNRSklOMGxx/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFJ_1_2.jpeg",
              authorName: "@mongsil_moon",
            },
            {
              link: "https://www.instagram.com/p/CNLn0avMnF8/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFJ_1_3.jpeg",
              authorName: "@sirius.hong",
            },
            {
              link: "https://www.instagram.com/p/CKqs3B3FCpr/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFJ_1_4.jpeg",
              authorName: "@yolo_inyoung",
            },
          ],
        },
        {
          name: "섬이정원",
          description:
            "<b>#섬이정원</b> 또한 높낮이가 다른 다랑이 논의 특성을 이용해 꾸민 다양한 테마의 정원과, 바다를 향해 있는 사각 연못이 아름다워 인생샷 명소로 사랑받는 곳이랍니다.",
          insta: [
            {
              link: "https://www.instagram.com/p/CNhaN2cjS-s/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFJ_2_0.jpeg",
              authorName: "@villagecafe_coo",
            },
            {
              link: "https://www.instagram.com/p/CNgwd0Clcu3/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFJ_2_1.jpeg",
              authorName: "@h_.ran",
            },
            {
              link: "https://www.instagram.com/p/CNZ3fmOnN73/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFJ_2_2.jpeg",
              authorName: "@andmay_pictures",
            },
            {
              link: "https://www.instagram.com/p/CNm1t1Glr3a/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFJ_2_3.jpeg",
              authorName: "@b_yeon28",
            },
            {
              link: "https://www.instagram.com/p/CNoRcLll3ZZ/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFJ_2_4.jpeg",
              authorName: "@ssssuny0415",
            },
          ],
        },
        {
          name: "상상양떼목장",
          description:
            "양과 함께 뛰어놀 수 있는 <b>#상상양떼목장</b>은 어떤가요? 파아란 하늘이 펼쳐진 날, 동심으로 돌아간 기분을 한껏 느낄 수 있을거예요.",
          insta: [
            {
              link: "https://www.instagram.com/p/CBUXNZSHwpA/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFJ_3_0.jpeg",
              authorName: "@mixxo_u",
            },
            {
              link: "https://www.instagram.com/p/CEYxokFslWc/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFJ_3_1.jpeg",
              authorName: "@anne_shirley19",
            },
            {
              link: "https://www.instagram.com/p/CDi6Dhzh_Te/",
              thumbnailUrl:
                "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/INFJ_3_2.jpeg",
              authorName: "@b1122.kg",
            },
          ],
        },
      ];
  }
};
