import Axios from "axios";

const UseAxios = () => {
  Axios.interceptors.request.use(async (config) => {
    const token = localStorage.getItem("accessToken");

    if (token && token.length > 0) {
      config.headers.common["Authorization"] = `Bearer ${token}`;
    }
    return config;
  });
};

export default UseAxios;
