import {
  BlockType,
  IndexBlockType,
  IndexPOIType,
  SampleTripType,
} from '../Trip/types';
import { PoiIndicator, PoiMarker } from '../Trip/PoiMarker';
import { getColorByType, getSimpleDays } from '../../util';
import { getSampleTrip, getWeeklyTrip } from '../../api/Survey';
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl, { Marker } from '!mapbox-gl';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import AnimateHeight from 'react-animate-height';
import BriefBlock from '../Trip/BriefBlock';
import ClipboardJS from 'clipboard';
import DetailBlock from '../Trip/DetailBlock';
import { ReactComponent as DownIcon } from '../../assets/Trip/down.svg';
import Intro from '../Trip/Intro';
import { ReactComponent as IntroIcon } from '../../assets/Trip/intro.svg';
import { MainContext } from '../../contexts/MainContext';
import PoiPage from '../Trip/PoiPage';
import ReactDOMServer from 'react-dom/server';
import { ReactComponent as ShareIcon } from '../../assets/Trip/share.svg';
import { ReactComponent as ShareLinkIcon } from '../../assets/Trip/shareLink.svg';
import Survey from '../Trip/Survey';
import { ReactComponent as SurveyIcon } from '../../assets/Trip/survey.svg';
import { ReactComponent as UmbrellaIcon } from '../../assets/Trip/umbrella.svg';
import { ReactComponent as UpIcon } from '../../assets/Trip/up.svg';
import loading from '../../assets/loading.gif';
import useHideScrollTop from '../../hooks/useHideScrollTop';

const getDrawerMainHeight = (weekly?: boolean) => {
  const tripMain = document.getElementById('trip-main');
  const mapFloatingButtons = document.getElementById('map-floating-buttons');
  const drawerButton = document.getElementById('drawer-button');
  if (tripMain && (weekly || mapFloatingButtons !== null) && drawerButton) {
    if (weekly) {
      return tripMain.clientHeight - drawerButton?.clientHeight;
    }
    return (
      tripMain.clientHeight -
      mapFloatingButtons!.clientHeight -
      drawerButton?.clientHeight
    );
  }
  return 0;
};

const scrollToTop = () => {
  const elmnt = document.getElementById('block-list');
  elmnt?.scrollTo(0, 0);
};

const AppSample = ({ weekly }: { weekly?: boolean }) => {
  const [, dispatchMainContext] = useContext(MainContext);
  const { id } = useParams<{ id: string }>();
  const [poi, setPoi] = useState<undefined | IndexPOIType>();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [mainHeight, setMainHeight] = useState(window.innerHeight);
  const [height, setHeight] = useState(getDrawerMainHeight(weekly) / 2);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [rainSelected, setRainSelected] = useState(false);
  const [detailView, setDetailView] = useState(true);
  const [showAlternative, setShowAlternative] = useState(false);
  const [showAlternativeIds, setShowAlternativeIds] = useState<number[]>([]);
  const [overlays, setOverlays] = useState<
    {
      id: number;
      alternative: boolean;
      marker: mapboxgl.Marker;
    }[]
  >([]);
  const [map, setMap] = useState<mapboxgl.Map | undefined>();
  const [trip, setTrip] = useState<SampleTripType | undefined>();
  const [getTripDone, setGetTripDone] = useState(false);
  const [topIndex, setTopIndex] = useState(0);
  const history = useHistory();

  const setDefaultMap = () => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN!;
    const center: [number, number] = [128.070053, 36.679994];
    const zoom = 7;
    var _map = new mapboxgl.Map({
      container: 'map-sample',
      style: 'mapbox://styles/brian-storicity/cko3uhc6x04ff17ljpk12mkb5',
      center,
      zoom,
    });
    setMap(_map);
  };
  useEffect(() => {
    const callGetSampleTrip = async () => {
      try {
        if (weekly) {
          const { data: surveyTrip } = await getWeeklyTrip(id);
          setTrip(surveyTrip);
        } else {
          const { data: surveyTrip } = await getSampleTrip(id);
          setTrip(surveyTrip);
        }
      } catch (e) {
        alert('잘못된 샘플 주소입니다');
        history.push('/');
      } finally {
        setDefaultMap();
      }
    };
    callGetSampleTrip();
  }, [id]);
  useEffect(() => {
    const tripDone = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setGetTripDone(true);
    };
    if (map !== undefined) {
      tripDone();
    }
  }, [map]);
  useEffect(() => {
    if (trip && trip.meta.surveyDetail !== undefined) {
      showIntro();
    }
  }, [trip]);
  const getBlocks = useCallback(() => {
    let currentBlocks: BlockType[] = [];
    if (trip) {
      if (rainSelected) {
        currentBlocks = trip.rainBlocks;
      } else {
        currentBlocks = trip.blocks[selectedIndex];
      }
    }
    let blocks: IndexBlockType[] = [];
    currentBlocks.forEach((block, index) => {
      let altBlocks: IndexPOIType[] = [];
      const poiIndex = rainSelected
        ? (index + 10).toString(36).toUpperCase()
        : (index + 1).toString();
      block.alternatives.forEach((b, i) => {
        const altPoiIndex = rainSelected
          ? poiIndex + '-' + (i + 10).toString(36).toUpperCase()
          : `${index + 1}-${i + 1}`;
        altBlocks.push({ ...b, index: altPoiIndex });
      });
      blocks.push({
        main: { ...block.main, index: poiIndex },
        alternatives: altBlocks,
        transportation: block.transportation,
        traveltime: block.traveltime,
      });
    });
    return blocks;
  }, [rainSelected, trip, selectedIndex]);
  useEffect(() => {
    const blocks = getBlocks();
    let coordinates: [number, number][] = [];
    if (blocks.length > 0 && !rainSelected && map !== undefined) {
      coordinates = blocks.map((b) => [b.main.longitude, b.main.latitude]);
    }
    if (coordinates.length > 0) {
      const sourceData = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: coordinates,
        },
      };
      const layer = {
        id: 'route',
        type: 'line',
        source: 'route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#000',
          'line-width': 2,
          'line-dasharray': [1, 3],
        },
      };

      if (map.loaded() && map.isStyleLoaded()) {
        if (map.getSource('route')) {
          map.getSource('route').setData(sourceData);
        } else {
          map.addSource('route', {
            type: 'geojson',
            data: sourceData,
          });
        }
        if (!map.getLayer('route')) {
          map.addLayer(layer);
        }
      } else {
        map.on('load', () => {
          if (map.getSource('route')) {
            map.getSource('route').setData(sourceData);
          } else {
            map.addSource('route', {
              type: 'geojson',
              data: sourceData,
            });
          }
          if (!map.getLayer('route')) {
            map.addLayer(layer);
          }
        });
      }
    }
  }, [getBlocks, rainSelected, map]);
  useEffect(() => {
    if (poi && overlays.length > 0) {
      map.easeTo({ center: [poi.longitude, poi.latitude] });
      overlays.forEach((overlay) => {
        if (overlay.id === poi.id) {
          overlay.marker.getElement().style['zIndex'] = '100';
        } else if (overlay.alternative) {
          overlay.marker.getElement().style['zIndex'] = '1';
        }
      });
    }
  }, [poi, overlays, map]);
  useEffect(() => {
    let blocks: {
      id: number;
      poiType: string;
      index: string;
      lat: number;
      lng: number;
      alternative: boolean;
      onClick: () => void;
      zIndex: number;
    }[] = [];
    if (map !== undefined) {
      getBlocks().forEach((block, index) => {
        blocks.push({
          id: block.main.id,
          poiType: block.main.poiType,
          index: block.main.index,
          lat: block.main.latitude,
          lng: block.main.longitude,
          alternative: false,
          onClick: () => setPoi(block.main),
          zIndex: 99 - index,
        });
        block.alternatives.forEach((b, i) =>
          blocks.push({
            id: b.id,
            poiType: b.poiType,
            index: b.index,
            lat: b.latitude,
            lng: b.longitude,
            alternative: true,
            onClick: () => setPoi(b),
            zIndex: 1,
          })
        );
      });
      if (blocks.length > 0) {
        let north = blocks[0].lat;
        let south = blocks[0].lat;
        let east = blocks[0].lng;
        let west = blocks[0].lng;

        const _overlays = blocks.map((block) => {
          if (!block.alternative) {
            if (block.lat > north) {
              north = block.lat;
            }
            if (block.lat < south) {
              south = block.lat;
            }
            if (block.lng > east) {
              east = block.lng;
            }
            if (block.lng < west) {
              west = block.lng;
            }
          }

          const content = document.createElement('button');
          content.style['width'] = '4.5rem';
          content.innerHTML = `
            <div
              class="h-10 rounded-full flex items-center p-1 text-white font-medium text-sm bg-${
                block.alternative ? 'gray-400' : getColorByType(block.poiType)
              }"
            >
              <div class="bg-white rounded-full w-8 h-8 flex items-center justify-center">
                ${ReactDOMServer.renderToString(
                  <PoiMarker
                    type={block.poiType}
                    alternative={block.alternative}
                    className='h-4 w-4'
                  />
                )}
              </div>
              <div class="flex-grow text-center">${block.index}</div>
            </div>
            <div class="w-full">
              ${ReactDOMServer.renderToString(
                <PoiIndicator
                  type={block.poiType}
                  alternative={block.alternative}
                  className='h-2 w-2 mx-auto -mt-px'
                />
              )}
            </div>
          `;
          content.onclick = () => {
            setDrawerOpen(true);
            block.onClick();
          };
          return {
            id: block.id,
            alternative: block.alternative,
            marker: new Marker({ element: content }).setLngLat([
              block.lng,
              block.lat,
            ]),
          };
        });
        setOverlays(_overlays);
        map.fitBounds(
          [
            [west, south],
            [east, north],
          ],
          {
            padding: { left: 40, right: 40, top: 50, bottom: 80 },
          }
        );
      }
    }
  }, [map, getBlocks]);
  useEffect(() => {
    overlays.forEach((overlay) => {
      if (showAlternative || showAlternativeIds.includes(overlay.id)) {
        overlay.marker.addTo(map);
      } else {
        if (overlay.alternative) {
          overlay.marker.remove();
        } else {
          overlay.marker.addTo(map);
        }
      }
    });
    return () => overlays.forEach((overlay) => overlay.marker.remove());
  }, [overlays, showAlternative, showAlternativeIds, map]);
  useEffect(() => {
    if (showAlternativeIds.length > 0) {
      if (
        overlays.filter((ov) => ov.alternative === true).length ===
        showAlternativeIds.length
      ) {
        setShowAlternative(true);
      } else {
        setShowAlternative(false);
      }
    }
  }, [overlays, showAlternativeIds]);
  useEffect(() => {
    if (drawerOpen) {
      setHeight(getDrawerMainHeight(weekly));
    } else {
      setHeight(getDrawerMainHeight(weekly) / 2);
    }
  }, [drawerOpen, getTripDone, trip]);

  useEffect(() => {
    function updateSize() {
      setMainHeight(window.innerHeight);
      setDrawerOpen(false);
      setHeight(getDrawerMainHeight(weekly) / 2);
    }
    window.addEventListener('resize', updateSize, false);
    return () => window.removeEventListener('resize', updateSize, false);
  }, [drawerOpen]);
  useEffect(() => {
    if (mainHeight > 0) {
      setHeight(getDrawerMainHeight(weekly) / 2);
    }
  }, [mainHeight]);

  const onScrollBlockList = useCallback(() => {
    const blockList = document.getElementById('block-list');
    if (map !== undefined && blockList !== null && !drawerOpen) {
      let selectedBlocks = getBlocks();
      let _topIndex = 0;
      let accHeight = 0;

      selectedBlocks.forEach((block, index) => {
        const b = document.getElementById(
          detailView ? `detail-block-${index}` : `brief-block-${index}`
        );
        if (b) {
          accHeight += b.clientHeight;
          if (blockList.scrollTop > accHeight) {
            _topIndex = index + 1;
          }
        }
      });
      if (selectedBlocks[_topIndex] && _topIndex !== topIndex) {
        map.easeTo({
          center: [
            selectedBlocks[_topIndex].main.longitude,
            selectedBlocks[_topIndex].main.latitude,
          ],
        });
        overlays.forEach((overlay, index: number) => {
          overlay.marker.getElement().style['zIndex'] = `${99 - index}`;
        });
        const overlay = overlays.filter((overlay) => !overlay.alternative)[
          _topIndex
        ];
        if (overlay) {
          overlay.marker.getElement().style['zIndex'] = '100';
        }
        setTopIndex(_topIndex);
      }
    }
  }, [map, drawerOpen, getBlocks, detailView, topIndex, overlays]);

  useEffect(() => {
    const clipboard = new ClipboardJS('.btn');
    // @ts-ignore
    clipboard.on('success', function (e) {
      dispatchMainContext({
        type: 'UPDATE_DIALOG',
        value: {
          type: 'DialogConfirm',
          content: (
            <div className='flex flex-col items-center rounded-t-md px-8 pt-8 pb-10 bg-white'>
              <div className='w-full text-center font-bold'>
                샘플 일정표 링크가 복사되었습니다
              </div>
            </div>
          ),
        },
      });
      e.clearSelection();
    });
    return () => clipboard.destroy();
  }, []);
  const onClickKakaoShare = useCallback(() => {
    // @ts-ignore
    if (!Kakao.isInitialized()) {
      // @ts-ignore
      Kakao.init(process.env.REACT_APP_KAKAO_CLIENT_ID);
    }
    if (trip && trip.meta.highlights && trip.meta.highlights.length > 0) {
      // @ts-ignore
      Kakao.Link.sendDefault({
        objectType: 'feed',
        content: {
          title: `${trip.meta.username}님을 위한 ${getSimpleDays(
            trip.blocks.length
          )} ${trip.meta.location} 여행 디자인`,
          // @ts-ignore
          description: trip.meta.descriptions[0],
          imageUrl: trip.meta.highlights[0].mainImageUrl,
          link: {
            mobileWebUrl: `https://yodatrip.com/sample-trip/${id}`,
          },
        },
        buttonTitle: '여다에서 보기',
      });
    }
  }, [trip, id]);
  const showSurvey = useCallback(() => {
    if (trip !== undefined) {
      dispatchMainContext({
        type: 'UPDATE_DIALOG',
        value: {
          type: 'DialogConfirm',
          content: <Survey survey={trip.meta.surveyDetail} />,
        },
      });
    }
  }, [trip]);
  const showIntro = useCallback(() => {
    if (trip !== undefined && trip.meta.username !== undefined) {
      dispatchMainContext({
        type: 'UPDATE_DIALOG',
        value: {
          type: 'DialogConfirm',
          content: (
            <Intro
              username={trip.meta.username}
              covidFree={false}
              meta={trip.meta}
              days={getSimpleDays(trip.blocks.length)}
            />
          ),
          buttonText: '일정표 보기',
        },
      });
    }
  }, [trip]);
  const showShare = useCallback(() => {
    if (trip !== undefined) {
      dispatchMainContext({
        type: 'UPDATE_DIALOG',
        value: {
          type: 'DialogConfirm',
          content: (
            <div className='flex flex-col items-center rounded-t-md px-8 pt-8 pb-10 bg-white'>
              <div className='w-full text-center font-bold'>공유하기</div>
              <div className='mt-8 w-40 flex justify-center'>
                <button
                  className='btn'
                  data-clipboard-text={`https://yodareactnative.page.link/?link=${encodeURIComponent(
                    `https://yodatrip.com?sampleTripId=${id}`
                  )}&apn=com.yodareactnative&isi=1563847214&ibi=com.storicity.yodatrip&efr=1`}
                >
                  <ShareLinkIcon />
                  <div className='mt-3 text-sm'>링크 복사</div>
                </button>
              </div>
            </div>
          ),
        },
      });
    }
  }, [trip, id, onClickKakaoShare]);
  useEffect(() => {
    if (poi) {
      dispatchMainContext({
        type: 'UPDATE_DIALOG',
        value: {
          type: 'DialogConfirm',
          content: <PoiPage poi={poi} close={() => setPoi(undefined)} />,
          hideYes: true,
        },
      });
    } else {
      dispatchMainContext({
        type: 'UPDATE_DIALOG',
        value: { content: undefined },
      });
    }
  }, [poi]);
  const getPaddingBottom = useCallback(() => {
    if (drawerOpen) {
      return '0';
    } else {
      const blocks = getBlocks();
      if (
        !detailView &&
        blocks.length > 0 &&
        blocks[blocks.length - 1].alternatives.length > 0
      ) {
        return `calc(${height}px - 9rem)`;
      } else {
        return `calc(${height}px - 7rem)`;
      }
    }
  }, [getBlocks, drawerOpen, detailView, height]);
  useHideScrollTop();

  return (
    <div id='trip-main' className='relative w-full bg-white h-full'>
      {!getTripDone && (
        <div
          className={`top-0 left-0 bg-white w-full h-full fixed`}
          style={{ zIndex: 99999 }}
        >
          <div className='w-full h-full flex flex-col items-center justify-center mt-14'>
            <div className='w-12 sm:w-16'>
              <img className='w-full' src={loading} alt='loading' />
            </div>
            <div className='text-yoda-gray-5 body-medium-15 sm:text-2xl pt-4 sm:pt-6'>
              잠시만 기다려주세요
            </div>
          </div>
        </div>
      )}
      {trip?.meta.surveyDetail !== undefined && (
        <div
          id='map-floating-buttons'
          className='absolute left-0 pt-5 sm:pt-14'
          style={{ zIndex: 999 }}
        >
          <div className='flex text-xs sm:text-sm'>
            <button
              className='bg-white w-20 sm:w-24 h-8 sm:h-10 ml-5 shadow-lg rounded flex items-center justify-center'
              onClick={showSurvey}
            >
              <SurveyIcon className='h-3 sm:h-4 mr-1.5' />
              <div>내 설문</div>
            </button>
            <button
              className='bg-white w-20 sm:w-24 h-8 sm:h-10 ml-2.5 shadow-lg rounded flex items-center justify-center'
              onClick={showIntro}
            >
              <IntroIcon className='h-3 sm:h-4 mr-1.5' />
              <div>여행소개</div>
            </button>
          </div>
        </div>
      )}
      <div className='absolute right-0 pt-5 sm:pt-14' style={{ zIndex: 999 }}>
        <div className='mr-5'>
          <button onClick={() => setShowAlternative((prev) => !prev)}>
            <div
              className={`w-16 sm:w-20 h-8 sm:h-10 rounded-full ${
                showAlternative
                  ? 'bg-yoda-primary pr-2 pl-3'
                  : 'bg-gray-400 pr-3 pl-2'
              } flex items-center text-xs sm:text-sm justify-between text-white`}
            >
              {showAlternative && <div>후보</div>}
              <div className='w-4 h-4 sm:w-6 sm:h-6 rounded-full bg-white'></div>
              {!showAlternative && <div>후보</div>}
            </div>
          </button>
        </div>
      </div>
      <div id='map-sample' className='absolute h-3/5 w-full left-0 top-0'></div>
      <div className='absolute w-full left-0 bottom-0' style={{ zIndex: 999 }}>
        <div>
          <div id='drawer-button' className='pt-6'>
            <button
              className='py-2.5 w-full flex justify-center bg-white rounded-t-xl'
              onClick={() => setDrawerOpen((open) => !open)}
            >
              {drawerOpen ? (
                <DownIcon className='h-4' />
              ) : (
                <UpIcon className='h-4' />
              )}
            </button>
          </div>
          <AnimateHeight
            id='detailBlockPanel'
            duration={500}
            height={height}
            className='bg-white'
          >
            {trip !== undefined && (
              <div className='h-full w-full relative' style={{ height }}>
                <div className='px-4 absolute top-0 left-0 right-0 w-full z-20 bg-white'>
                  <div className='flex items-center border-b border-gray-200'>
                    {trip.blocks.map((_, index) => (
                      <button
                        key={index}
                        className={`w-14 h-12 ${
                          !rainSelected && selectedIndex === index
                            ? 'bg-yoda-primary'
                            : ''
                        }`}
                        onClick={() => {
                          setSelectedIndex(index);
                          setRainSelected(false);
                          scrollToTop();
                        }}
                      >
                        <p
                          className={`font-medium w-full text-center ${
                            !rainSelected && selectedIndex === index
                              ? 'text-white'
                              : 'text-gray-800'
                          }`}
                        >
                          {index + 1}
                        </p>
                        <p
                          className={`text-xs w-full text-center ${
                            !rainSelected && selectedIndex === index
                              ? 'text-white'
                              : 'text-gray-400'
                          }`}
                        >
                          일차
                        </p>
                      </button>
                    ))}
                    {trip.rainBlocks.length > 0 && (
                      <button
                        className={`w-14 h-12 ${
                          rainSelected ? 'bg-yoda-primary' : ''
                        }`}
                        onClick={() => setRainSelected(true)}
                      >
                        <UmbrellaIcon className='mx-auto' />
                      </button>
                    )}
                    <div className='flex-grow'></div>
                    <div className='px-2.5 bg-gray-100 h-9 flex items-center text-sm'>
                      <button
                        className='flex items-center'
                        onClick={() => setDetailView((detail) => !detail)}
                      >
                        <div>{detailView ? '한 눈에 보기' : '상세보기'}</div>
                        {detailView ? (
                          <UpIcon className='ml-1.5 h-1.5' />
                        ) : (
                          <DownIcon className='ml-1.5 h-1.5' />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  id='block-list'
                  className='w-full h-full pt-12 overflow-y-auto'
                  style={{ paddingBottom: getPaddingBottom() }}
                  onScroll={onScrollBlockList}
                >
                  {getBlocks().map((block, index) =>
                    detailView ? (
                      <DetailBlock
                        key={`${selectedIndex}_${index}_${block.main.id}`}
                        block={block}
                        setPoi={setPoi}
                        setShowAlternativeIds={setShowAlternativeIds}
                        index={index}
                        map={map}
                      />
                    ) : (
                      <BriefBlock
                        key={`${selectedIndex}_${index}_${block.main.id}`}
                        block={block}
                        setPoi={setPoi}
                        setShowAlternativeIds={setShowAlternativeIds}
                        index={index}
                      />
                    )
                  )}
                </div>
              </div>
            )}
          </AnimateHeight>
        </div>
      </div>
    </div>
  );
};

export default AppSample;
