import Axios from "axios";
import { UserType } from "../contexts/MainContext";

const getMe = async () => Axios.get<UserType>("v2/accounts/me");

const updateMe = async ({
  birthdate,
  gender,
  name,
  termAgreed,
}: {
  birthdate?: string;
  gender?: number;
  name?: string;
  termAgreed?: boolean;
}) =>
  Axios.put("v2/accounts/me", {
    birthdate,
    gender,
    name,
    termAgreed,
  });

const mobileVerify = async (mobile: string) =>
  Axios.post("v2/accounts/mobile/verify", { mobile });

const mobileConfirm = async (code: string) =>
  Axios.post("v2/accounts/mobile/confirm", { code });

const mobileSigninRequest = async (mobile: string) =>
  Axios.post<{ code: string }>("v2/accounts/mobile/signin/request", { mobile });

const mobileSigninConfirm = async (mobile: string, code: string) =>
  Axios.post<{
    status: string;
    accessToken: string;
    userInfo: {
      birthdate?: string;
      gender?: number;
      mobile?: string;
      name?: string;
      provider?: string;
      termAgreed?: boolean;
      userId?: string;
    } | null;
  }>("v2/accounts/mobile/signin/confirm", { mobile, code });

export {
  getMe,
  updateMe,
  mobileVerify,
  mobileConfirm,
  mobileSigninRequest,
  mobileSigninConfirm,
};
