import { IndexBlockType, IndexPOIType } from "./types";
import { getColorByType, getDiningText, getPoiType } from "../../util";
import { useEffect, useState } from "react";

import AnimateHeight from "react-animate-height";
import ClampLines from "react-clamp-lines";
import { ReactComponent as DownIcon } from "../../assets/Trip/down.svg";
import KakaoNaviIcon from "../../assets/Trip/kakaoNavi.png";
import { ReactComponent as Star } from "../../assets/Trip/star-green.svg";
import { ReactComponent as StarIcon } from "../../assets/Trip/star.svg";
import { ReactComponent as Switch } from "../../assets/Trip/switch.svg";
import TmapIcon from "../../assets/Trip/tmap.jpg";
import Transportation from "./Transportation";
import { ReactComponent as UpIcon } from "../../assets/Trip/up.svg";

const DetailBlock = ({
  block,
  setPoi,
  setShowAlternativeIds,
  index,
  map,
  onClickSwitch,
}: {
  block: IndexBlockType;
  setPoi: (detailBlock: IndexPOIType) => void;
  setShowAlternativeIds: (ids: (prevState: number[]) => number[]) => void;
  index: number;
  map: any;
  onClickSwitch?: (poiId: number) => void;
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const getSourceTag = (
    recommendationTags: {
      type: "party" | "interest" | "accomm" | "pref" | "covidFree" | "source";
      tag: string;
    }[]
  ) => {
    const source = recommendationTags.find((tag) => tag.type === "source");
    if (source) {
      return source.tag;
    }
    return "";
  };

  useEffect(() => {
    if (drawerOpen) {
      setShowAlternativeIds((ids) => {
        const newIds = [...ids];
        block.alternatives.forEach((b) => {
          newIds.push(b.id);
        });
        return newIds;
      });
    } else {
      setShowAlternativeIds((ids) => {
        const newIds = [...ids];
        block.alternatives.forEach((b) => {
          const index = newIds.findIndex((id) => id === b.id);
          newIds.splice(index, 1);
        });
        return newIds;
      });
    }
  }, [drawerOpen]);

  return (
    <div id={`detail-block-${index}`} className="border-b border-gray-200">
      <div className="ml-9 w-14 h-8">
        {index > 0 && (
          <div className="h-full border-r border-gray-400 w-px mx-auto"></div>
        )}
      </div>
      <div className="px-4">
        <div className="pl-5">
          <div className="flex items-start">
            <div className="self-stretch flex flex-col relative">
              <div className="w-14 h-14">
                <img
                  className="w-full h-full rounded-full object-cover"
                  src={block.main.detail.mainImageUrl}
                />
              </div>
              {block.traveltime !== null && (
                <div
                  className="flex-grow border-r border-gray-400 w-px mx-auto"
                  style={{ minHeight: "5rem" }}
                ></div>
              )}
              {block.traveltime !== null && block.transportation !== null && (
                <div className="absolute bottom-8 left-1 bg-white">
                  <Transportation type={block.transportation} />
                </div>
              )}
              {block.traveltime !== null && (
                <div className="absolute bottom-1 w-full flex justify-center">
                  <div className="px-1.5 py-1 rounded-full bg-gray-100 text-gray-900 text-xs font-medium">
                    {block.traveltime}분
                  </div>
                </div>
              )}
            </div>
            <div className="ml-2.5 flex-grow">
              <div className="flex items-start">
                <div
                  className={`h-8 w-8 rounded flex items-center justify-center border border-${getColorByType(
                    block.main.poiType
                  )}`}
                >
                  {block.main.index}
                </div>
                <div
                  className={`ml-1.5 h-8 px-2.5 rounded flex items-center bg-${getColorByType(
                    block.main.poiType
                  )} text-white`}
                >
                  {getDiningText(block.main.poiType, block.main.type)}
                </div>
                <div className="flex-1"></div>
                <a
                  className="sm:hidden"
                  target="_blank"
                  href={`https://apis.openapi.sk.com/tmap/app/routes?appKey=${process.env.REACT_APP_TMAP_APP_KEY}&name=${block.main.name}&lon=${block.main.longitude}&lat=${block.main.latitude}`}
                >
                  <img className="h-8 w-8 ml-2" src={TmapIcon} />
                </a>
                {navigator.userAgent !== "YodaReactNative" && (
                  <button
                    className="sm:hidden"
                    onClick={() => {
                      // @ts-ignore
                      if (!Kakao.isInitialized()) {
                        // @ts-ignore
                        Kakao.init(process.env.REACT_APP_KAKAO_CLIENT_ID);
                      }
                      // @ts-ignore
                      Kakao.Navi.start({
                        name: block.main.name,
                        x: block.main.longitude,
                        y: block.main.latitude,
                        coordType: "wgs84",
                      });
                    }}
                  >
                    <img className="h-8 w-8 ml-2" src={KakaoNaviIcon} />
                  </button>
                )}
              </div>
              <button
                className="text-left"
                onClick={() => {
                  setPoi(block.main);
                  map.easeTo({
                    center: [block.main.longitude, block.main.latitude],
                  });
                }}
              >
                <div className="mt-2.5 font-bold">
                  {block.main.name}
                  <span className="ml-1 text-xs text-gray-400 font-medium">
                    {getPoiType(block.main)}
                  </span>
                </div>
                {getSourceTag(block.main.detail.recommendationTags).length >
                  0 && (
                  <div className="flex items-center mt-1.5 sm:mt-2 mb-0.5 sm:mb-1">
                    <Star className="h-3.5 w-3.5 sm:h-5 sm:w-5" />
                    <div className="ml-1.5 sm:ml-2 text-yoda-green-dark text-sm leading-6 tracking-tight sm:text-base sm:leading-7">
                      {getSourceTag(block.main.detail.recommendationTags)}
                    </div>
                  </div>
                )}
                {block.main.detail.description && (
                  <ClampLines
                    id={block.main.id + "-" + index}
                    className="mt-1 text-sm text-gray-800"
                    text={block.main.detail.description}
                    lines={2}
                    ellipsis="..."
                    buttons={false}
                  />
                )}
                <div className="w-full flex items-start mt-2.5 pb-5">
                  {block.main.detail.naverRating !== null && (
                    <div className="flex-1">
                      <div className="text-xs text-gray-800 font-medium">
                        네이버
                      </div>
                      <div className="text-xs flex item-center mt-1">
                        <StarIcon className="h-4" />
                        <div className="ml-1 text-gray-900 font-bold">
                          {block.main.detail.naverRating}
                        </div>
                        <div className="text-gray-500 font-medium">/5</div>
                      </div>
                    </div>
                  )}
                  {block.main.detail.googleRating !== null && (
                    <div className="flex-1">
                      <div className="text-xs text-gray-800 font-medium">
                        구글
                      </div>
                      <div className="text-xs flex item-center mt-1">
                        <StarIcon className="h-4" />
                        <div className="ml-1 text-gray-900 font-bold">
                          {block.main.detail.googleRating}
                        </div>
                        <div className="text-gray-500 font-medium">/5</div>
                      </div>
                    </div>
                  )}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      {block.alternatives.length > 0 && (
        <button
          className="relative py-2.5 w-full items-center flex justify-center bg-gray-50 text-gray-400 rounded-t-xl"
          onClick={() => setDrawerOpen((open) => !open)}
        >
          {block.traveltime !== null && (
            <div className="absolute top-0 left-9 h-full w-14">
              <div className="h-full border-r border-gray-400 w-px mx-auto"></div>
            </div>
          )}
          <div className="text-xs">다른 후보 더보기</div>
          {drawerOpen ? (
            <UpIcon className="ml-2 h-3" />
          ) : (
            <DownIcon className="ml-2 h-3" />
          )}
        </button>
      )}

      <AnimateHeight height={drawerOpen ? "auto" : 0}>
        <div className="pl-14 pb-5 relative">
          {block.traveltime !== null && (
            <div className="absolute top-0 left-9 bottom-0 h-full w-14">
              <div className="h-full border-r border-gray-400 w-px mx-auto"></div>
            </div>
          )}
          {block.alternatives.map((alt, altIdx) => (
            <div key={alt.id} className="px-4 pt-5">
              <div className="pl-5">
                <div className="flex items-start">
                  <button
                    className="ml-2.5 text-left flex-1"
                    onClick={() => setPoi(alt)}
                  >
                    <div className="flex items-start">
                      <div
                        className={`h-8 px-2.5 rounded flex items-center justify-center border border-${getColorByType(
                          alt.poiType
                        )}`}
                      >
                        {alt.index}
                      </div>
                      <div
                        className={`ml-1.5 h-8 px-2.5 rounded flex items-center bg-${getColorByType(
                          alt.poiType
                        )} text-white`}
                      >
                        {getDiningText(alt.poiType, alt.type)}
                      </div>
                    </div>
                    <div className="mt-2.5 font-bold">
                      {alt.name}
                      <span className="ml-1 text-xs text-gray-400 font-medium">
                        {getPoiType(alt)}
                      </span>
                    </div>
                    {getSourceTag(alt.detail.recommendationTags).length > 0 && (
                      <div className="flex items-center mt-1.5 sm:mt-2 mb-0.5 sm:mb-1">
                        <Star className="h-3.5 w-3.5 sm:h-5 sm:w-5" />
                        <div className="ml-1.5 sm:ml-2 text-yoda-green-dark text-sm leading-6 tracking-tight sm:text-base sm:leading-7">
                          {getSourceTag(alt.detail.recommendationTags)}
                        </div>
                      </div>
                    )}
                    {alt.detail.description && (
                      <ClampLines
                        id={alt.id + "-" + altIdx}
                        className="mt-1 text-sm text-gray-800"
                        text={alt.detail.description}
                        lines={2}
                        ellipsis="..."
                        buttons={false}
                      />
                    )}
                  </button>
                  {onClickSwitch !== undefined && (
                    <button
                      className="flex-shrink-0"
                      onClick={() => onClickSwitch(alt.id)}
                    >
                      <Switch className="h-8 w-8" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </AnimateHeight>
    </div>
  );
};

export default DetailBlock;
