import Calendar from "../../assets/Main/retire/presentaion/calendar.svg";
import MainBlue1 from "../../assets/Main/retire/pins/main_blue_1.svg";
import MainBlue2 from "../../assets/Main/retire/pins/main_blue_2.svg";
import MainPink from "../../assets/Main/retire/pins/main_pink.svg";
import MainYellow from "../../assets/Main/retire/pins/main_yellow.svg";
import Pink from "../../assets/Main/retire/pins/alt_pink.svg";
import Purple1 from "../../assets/Main/retire/pins/alt_purple.svg";
import Purple2 from "../../assets/Main/retire/pins/alt_purple_2.svg";
import Quality from "../../assets/Main/retire/presentaion/quality.svg";
import Route from "../../assets/Main/retire/presentaion/route.svg";
import Yellow from "../../assets/Main/retire/pins/alt_yellow.svg";
import YodaPick1 from "../../assets/Main/retire/yodaPick_screenshot_1.png";
import YodaPick2 from "../../assets/Main/retire/yodaPick_screenshot_2.png";
import YodaPick3 from "../../assets/Main/retire/yodaPick_screenshot_3.png";
import blue from "../../assets/Main/retire/pins/alt_blue.svg";

export const altPins = [
  { svg: Yellow, className: "alt-yellow" },
  { svg: Purple1, className: "alt-purple-1" },
  { svg: blue, className: "alt-blue" },
  { svg: Pink, className: "alt-pink" },
  { svg: Purple2, className: "alt-purple-2" },
];

export const mainPins = [
  { svg: MainPink, id: "main-pink" },
  { svg: MainBlue1, id: "main-blue-1" },
  { svg: MainYellow, id: "main-yellow" },
  { svg: MainBlue2, id: "main-blue-2" },
];

export const mainDots = [
  "main-pink-dot",
  "main-blue-1-dot",
  "main-yellow-dot",
  "main-blue-2-dot",
];

export const presentations = [
  { svg: Quality, text: "장소의 퀄리티" },
  { svg: Calendar, text: "영업 시간" },
  { svg: Route, text: "최적의 동선" },
];

export const yodaPickScreenshots = [
  { img: YodaPick1 },
  { img: YodaPick2 },
  { img: YodaPick3 },
];
