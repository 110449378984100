import { useEffect } from "react";

const useHideScrollTop = () => {
  useEffect(() => {
    const scrollTop = document.getElementById("scroll-top");
    const scrollTopMobile = document.getElementById("scroll-top-mobile");
    if (scrollTop) {
      scrollTop.classList.add("hidden");
      scrollTop.classList.add("sm:hidden");
    }
    if (scrollTopMobile) {
      scrollTopMobile.classList.add("hidden");
      scrollTopMobile.classList.add("sm:hidden");
    }
    return () => {
      if (scrollTop) {
        scrollTop.classList.remove("sm:hidden");
      }
      if (scrollTopMobile) {
        scrollTopMobile.classList.remove("hidden");
      }
    };
  }, []);
};

export default useHideScrollTop;
