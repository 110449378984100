import { ReactElement } from "react";
import useOnclickOutside from "react-cool-onclickoutside";

const DialogConfirmBlack = ({
  content,
  closeHandler,
  onClickYes,
  onClickOuter,
  hideYes,
  buttonText,
}: {
  content: ReactElement;
  closeHandler: () => void;
  onClickYes?: () => void;
  onClickOuter?: () => void;
  hideYes?: boolean;
  buttonText?: string;
}) => {
  const ref = useOnclickOutside((e) => {
    if (e.type !== "mousedown") {
      return;
    }
    if (onClickOuter) {
      onClickOuter();
    }
    closeHandler();
  });

  return (
    <div
      ref={ref}
      className="z-50 max-w-full break-all bg-white px-5 sm:px-7 py-6 sm:py-9 rounded sm:rounded-md"
    >
      <div>
        {content}
        <div className={` ${hideYes === true ? `hidden` : ""}`}>
          <button
            className="w-full btn-bold-16 text-white bg-yoda-black-1 rounded sm:rounded-md h-12 sm:h-18"
            onClick={() => {
              if (onClickYes) {
                onClickYes();
              }
              closeHandler();
            }}
          >
            {buttonText || "확인"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DialogConfirmBlack;
