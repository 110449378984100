import {
  DestFoodActType,
  DestMapCityType,
  DestTextType,
  InterestType,
  ParentInterestType,
} from "../util/surveyOptions";
import { SampleTripType, TripType } from "../components/Trip/types";

import Axios from "axios";
import { SurveyState } from "../contexts/SurveyContext";

export type SurveysType = {
  id: number;
  from: string | null;
  to: string | null;
  people: number | null;
  status: number;
  location: string | null;
  mainImageUrl: string | null;
  fromMonth: number | null;
  length: number | null;
  updatedAt: string;
};

export type CandidateType = {
  id: string;
  content: string;
};

export type HateType = {
  category: "meat" | "seafood" | "taste";
  imageUrlHigh: string;
  imageUrlLow: string;
  imageUrlMedium: string;
  name: string;
  value: string;
};

export type FavoriteType = {
  imageUrlHigh: string;
  imageUrlLow: string;
  imageUrlMedium: string;
  name: string;
  value: string;
};

export type HateCategoryType = {
  name: "meat" | "seafood" | "taste";
  children: HateType[];
};

export type FavoriteQuestionType = {
  question: number;
  content: FavoriteType[];
};

export type HateQuestionType = {
  question: number;
  content: HateCategoryType[];
};

const getSurveys = () => Axios.get<SurveysType[]>("v2/surveys");

const getSurvey = (id: number) => Axios.get<SurveyState>(`v2/surveys/${id}`);

const getSurveyTrip = (id: string) => Axios.get<TripType>(`v2/trip/${id}`);

const getSampleTrip = (id: string) =>
  Axios.get<SampleTripType>(`v2/trip/sample/${id}`);

const getWeeklyTrip = (id: string) =>
  Axios.get<SampleTripType>(`v2/trip/weekly/${id}`);

const getDestCandidates = (params: string) =>
  Axios.get<{
    candidates: {
      driveDistLimit: string;
      transitDistLimit: string;
      keywords: string;
      interestMatches: string[];
    }[];
  }>(`v2/dest_options/candidates?${params}`);

const getDepartLocations = () =>
  Axios.get<CandidateType[]>("/v2/searches/depart_location");

const getFoodTypes = () =>
  Axios.get<[FavoriteQuestionType, FavoriteQuestionType, HateQuestionType]>(
    "v2/food_types"
  );

const createDepartLocations = (content: string) =>
  Axios.post(`/v2/searches/depart_location?content=${content}`);

const deleteDepartLocations = (id: string) =>
  Axios.delete(`/v2/searches/depart_location?id=${id}`);

const createSurvey = () => Axios.post<{ id: number }>("v2/surveys");

const updateSurvey = (id: string, body: SurveyState) =>
  Axios.put(`v2/surveys/${id}`, body);

const deleteSurvey = (id: number) => Axios.delete(`v2/surveys/${id}`);

const getTripShare = (id: string, mobile: string) =>
  Axios.get<TripType>(`/v2/trip/share/${id}?mobile=${mobile}`);

const createPurchaseConfirm = (
  surveyId: string,
  couponId: string | null,
  amount: number,
  receipt: any
) =>
  Axios.post(`v2/surveys/${surveyId}/purchase/confirm`, {
    couponId,
    amount,
    receipt,
  });

const getInterestOptions = (params: string) =>
  Axios.get<(InterestType | ParentInterestType)[]>(
    `v2/interest_types?${params}`
  );

const getDestOptions = (param: string) =>
  Axios.get<{
    cities: DestMapCityType[];
    foods: DestFoodActType[];
    acts: DestFoodActType[];
    texts: DestTextType[];
  }>(`v2/dest_options?${param}`);

const updateMainSelect = (surveyId: string, poiId: number) =>
  Axios.put(`v2/surveys/${surveyId}/pois/${poiId}/select`);

export {
  getSurveys,
  getSurvey,
  getSurveyTrip,
  getSampleTrip,
  getWeeklyTrip,
  getDestCandidates,
  getDepartLocations,
  createDepartLocations,
  deleteDepartLocations,
  createSurvey,
  updateSurvey,
  deleteSurvey,
  getTripShare,
  createPurchaseConfirm,
  getInterestOptions,
  getDestOptions,
  updateMainSelect,
  getFoodTypes,
};
