import "./mbti.css";

import {
  MBTIType,
  _getBackgroundColor,
  _getBackgroundTop,
  _getCityDesc,
  _getCityDescription,
  _getCityImg,
  _getCityName,
  _getHighlights,
  _getPersonalCharacter,
  _getPersonalCharacterImg,
  _getPersonalCharacterTitle,
  _getPersonalDescription,
  _getResultImg,
} from "./Descriptions";
import { useCallback, useContext, useEffect, useRef, useState } from "react";

import Blinking_Bg from "../../assets/MBTI/blinking_bg.png";
import Blinking_Bg_Green from "../../assets/MBTI/blinking_bg_green.png";
import Capture from "../../assets/MBTI/capture.png";
import CaptureGuide from "../../assets/MBTI/capture_guide.png";
import Carrier from "../../assets/MBTI/carrier.png";
import ClipboardJS from "clipboard";
import Gradient from "../../assets/MBTI/gradient.png";
import { Howl } from "howler";
import KakaoIcon from "../../assets/MBTI/kakao.png";
import Link from "../../assets/MBTI/link.png";
import Logo from "../../assets/MBTI/logo.png";
import LogoTitle from "../../assets/MBTI/logo_title.png";
import Movie_1 from "../../assets/MBTI/movie_1.png";
import Movie_2 from "../../assets/MBTI/movie_2.png";
import Movie_3 from "../../assets/MBTI/movie_3.png";
import Movie_4 from "../../assets/MBTI/movie_4.png";
import Movie_Background from "../../assets/MBTI/movie_background.png";
import ReactGA from "react-ga";
import Sound from "../../assets/MBTI/sound.png";
import Step1 from "../../assets/MBTI/step_1.gif";
import Step1_1 from "../../assets/MBTI/step_1_1.jpg";
import Step2 from "../../assets/MBTI/step_2.jpg";
import Step3 from "../../assets/MBTI/step_3.gif";
import Step3_BG from "../../assets/MBTI/step_3_bg.png";
import Step3_Intro from "../../assets/MBTI/step_3_intro.png";
import Step3_Monitor from "../../assets/MBTI/step_3_monitor.jpg";
import Step4 from "../../assets/MBTI/step_4.gif";
import Step5 from "../../assets/MBTI/step_5.png";
import Step6 from "../../assets/MBTI/step_6.jpg";
import Step6_Mobile_Bottom from "../../assets/MBTI/step_6_mobile_bottom.png";
import Step6_Mobile_Top from "../../assets/MBTI/step_6_mobile_top.png";
import Step7 from "../../assets/MBTI/step_7.png";
import Step7_Mobile from "../../assets/MBTI/step_7_mobile.gif";
import Step8 from "../../assets/MBTI/step_8.png";
import Step8_Bubble_Bottom from "../../assets/MBTI/step_8_bubble_bottom.png";
import Step8_Bubble_Top from "../../assets/MBTI/step_8_bubble_top.png";
import Step9Bubble from "../../assets/MBTI/step_9_bubble.png";
import { SurveyContext } from "../../contexts/SurveyContext";
import TitleImage from "../../assets/MBTI/title.png";
import Twitter from "../../assets/MBTI/twitter.png";

const background1 = new Howl({
  src:
    "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/background1.mp3",
  loop: true,
});
const background2 = new Howl({
  src:
    "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/background2.mp3",
  loop: true,
});
const door_chime = new Howl({
  src:
    "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/door_chime.mp3",
});
const step_1_alarm = new Howl({
  src:
    "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/step_1_alarm.mp3",
});
const step_1_bird = new Howl({
  src:
    "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/step_1_bird.mp3",
});
const step_2_bus = new Howl({
  src:
    "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/step_2_bus.mp3",
});
const step_3_laptop = new Howl({
  src:
    "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/step_3_laptop.mp3",
});
const step_3_office = new Howl({
  src:
    "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/step_3_office.mp3",
});
const step_4_keyboard = new Howl({
  src:
    "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/step_4_keyboard.mp3",
});
const step_6_1_street = new Howl({
  src:
    "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/step_6_1_street.mp3",
});
const step_8_mobile = new Howl({
  src:
    "https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/step_8_mobile.mp3",
});

const Questions = () => {
  const [, dispatch] = useContext(SurveyContext);
  const [step, setStep] = useState(-1);
  const [E, setE] = useState(0);
  const [I, setI] = useState(0);
  const [B, setB] = useState<"S" | "N" | undefined>();
  const [T, setT] = useState<"T" | "F" | undefined>();
  const [P, setP] = useState(0);
  const [J, setJ] = useState(0);
  const [MBTI, setMBTI] = useState<MBTIType | undefined>();
  const [endingPlay, setEndingPlay] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [highlights, setHighlights] = useState<
    {
      name: string;
      description: string;
      insta: { link: string; authorName: string; thumbnailUrl: string }[];
    }[]
  >([]);

  useEffect(() => {
    if (E + I === 3 && B && T && P + J === 3) {
      let M: "E" | "I" = "E";
      if (I > 1) {
        M = "I";
      }
      let _I: "P" | "J" = "P";
      if (J > 1) {
        _I = "J";
      }
      const _MBTI = `${M}${B}${T}${_I}` as const;
      setMBTI(_MBTI);
    } else {
      setMBTI("ISTJ");
    }
  }, [E, I, B, T, P, J]);

  const getPersonalCharacterImg = useCallback(() => {
    if (MBTI === undefined) {
      return "";
    }

    return _getPersonalCharacterImg(MBTI);
  }, [MBTI]);

  const getPersonalDescription = useCallback(() => {
    if (MBTI === undefined) {
      return "";
    }

    return _getPersonalDescription(MBTI);
  }, [MBTI]);

  const getBackgroundColor = useCallback(() => {
    if (MBTI === undefined) {
      return "";
    }

    return _getBackgroundColor(MBTI);
  }, [MBTI]);

  const getTextColor = useCallback(() => {
    if (MBTI === "INFP") return "#212121";
    return "#FFFFFF";
  }, [MBTI]);

  const getCityImg = useCallback(() => {
    if (MBTI === undefined) {
      return "";
    }

    return _getCityImg(MBTI);
  }, [MBTI]);

  const getCityDescription = useCallback(() => {
    if (MBTI === undefined) {
      return "";
    }

    return _getCityDescription(MBTI);
  }, [MBTI]);

  const getResultImg = useCallback(() => {
    if (MBTI === undefined) {
      return "";
    }

    return _getResultImg(MBTI);
  }, [MBTI]);

  useEffect(() => {
    const fetchHighlights = async () => {
      if (MBTI === undefined) {
        setHighlights([]);
      } else {
        const hls = _getHighlights(MBTI);
        const _highlights: {
          name: string;
          description: string;
          insta: { link: string; authorName: string; thumbnailUrl: string }[];
        }[] = [];
        for (const h of hls) {
          const insta: {
            link: string;
            thumbnailUrl: string;
            authorName: string;
          }[] = [];
          for (const i of h.insta) {
            insta.push({
              link: i.link,
              thumbnailUrl: i.thumbnailUrl,
              authorName: i.authorName,
            });
          }
          _highlights.push({
            name: h.name,
            description: h.description,
            insta,
          });
        }
        setHighlights(_highlights);
      }
    };
    fetchHighlights();
  }, [MBTI]);

  useEffect(() => {
    if (step === -1) {
      setTimeout(() => {
        setMBTI(undefined);
        setE(0);
        setI(0);
        setB(undefined);
        setT(undefined);
        setP(0);
        setJ(0);
      }, 1000);
    } else if (step === 1) {
      step_1_alarm.play();
    } else if (step === 1.1) {
      step_1_alarm.pause();
      step_1_bird.play();
      background1.play();
    } else if (step === 2) {
      step_1_bird.pause();
      setTimeout(() => {
        step_2_bus.play();
      }, 1000);
    } else if (step === 3) {
      step_2_bus.pause();
      setTimeout(() => {
        step_3_office.play();
      }, 1000);
      setTimeout(() => {
        setStep(3.1);
      }, 4000);
    } else if (step === 3.1) {
      setTimeout(() => {
        step_3_laptop.play();
      }, 1000);
      step_3_office.pause();
    } else if (step === 4) {
      setTimeout(() => {
        step_4_keyboard.play();
      }, 1000);
      step_3_laptop.pause();
    } else if (step === 5) {
      step_4_keyboard.pause();
    } else if (step === 6 || step === 6.5) {
      if (step === 6.5) {
        door_chime.play();
      }
      const background1Fadeout = setInterval(() => {
        if (background1.volume() > 0.2) {
          background1.volume(background1.volume() - 0.2);
        } else {
          background2.play();
          background1.pause();
          clearInterval(background1Fadeout);
        }
      }, 200);
      setTimeout(() => {
        step_6_1_street.play();
      }, 1000);
    } else if (step === 7) {
      step_6_1_street.pause();
    } else if (step === 7.2) {
      setTimeout(() => {
        step_8_mobile.play();
      }, 1000);
    } else if (step === 8) {
      step_8_mobile.pause();
    } else if (step === 9.2) {
      setEndingPlay(true);
      setTimeout(() => {
        setStep(9.3);
      }, 3000);
    } else if (step === 9.3) {
      setTimeout(() => {
        setStep(10);
      }, 5000);
    } else if (step === 10) {
      const background2Fadeout = setInterval(() => {
        if (background2.volume() > 0.2) {
          background2.volume(background2.volume() - 0.2);
        } else {
          background2.pause();
          clearInterval(background2Fadeout);
        }
      }, 200);
    }
  }, [step]);
  useEffect(() => {
    if (endingPlay) {
      videoRef.current?.play();
    }
  }, [endingPlay]);
  useEffect(() => {
    const clipboard = new ClipboardJS(".btn");
    // @ts-ignore
    clipboard.on("success", function (e) {
      alert("테스트 주소가 복사되었습니다!");
      e.clearSelection();
    });
    return () => clipboard.destroy();
  }, []);

  return (
    <div className="relative fixed top-0 sm:top-1/2 left-0 sm:left-2/3 h-full sm:max-h-full sm:h-208 w-full sm:w-96 sm:transform sm:-translate-y-1/2 sm:-translate-x-1/3 sm:rounded-3xl">
      {(step === -1 || step === 0) && (
        <video
          className="absolute w-full h-full top-0 left-0 object-cover sm:rounded-3xl"
          preload="auto"
          playsInline={true}
          autoPlay={true}
          muted={true}
          loop={true}
          style={{ zIndex: -1 }}
          onLoadedData={() => setStep(0)}
        >
          <source
            src="https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/main_background.mp4"
            type="video/mp4"
          ></source>
        </video>
      )}
      <img
        className={`absolute w-2/5 top-4 right-4 ${
          step === 0 ? "fade-in" : "fade-out"
        }`}
        src={LogoTitle}
      />
      <div
        className={`invisible w-full h-full flex flex-col items-center ${
          step === 0 ? "fade-in" : "fade-out"
        } absolute top-0 left-0 sm:rounded-3xl overflow-hidden z-10`}
      >
        <div className="w-full px-5">
          <img src={TitleImage} className="w-full h-auto mt-14 sm:mt-28" />
        </div>
        <div className="flex-1"></div>
        <button
          className="mb-28 w-72 h-12 text-2xl font-bold bg-white text-yoda-primary rounded-full"
          style={{ boxShadow: "0px 6px 10px rgba(101, 67, 33, 0.5)" }}
          onClick={() => setStep(1)}
        >
          시작하기
        </button>
      </div>
      <div
        className={`invisible w-full h-full ${
          step === 1 || step === 1.1 ? "fade-in" : "fade-out"
        } absolute top-0 left-0 sm:rounded-3xl overflow-hidden`}
      >
        {step === 1 && (
          <button
            className={`w-full h-full flex flex-col items-center justify-end absolute top-0 left-0 bg-contain bg-no-repeat sm:rounded-3xl bg-center text-lg font-medium text-white`}
            style={{ zIndex: 99, backgroundImage: `url(${Step1})` }}
            onClick={() => setStep(1.1)}
          >
            <div
              className="animate-flicker bg-center h-20 bg-contain bg-no-repeat flex items-center mb-40"
              style={{ backgroundImage: `url(${Blinking_Bg})` }}
            >
              탭해서 알람 끄기
            </div>
            <img className="mx-auto w-7 h-auto" src={Sound} />
            <div className="w-full text-sm text-center text-white my-1">
              소리를 켜면 더욱 몰입감 있게 즐길 수 있어요
            </div>
          </button>
        )}
        <div
          className={`bg-black h-1/2 w-full sm:rounded-t-3xl absolute top-0 left-0 ${
            step === 1.1 ? "animate-top-blinking" : ""
          } large-shadow`}
          style={{
            zIndex: 9,
            transform: step === 2 ? "translateY(calc(-100% - 60px)" : "",
          }}
        ></div>
        <div
          className={`bg-black h-1/2 w-full sm:rounded-b-3xl absolute top-1/2 left-0 ${
            step === 1.1 ? "animate-bottom-blinking" : ""
          } large-shadow`}
          style={{
            zIndex: 9,
            transform: step === 2 ? "translateY(calc(100% + 60px)" : "",
          }}
        ></div>
        <div
          className={`w-full h-full flex flex-col items-center ${
            step === 1.1 ? "" : "fade-out"
          } bg-cover bg-no-repeat sm:rounded-3xl bg-bottom relative`}
          style={{ backgroundImage: `url(${Step1_1})` }}
        >
          <div className="absolute top-3 left-3 rounded-full w-16 py-1 bg-yoda-primary text-white text-center">
            1/9
          </div>
          <div className="overflow-hidden mt-12 sm:mt-20">
            <div
              className={`text-xl sm:text-lg leading-7 opacity-0 button-default text-center font-bold ${
                step === 1.1 ? "text-fade-in" : ""
              }`}
              style={{ color: "#444444" }}
            >
              좋은 아침이에요!
              <br />
              오늘도 평소와 같이
              <br />
              침대에서 일어난 당신은
            </div>
          </div>
          <div className="mt-8 overflow-hidden w-88 max-w-full pt-1 pb-4 px-4">
            <button
              className={`h-16 w-full opacity-0 text-yoda-primary font-bold bg-white rounded-full button-default ${
                step === 1.1 ? "button-fade-in" : ""
              }`}
              style={{ boxShadow: "0px 6px 10px rgba(101, 67, 33, 0.5)" }}
              onClick={() => {
                setStep(2);
                setJ((prevJ) => prevJ + 1);
              }}
            >
              이부자리를 정돈하며
              <br />
              오늘 해야 할 일들을 머릿속으로 떠올린다.
            </button>
          </div>
          <div className="overflow-hidden w-88 max-w-full pt-1 pb-4 px-4">
            <button
              className={`h-16 w-full opacity-0 text-yoda-primary font-bold bg-white rounded-full button-default ${
                step === 1.1 ? "button-fade-in" : ""
              }`}
              style={{ boxShadow: "0px 6px 10px rgba(101, 67, 33, 0.5)" }}
              onClick={() => {
                setStep(2);
                setP((prevP) => prevP + 1);
              }}
            >
              비몽사몽...zzz
              <br />
              멍때리며 씻으러 들어간다.
            </button>
          </div>
        </div>
      </div>
      <div
        className={`invisible w-full h-full flex flex-col items-center ${
          step === 2 ? "fade-in" : "fade-out"
        } bg-cover bg-no-repeat sm:rounded-3xl bg-bottom absolute top-0 left-0`}
        style={{ backgroundImage: `url(${Step2})` }}
      >
        <div className="absolute top-3 left-3 rounded-full w-16 py-1 bg-yoda-primary text-white text-center">
          2/9
        </div>
        <div className="overflow-hidden mt-12 sm:mt-20">
          <div
            className={`text-xl sm:text-lg leading-7 opacity-0 button-default text-center font-bold ${
              step === 2 ? "text-fade-in" : ""
            }`}
            style={{ color: "#444444" }}
          >
            출근하는 길에
            <br />
            문득 예쁜 풍경을 발견한 당신은
          </div>
        </div>
        <div className="mt-8 overflow-hidden w-88 max-w-full pt-1 pb-4 px-4">
          <button
            className={`h-16 w-full opacity-0 text-yoda-primary font-bold bg-white rounded-full button-default ${
              step === 2 ? "button-fade-in" : ""
            }`}
            style={{ boxShadow: "0px 6px 10px rgba(101, 67, 33, 0.5)" }}
            onClick={() => {
              setStep(3);
              dispatch({ type: "UPDATE_IS_INSTA", value: { isInsta: true } });
            }}
          >
            당장 핸드폰을 들어 사진을 찍는다
          </button>
        </div>
        <div className="overflow-hidden w-88 max-w-full pt-1 pb-4 px-4">
          <button
            className={`h-16 w-full opacity-0 text-yoda-primary font-bold bg-white rounded-full button-default ${
              step === 2 ? "button-fade-in" : ""
            }`}
            style={{ boxShadow: "0px 6px 10px rgba(101, 67, 33, 0.5)" }}
            onClick={() => {
              setStep(3);
              dispatch({ type: "UPDATE_IS_INSTA", value: { isInsta: false } });
            }}
          >
            잠시 눈에 담은 뒤 시선을 거둔다
          </button>
        </div>
      </div>
      <div
        className={`invisible opacity-0 w-full h-full flex flex-col items-center ${
          step === 3
            ? "step-3-intro-fade-in"
            : step === 3.1
            ? "step-3-intro-fade-out"
            : ""
        } absolute top-0 left-0 bg-left-bottom bg-cover bg-no-repeat sm:rounded-3xl bg-bottom`}
        style={{ backgroundImage: `url(${Step3_Intro})` }}
      ></div>
      <div
        className={`invisible w-full h-full flex flex-col items-center ${
          step === 3.1 ? "fade-in" : "step-3-fade-out"
        } absolute top-0 left-0 bg-cover bg-no-repeat sm:rounded-3xl bg-bottom`}
        style={{ backgroundImage: `url(${Step3})` }}
      >
        <div className="absolute top-3 left-3 rounded-full w-16 py-1 bg-yoda-primary text-white text-center">
          3/9
        </div>
        <button
          className="w-full h-full flex flex-col items-center justify-center"
          onClick={() => setStep(3.2)}
        >
          <div
            className="animate-flicker bg-center h-20 bg-contain bg-no-repeat flex items-center ml-4 mb-72 sm:mb-32"
            style={{ backgroundImage: `url(${Blinking_Bg_Green})` }}
          >
            탭해서 메세지 확인하기
          </div>
        </button>
      </div>
      <div
        className={`invisible w-full h-full flex flex-col items-center ${
          step === 3.2 ? "step-3-fade-in" : "fade-out"
        } absolute top-0 left-0 bg-cover bg-no-repeat bg-white sm:rounded-3xl bg-bottom`}
        style={{ backgroundImage: `url(${Step3_BG})` }}
      >
        <div className="absolute top-3 left-3 rounded-full w-16 py-1 bg-yoda-primary text-white text-center">
          3/9
        </div>
        <div className="overflow-hidden mt-28 h-20">
          <div
            className={`text-xl sm:text-lg leading-7 opacity-0 button-default text-center font-bold ${
              step === 3.2 ? "step-3-text-fade-in" : ""
            }`}
            style={{ color: "#444444" }}
          >
            새로운 업무가 도착했습니다!
            <br />
            어떻게 시작할까요?
          </div>
        </div>
        <div className="mt-14 sm:mt-16 w-full relative">
          <img className="w-full h-auto" src={Step3_Monitor} />
          <div className="absolute left-0 top-5 w-full flex flex-col items-center">
            <div className="overflow-hidden w-88 max-w-full pt-1 pb-4 px-4">
              <button
                className={`h-16 w-full opacity-0 text-white font-bold bg-yoda-primary rounded-full button-default ${
                  step === 3.2 ? "step-3-button-fade-in" : ""
                }`}
                style={{ boxShadow: "0px 6px 10px rgba(0,0,0, 0.5)" }}
                onClick={() => {
                  setStep(4);
                  setJ((prevJ) => prevJ + 1);
                }}
              >
                마스터 플랜은 필수!
                <br />
                체계적으로 계획을 세운 후 시작하기
              </button>
            </div>
            <div className="overflow-hidden w-88 max-w-full pt-1 pb-4 px-4">
              <button
                className={`h-16 w-full opacity-0 text-white font-bold bg-yoda-primary rounded-full button-default ${
                  step === 3.2 ? "step-3-button-fade-in" : ""
                }`}
                style={{ boxShadow: "0px 6px 10px rgba(0,0,0, 0.5)" }}
                onClick={() => {
                  setStep(4);
                  setP((prevP) => prevP + 1);
                }}
              >
                머리속에 가장 먼저 떠오르는
                <br />
                아이디어부터 당장 실행해보기
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`invisible w-full h-full flex flex-col items-center ${
          step === 4 ? "fade-in" : "fade-out"
        } absolute top-0 left-0 bg-cover bg-no-repeat sm:rounded-3xl bg-bottom`}
        style={{ backgroundImage: `url(${Step4})` }}
      >
        <div className="absolute top-3 left-3 rounded-full w-16 py-1 bg-yoda-primary text-white text-center">
          4/9
        </div>
        <div className="overflow-hidden mt-12 sm:mt-20 h-16">
          <div
            className={`text-xl sm:text-lg leading-7 opacity-0 button-default text-center font-bold ${
              step === 4 ? "text-fade-in" : ""
            }`}
            style={{ color: "#444444" }}
          >
            오후 세시, 졸음이 쏟아지는 시각입니다.
            <br />
            잠을 깨기 위해 무엇을 하면 좋을까요?
          </div>
        </div>
        <div
          className="w-full flex-1 flex flex-col items-center bg-cover bg-bottom bg-no-repeat"
          style={{ backgroundImage: `url(${Gradient})` }}
        >
          <div className="flex-1 sm:flex-none"></div>
          <div className="overflow-hidden sm:mt-4 w-88 max-w-full pt-1 pb-4 px-4">
            <button
              className={`h-16 w-full opacity-0 text-yoda-primary font-bold bg-white rounded-full button-default ${
                step === 4 ? "button-fade-in" : ""
              }`}
              style={{ boxShadow: "0px 6px 10px rgba(0,0,0, 0.5)" }}
              onClick={() => {
                setStep(5);
                setE((prevE) => prevE + 1);
              }}
            >
              잠 깨는 데에 수다만한게 없죠!
              <br />
              동료들과 이야기를 나눠요
            </button>
          </div>
          <div className="overflow-hidden mb-12 sm:mb-0 w-88 max-w-full pt-1 pb-4 px-4">
            <button
              className={`h-16 w-full opacity-0 text-yoda-primary font-bold bg-white rounded-full button-default ${
                step === 4 ? "button-fade-in" : ""
              }`}
              style={{ boxShadow: "0px 6px 10px rgba(0,0,0, 0.5)" }}
              onClick={() => {
                setStep(5);
                setI((prevI) => prevI + 1);
              }}
            >
              잠시 바람을 쐬며
              <br />
              혼자 산책을 하러 다녀와요.
            </button>
          </div>
        </div>
      </div>
      <div
        className={`invisible w-full h-full flex flex-col items-center ${
          step === 5 ? "fade-in" : "fade-out"
        } absolute top-0 left-0 bg-cover bg-no-repeat sm:rounded-3xl bg-bottom`}
        style={{ backgroundImage: `url(${Step5})` }}
      >
        <div className="absolute top-3 left-3 rounded-full w-16 py-1 bg-yoda-primary text-white text-center">
          5/9
        </div>
        <div className="overflow-hidden mt-12 sm:mt-16 h-20">
          <div
            className={`text-xl sm:text-lg leading-7 opacity-0 button-default text-center ${
              step === 5 ? "text-fade-in" : ""
            } font-bold`}
            style={{ color: "#444444" }}
          >
            야호! 드디어 퇴근입니다.
            <br />
            오늘은 왠지 수고한 나를 위해
            <br />
            맛있는 저녁식사를 하고 싶어요.
          </div>
        </div>
        <div className="overflow-hidden mt-10 w-88 max-w-full pt-1 pb-4 px-4">
          <button
            className={`h-16 w-full opacity-0 text-yoda-primary font-bold bg-white rounded-full button-default ${
              step === 5 ? "button-fade-in" : ""
            }`}
            style={{ boxShadow: "0px 6px 10px rgba(101, 67, 33, 0.4)" }}
            onClick={() => {
              setStep(6);
              setE((prevE) => prevE + 1);
            }}
          >
            친한 친구에게 연락해요.
            <br />
            "나와! 맛있는 거 먹으러 가자!"
          </button>
        </div>
        <div className="overflow-hidden w-88 max-w-full pt-1 pb-4 px-4">
          <button
            className={`h-16 w-full opacity-0 text-yoda-primary font-bold bg-white rounded-full button-default ${
              step === 5 ? "button-fade-in" : ""
            }`}
            style={{ boxShadow: "0px 6px 10px rgba(101, 67, 33, 0.4)" }}
            onClick={() => {
              setStep(6.5);
              setI((prevI) => prevI + 1);
            }}
          >
            오늘은 방구석 파티다!
            <br />
            혼자 집에서 맛있는 음식 시켜먹기
          </button>
        </div>
      </div>
      <div
        className={`invisible w-full h-full flex flex-col items-center ${
          step === 6 || step === 6.1 ? "fade-in" : "fade-out"
        } absolute top-0 left-0 bg-cover bg-no-repeat sm:rounded-3xl bg-bottom`}
        style={{ backgroundImage: `url(${Step6})` }}
      >
        <div className="absolute top-3 left-3 rounded-full w-16 py-1 bg-yoda-primary text-white text-center">
          6/9
        </div>
        <div className="overflow-hidden mt-14 h-20">
          <div
            className={`text-xl sm:text-lg leading-7 opacity-0 button-default text-center font-bold ${
              step === 6 || step === 6.1 ? "text-fade-in" : ""
            }`}
            style={{ color: "#444444" }}
          >
            어떤 식당을 방문할까요?
          </div>
        </div>
        <div
          className={`absolute w-full left-0 flex flex-col items-center ${
            step === 6 ? "z-fade-in" : "z-fade-out"
          }`}
          style={{ top: "8rem" }}
        >
          <div className="overflow-hidden w-88 max-w-full pt-1 pb-4 px-4">
            <button
              className={`h-16 w-full opacity-0 text-yoda-primary font-bold bg-white rounded-full button-default ${
                step === 6 ? "button-fade-in" : "button-fade-out"
              }`}
              style={{ boxShadow: "0px 6px 10px rgba(101, 67, 33, 0.4)" }}
              onClick={() => {
                setStep(6.1);
                setJ((prevJ) => prevJ + 1);
              }}
            >
              리뷰가 좋은 음식점
            </button>
          </div>
          <div className="overflow-hidden w-88 max-w-full pt-1 pb-4 px-4">
            <button
              className={`h-16 w-full opacity-0 text-yoda-primary font-bold bg-white rounded-full button-default ${
                step === 6 ? "button-fade-in" : "button-fade-out"
              }`}
              style={{ boxShadow: "0px 6px 10px rgba(101, 67, 33, 0.4)" }}
              onClick={() => {
                setStep(6.1);
                setP((prevP) => prevP + 1);
              }}
            >
              간판이 왠지 끌리는 음식점
            </button>
          </div>
        </div>
        <div
          className={`absolute w-full left-0 flex flex-col items-center ${
            step === 6.1 ? "z-fade-in" : "z-fade-out"
          }`}
          style={{ top: "8rem" }}
        >
          <div className="overflow-hidden w-88 max-w-full pt-1 pb-4 px-4">
            <button
              className={`h-16 w-full opacity-0 text-yoda-primary font-bold bg-white rounded-full button-default ${
                step === 6.1 ? "step-6-button-fade-in" : ""
              }`}
              style={{ boxShadow: "0px 6px 10px rgba(101, 67, 33, 0.4)" }}
              onClick={() => {
                door_chime.play();
                setStep(7);
                dispatch({
                  type: "UPDATE_IS_POPULAR",
                  value: { isPopular: true },
                });
              }}
            >
              유명해 보이는 식당
            </button>
          </div>
          <div className="overflow-hidden w-88 max-w-full pt-1 pb-4 px-4">
            <button
              className={`h-16 w-full opacity-0 text-yoda-primary font-bold bg-white rounded-full button-default ${
                step === 6.1 ? "step-6-button-fade-in" : ""
              }`}
              style={{ boxShadow: "0px 6px 10px rgba(101, 67, 33, 0.4)" }}
              onClick={() => {
                door_chime.play();
                setStep(7);
                dispatch({
                  type: "UPDATE_IS_POPULAR",
                  value: { isPopular: false },
                });
              }}
            >
              새로 생긴듯한 식당
            </button>
          </div>
        </div>
      </div>
      <div
        className={`invisible w-full h-full flex flex-col items-center ${
          step === 6.5 || step === 6.6 || step === 6.7 ? "fade-in" : "fade-out"
        } absolute top-0 left-0 sm:rounded-3xl`}
      >
        <div className="absolute top-3 left-3 rounded-full w-16 py-1 bg-yoda-primary text-white text-center z-10">
          6/9
        </div>
        <div
          className={`invisible w-full h-full flex flex-col items-center ${
            step === 6.5 ? "fade-in" : "step-3-fade-out"
          } absolute top-0 left-0 sm:rounded-3xl overflow-hidden`}
        >
          <video
            className="absolute w-full h-full top-0 left-0 object-cover"
            playsInline={true}
            preload="auto"
            autoPlay={true}
            muted={true}
            loop={true}
          >
            <source
              src="https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/step_6_1.mp4"
              type="video/mp4"
            ></source>
          </video>
          <button
            className={`w-full h-full flex flex-col items-center justify-center absolute top-0 left-0 bg-contain bg-no-repeat sm:rounded-3xl bg-center text-lg font-medium`}
            onClick={() => setStep(6.6)}
          >
            <div
              className="animate-flicker bg-center h-20 bg-contain bg-no-repeat flex items-center mb-40"
              style={{ backgroundImage: `url(${Blinking_Bg})` }}
            >
              핸드폰을 탭해주세요
            </div>
          </button>
        </div>
        <img
          className={`invisible absolute top-0 left-0 sm:rounded-3xl h-auto w-full object-fill ${
            step === 6.6 || step === 6.7 ? "step-3-fade-in" : ""
          }`}
          style={{ zIndex: -1 }}
          src={Step6_Mobile_Top}
        />
        <img
          className={`invisible absolute bottom-0 left-0 sm:rounded-3xl h-auto w-full object-fill ${
            step === 6.6 || step === 6.7 ? "step-3-fade-in" : ""
          }`}
          style={{ zIndex: -1 }}
          src={Step6_Mobile_Bottom}
        />
        <div
          className="overflow-hidden mt-28 sm:mt-40 h-20"
          style={{ zIndex: 9 }}
        >
          <div
            className={`text-xl sm:text-lg leading-7 opacity-0 button-default text-center ${
              step === 6.6 || step === 6.7 ? "step-6-button-fade-in" : ""
            } font-bold`}
            style={{ color: "#444444" }}
          >
            어떤 음식점에서
            <br />
            배달 주문을 할까요?
          </div>
        </div>
        <div className="w-full relative">
          <div
            className={`absolute top-0 left-0 w-full flex flex-col items-center ${
              step === 6.6 ? "z-fade-in" : "z-fade-out"
            }`}
          >
            <div className="overflow-hidden w-88 max-w-full pt-1 pb-4 px-4">
              <button
                className={`h-12 sm:h-16 w-full opacity-0 text-white font-bold bg-yoda-primary rounded-full button-default ${
                  step === 6.6 ? "step-6-button-fade-in" : "button-fade-out"
                }`}
                style={{ boxShadow: "0px 6px 10px rgba(0,0,0, 0.4)" }}
                onClick={() => {
                  setStep(6.7);
                  setJ((prevJ) => prevJ + 1);
                }}
              >
                리뷰가 좋은 음식점
              </button>
            </div>
            <div className="overflow-hidden w-88 max-w-full pt-1 pb-4 px-4">
              <button
                className={`h-12 sm:h-16 w-full opacity-0 text-white font-bold bg-yoda-primary rounded-full button-default ${
                  step === 6.6 ? "step-6-button-fade-in" : "button-fade-out"
                }`}
                style={{ boxShadow: "0px 6px 10px rgba(0,0,0, 0.4)" }}
                onClick={() => {
                  setStep(6.7);
                  setP((prevP) => prevP + 1);
                }}
              >
                사진이 왠지 끌리는 음식점
              </button>
            </div>
          </div>
          <div
            className={`absolute top-0 left-0 w-full flex flex-col items-center ${
              step === 6.7 ? "z-fade-in" : "z-fade-out"
            }`}
          >
            <div className="overflow-hidden w-88 max-w-full pt-1 pb-4 px-4">
              <button
                className={`h-12 sm:h-16 w-full opacity-0 text-white font-bold bg-yoda-primary rounded-full button-default ${
                  step === 6.7 ? "step-6-button-fade-in" : ""
                }`}
                style={{ boxShadow: "0px 6px 10px rgba(0,0,0, 0.4)" }}
                onClick={() => {
                  setStep(7);
                  dispatch({
                    type: "UPDATE_IS_POPULAR",
                    value: { isPopular: true },
                  });
                }}
              >
                유명해 보이는 식당
              </button>
            </div>
            <div className="overflow-hidden w-88 max-w-full pt-1 pb-4 px-4">
              <button
                className={`h-12 sm:h-16 w-full opacity-0 text-white font-bold bg-yoda-primary rounded-full button-default ${
                  step === 6.7 ? "step-6-button-fade-in" : ""
                }`}
                style={{ boxShadow: "0px 6px 10px rgba(0,0,0, 0.4)" }}
                onClick={() => {
                  setStep(7);
                  dispatch({
                    type: "UPDATE_IS_POPULAR",
                    value: { isPopular: false },
                  });
                }}
              >
                새로 생긴듯한 식당
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`invisible w-full h-full flex flex-col items-center ${
          step === 7 || step === 7.1 ? "fade-in" : "fade-out"
        } absolute top-0 left-0 sm:rounded-3xl`}
      >
        <div
          className={`invisible w-full h-full flex flex-col items-center ${
            step === 7 ? "fade-in" : "step-3-fade-out"
          } absolute top-0 left-0 bg-cover bg-no-repeat sm:rounded-3xl bg-bottom`}
          style={{ backgroundImage: `url(${Step7})` }}
        >
          <div className="absolute top-3 left-3 rounded-full w-16 py-1 bg-yoda-primary text-white text-center">
            7/9
          </div>
        </div>
        <div
          className={`invisible absolute left-0 top-0 bg-cover bg-no-repeat bg-bottom ${
            step === 7.1 ? "movie-fade-in" : "fade-out"
          } w-full h-full flex justify-center items-center`}
          style={{ zIndex: 9, bottom: "17.5rem" }}
        >
          <div
            className={`w-64 h-auto self-center ${
              step === 7.1 || step === 7.2 ? "movie-zoom-in" : ""
            } relative grid grid-cols-2 gap-2 p-2`}
          >
            <img
              className="absolute top-0 left-0 w-full h-full"
              src={Movie_Background}
            />
            <button className="z-10" onClick={() => setStep(7.2)}>
              <img src={Movie_1} />
            </button>
            <button className="z-10" onClick={() => setStep(7.2)}>
              <img src={Movie_2} />
            </button>
            <button className="z-10" onClick={() => setStep(7.2)}>
              <img src={Movie_3} />
            </button>
            <button className="z-10" onClick={() => setStep(7.2)}>
              <img src={Movie_4} />
            </button>
          </div>
        </div>
        <div className="overflow-hidden mt-16 h-20" style={{ zIndex: 99 }}>
          <div
            className={`text-xl sm:text-lg leading-7 opacity-0 button-default text-center ${
              step === 7 || step === 7.1 ? "text-fade-in" : "fade-out"
            } font-bold ${step === 7.1 ? "text-white" : "description-color"}`}
          >
            자기 전 침대에 누워 영화를
            <br />한 편 보기로 합니다.
            <br />
            어떤 영화를 볼까요?
          </div>
        </div>
        <button
          className={`absolute w-full h-full flex flex-col items-center justify-center absolute top-0 left-0 bg-cover bg-no-repeat sm:rounded-3xl bg-bottom ${
            step === 7 ? "fade-in" : "step-3-fade-out"
          }`}
          style={{ zIndex: 999 }}
          onClick={() => setStep(7.1)}
        >
          <div
            className="animate-flicker bg-center h-20 bg-contain bg-no-repeat flex items-center sm:mb-40 mb-44"
            style={{
              backgroundImage: `url(${Blinking_Bg_Green})`,
              color: "#777777",
            }}
          >
            TV를 탭해주세요
          </div>
        </button>
      </div>
      <div
        className={`invisible w-full h-full ${
          step === 7.2 ? "fade-in" : "fade-out"
        } absolute top-0 left-0 bg-cover bg-no-repeat sm:rounded-3xl bg-center`}
        style={{ backgroundImage: `url(${Step7_Mobile})` }}
      >
        <button
          className={`absolute w-full h-full flex flex-col items-center justify-center absolute top-0 left-0 bg-cover bg-no-repeat sm:rounded-3xl bg-bottom text-lg font-medium ${
            step === 7.2 ? "fade-in" : "step-3-fade-out"
          } text-white`}
          style={{ zIndex: 9 }}
          onClick={() => setStep(8)}
        >
          <div
            className="animate-flicker bg-center h-20 bg-contain bg-no-repeat flex items-center mt-48"
            style={{ backgroundImage: `url(${Blinking_Bg})` }}
          >
            탭해서 전화받기
          </div>
        </button>
      </div>
      <div
        className={`invisible w-full h-full flex flex-col items-center ${
          step === 8 || step === 8.1 ? "fade-in" : "fade-out"
        } absolute top-0 left-0 bg-cover bg-no-repeat sm:rounded-3xl bg-bottom`}
        style={{ backgroundImage: `url(${Step8})` }}
      >
        <div className="absolute top-3 left-3 rounded-full w-16 py-1 bg-yoda-primary text-white text-center">
          8/9
        </div>
        <div className="absolute bottom-36 left-0 w-full h-32 flex justify-center">
          <img className="w-7/12 h-auto" src={Step8_Bubble_Bottom} />
        </div>
        <div className="overflow-hidden absolute w-full flex justify-center bottom-52">
          <div
            className={`opacity-0 button-default ${
              step === 8 ? "step-8-conv-1-fade-in" : "btn-fade-out"
            }`}
          >
            무슨 영화 보고 있었어?
          </div>
        </div>
        <div
          className="absolute left-0 w-full h-32 flex justify-center"
          style={{ bottom: "28rem" }}
        >
          <img className="w-7/12 h-auto" src={Step8_Bubble_Top} />
        </div>
        <div
          className="overflow-hidden absolute w-full flex justify-center"
          style={{ bottom: "31.5rem" }}
        >
          <div
            className={`opacity-0 button-default ${
              step === 8 ? "step-8-conv-2" : ""
            }`}
          >
            {"나 <여행을 열다>!"}
          </div>
        </div>
        <div
          className="overflow-hidden absolute w-full flex justify-center"
          style={{ bottom: "11.5rem" }}
        >
          <div
            className={`opacity-0 button-default ${
              step === 8 ? "step-8-conv-3-fade-in" : "btn-fade-out"
            }`}
          >
            그 영화 어때?
          </div>
        </div>
        <div
          className={`absolute left-0 top-0 w-full h-full flex flex-col items-center ${
            step === 8 ? "" : "fade-out"
          }`}
        >
          <div className="flex-1"></div>
          <div className="overflow-hidden w-88 max-w-full pt-1 pb-4 px-4">
            <button
              className={`h-16 w-full opacity-0 text-yoda-primary font-bold bg-white rounded-full button-default ${
                step === 8 ? "step-8-btn-fade-in" : ""
              }`}
              style={{ boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.5)" }}
              onClick={() => {
                setStep(8.1);
                setB("S");
              }}
            >
              음...여행에 관한 영화고,
              <br />
              요즘 핫한 김여다가 주인공이야!
            </button>
          </div>
          <div
            className="overflow-hidden w-88 max-w-full pt-1 pb-4 px-4"
            style={{ marginBottom: "28rem" }}
          >
            <button
              className={`h-12 w-full opacity-0 text-yoda-primary font-bold bg-white rounded-full button-default ${
                step === 8 ? "step-8-btn-fade-in" : ""
              }`}
              style={{ boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.5)" }}
              onClick={() => {
                setStep(8.1);
                setB("N");
              }}
            >
              완전 재밌어 이거! 꼭 봐야해!
            </button>
          </div>
        </div>
        <div className="overflow-hidden absolute w-full flex justify-center bottom-48">
          <div
            className={`opacity-0 button-default ${
              step === 8.1 ? "step-8-conv-4" : ""
            }`}
          >
            아 정말?
            <br />
            나도 나중에 봐야겠다!
          </div>
        </div>
        <div className="overflow-hidden absolute w-full flex justify-center bottom-48">
          <div
            className={`opacity-0 button-default ${
              step === 8.1 ? "step-8-conv-5-fade-in" : "btn-fade-out"
            }`}
          >
            그나저나 몸이 으슬으슬하네.
            <br />
            감기에 걸린 것 같아.
          </div>
        </div>
        <div
          className={`absolute left-0 top-0 w-full h-full flex flex-col items-center ${
            step === 8.1 ? "" : "fade-out"
          }`}
        >
          <div className="flex-1"></div>
          <div className="overflow-hidden w-88 max-w-full pt-1 pb-4 px-4">
            <button
              className={`h-16 w-full opacity-0 text-yoda-primary font-bold bg-white rounded-full button-default ${
                step === 8.1 ? "step-8-1-btn-fade-in" : ""
              }`}
              style={{ boxShadow: "0px 6px 10px rgba(101, 67, 33, 0.4)" }}
              onClick={() => {
                setStep(9);
                setT("T");
              }}
            >
              병원은 가봤어? 약은?
            </button>
          </div>
          <div
            className="overflow-hidden w-88 max-w-full pt-1 pb-4 px-4"
            style={{ marginBottom: "26rem" }}
          >
            <button
              className={`h-16 w-full opacity-0 text-yoda-primary font-bold bg-white rounded-full button-default ${
                step === 8.1 ? "step-8-1-btn-fade-in" : ""
              }`}
              style={{ boxShadow: "0px 6px 10px rgba(101, 67, 33, 0.4)" }}
              onClick={() => {
                setStep(9);
                setT("F");
              }}
            >
              괜찮아? 머리 많이 아파?
            </button>
          </div>
        </div>
      </div>
      <div
        className={`invisible w-full h-full flex flex-col items-center ${
          step === 9 || step === 9.1 || step === 9.2 ? "fade-in" : "fade-out"
        } absolute top-0 left-0 sm:rounded-3xl`}
      >
        <div className="absolute top-3 left-3 rounded-full w-16 py-1 bg-yoda-primary text-white text-center z-10">
          9/9
        </div>
        <video
          ref={videoRef}
          className="absolute w-full h-full top-0 left-0 object-cover sm:rounded-3xl"
          playsInline={true}
          preload="metadata"
          autoPlay={true}
          muted={true}
          loop={false}
          onLoadedData={() => videoRef.current?.pause()}
        >
          <source
            src="https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/step_9.mp4#t=0.01"
            type="video/mp4"
          ></source>
        </video>
        <div className="overflow-hidden mt-28 h-20">
          <div
            className={`text-xl sm:text-lg leading-7 opacity-0 button-default text-center ${
              step === 9 || step === 9.1 ? "text-fade-in" : ""
            } text-white font-bold`}
          >
            내일은 신나는 주말이에요.
            <br />
            어떻게 보내면 좋을까요?
          </div>
        </div>
        <div
          className={`absolute left-0 w-full ${
            step === 9 ? "" : "fade-out"
          } px-4`}
          style={{ top: "14rem" }}
        >
          <div>
            <img className="w-full h-auto" src={Step9Bubble} />
            <div className="absolute w-full h-full top-11 left-0 flex justify-between pl-8 pr-10">
              <div className="overflow-hidden h-20">
                <button
                  className={`w-full opacity-0 text-yoda-primary font-bold button-default text-center ${
                    step === 9 ? "button-fade-in" : "button-fade-out"
                  }`}
                  onClick={() => {
                    setStep(9.1);
                    setE((prevE) => prevE + 1);
                  }}
                >
                  여기저기 놀러다니며
                  <br />
                  에너지 충전!
                </button>
              </div>
              <div className="overflow-hidden h-20">
                <button
                  className={`w-full opacity-0 text-yoda-primary font-bold button-default text-center ${
                    step === 9 ? "button-fade-in" : "button-fade-out"
                  }`}
                  onClick={() => {
                    setStep(9.1);
                    setI((prevI) => prevI + 1);
                  }}
                >
                  이불 밖은 위험해!
                  <br />집 안에서
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`absolute left-0 w-full ${
            step === 9.1 ? "" : "fade-out"
          } px-4`}
          style={{ top: "14rem" }}
        >
          <div>
            <img className="w-full h-auto" src={Step9Bubble} />
            <div className="absolute w-full h-full top-10 left-0 flex justify-between">
              <button
                className={`w-full h-16 opacity-0 text-yoda-primary font-bold button-default ${
                  step === 9.1 ? "step-6-button-fade-in" : "button-fade-out"
                }`}
                onClick={() => {
                  setStep(9.2);
                  dispatch({
                    type: "UPDATE_IS_BUSY",
                    value: { isBusy: true },
                  });
                }}
              >
                부지런히 보내요
              </button>
              <button
                className={`w-full h-16 opacity-0 text-yoda-primary font-bold button-default ${
                  step === 9.1 ? "step-6-button-fade-in" : "button-fade-out"
                }`}
                onClick={() => {
                  setStep(9.2);
                  dispatch({
                    type: "UPDATE_IS_BUSY",
                    value: { isBusy: false },
                  });
                }}
              >
                여유롭게 보내요
              </button>
            </div>
          </div>
        </div>
      </div>
      {step === 9.3 && (
        <video
          className="absolute w-full h-full top-0 left-0 object-cover sm:rounded-3xl"
          preload="auto"
          playsInline={true}
          autoPlay={true}
          muted={true}
          loop={true}
        >
          <source
            src="https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/loading.mp4"
            type="video/mp4"
          ></source>
        </video>
      )}
      <div
        className={`invisible w-full h-full flex flex-col items-center ${
          step === 10 ? "fade-in" : "fade-out"
        } absolute top-0 left-0 sm:rounded-3xl bg-white text-center overflow-y-auto`}
      >
        <a
          href="https://yodareactnative.page.link/UkMX"
          className="self-end mr-4 mt-4"
        >
          <div className="bg-yoda-primary py-2 px-3 rounded-full text-white text-sm font-bold">
            여다로 가기
          </div>
        </a>
        <div className="w-full">
          <img src={getPersonalCharacterImg()} className="w-full h-auto" />
        </div>
        <div
          className="w-full px-10 sm:rounded-b-3xl"
          style={{
            backgroundColor: getBackgroundColor(),
            color: getTextColor(),
          }}
        >
          <div className="w-full whitespace-pre-wrap text-left text-base">
            {getPersonalDescription()}
          </div>
          <div
            className="mt-10 w-full flex items-center justify-start text-left pl-6 py-3 bg-white rounded-t-3xl"
            style={{
              marginLeft: "-2.5rem",
              width: "calc(100% + 5rem)",
            }}
          >
            <div
              className="border-l-4 mr-2 h-4 rounded"
              style={{ borderColor: getBackgroundColor() }}
            ></div>
            <div
              className="text-2xl font-bold text-white"
              style={{ color: getBackgroundColor() }}
            >
              나랑 잘 어울리는 여행지
            </div>
          </div>
        </div>
        <img className="w-full h-full" src={getCityImg()} />
        <div className="w-full px-6">
          <div
            className="w-full text-base mt-6 text-left whitespace-pre-line px-4"
            dangerouslySetInnerHTML={{ __html: getCityDescription() }}
          ></div>
          <div className="w-full mt-12 px-12">
            <div
              className="border-2 rounded"
              style={{ borderColor: getBackgroundColor() }}
            ></div>
          </div>
          {highlights.map((highlight: any) => (
            <div className="mt-8 mb-4 w-full">
              <div className="mt-10 w-full flex items-center justify-start text-left">
                <div
                  className="border-l-4 mr-2 h-4 rounded"
                  style={{ borderColor: getBackgroundColor() }}
                ></div>
                <div
                  className="text-2xl font-bold"
                  style={{ color: getBackgroundColor() }}
                >
                  {highlight.name}
                </div>
              </div>
              <div
                className="flex w-full overflow-x-auto mt-3 h-64"
                style={{ marginLeft: "-1.5rem", width: "calc(100% + 3rem)" }}
              >
                {highlight.insta.map((insta: any, index: number) => (
                  <a
                    className={`inline-block flex-shrink-0 ${
                      index === 0 ? "ml-6" : "ml-3"
                    } ${index === highlight.insta.length - 1 ? "pr-6" : ""}`}
                    href={insta.link}
                    target="_blank"
                  >
                    <div className="w-60 h-60 relative">
                      <img
                        className="w-full h-full rounded-md object-cover"
                        src={insta.thumbnailUrl}
                      />
                      <div className="absolute bottom-2 right-2 text-white text-xs">
                        {insta.authorName}
                      </div>
                    </div>
                  </a>
                ))}
              </div>
              <div
                className="w-full mt-3 text-left bg-yoda-gray-0 rounded-2xl py-3 px-4"
                dangerouslySetInnerHTML={{ __html: highlight.description }}
              ></div>
            </div>
          ))}
        </div>
        <a
          className="w-full"
          href="https://yodareactnative.page.link/UkMX"
          onClick={() =>
            ReactGA.event({
              category: "MBTI유입",
              action: "from_mbti",
              label: "MBTI_유입",
            })
          }
        >
          <div
            className="w-full mt-8 pt-8 pb-10"
            style={{ backgroundColor: "#A7CC66" }}
          >
            <img src={Logo} className="mx-auto w-9 h-auto" />
            <div
              className="mt-7 text-2xl text-white font-bold"
              style={{ textShadow: "0px 3px 6px #00000031" }}
            >
              내 성향과 딱 맞는 여행일정,
              <br />
              여다가 3분만에 만들어드려요!
            </div>
            <div className="mt-6 w-full px-12">
              <img className="w-full" src={Carrier} />
            </div>
            <div className="w-full h-14 px-6 mt-12 flex items-center justify-center">
              <div
                className="bg-white shadow-lg rounded-full text-lg font-bold flex items-center justify-center sizing-animate"
                style={{ color: "#326E06" }}
              >
                여다에서 나만의 여행일정 만들기
              </div>
            </div>
          </div>
        </a>
        <div className="w-full px-6">
          <div className="w-full mt-8 px-12">
            <div
              className="border-2 rounded"
              style={{ borderColor: getBackgroundColor() }}
            ></div>
          </div>
          <div
            className="mt-8 text-xl font-bold"
            style={{ color: getBackgroundColor() }}
          >
            친구와 테스트 공유하기
          </div>
          <div className="mt-6 w-full flex items-center justify-between">
            <a href={getResultImg()} download={MBTI} title={MBTI}>
              <img className="w-16 h-16" src={Capture} />
            </a>
            <a
              href="https://twitter.com/intent/tweet?text=<MBTI 여다 여행성향 테스트> 나를 부르는 국내 여행지는 어디? 여다와 함께 알아봐요!&url=https://yodatrip.com/mbti&hashtags=여행테스트,여행성향테스트,여행을열다,여다"
              target="_blank"
            >
              <img className="h-16 w-16" src={Twitter} />
            </a>
            <button
              className="h-16 w-16"
              onClick={() => {
                // @ts-ignore
                if (!Kakao.isInitialized()) {
                  // @ts-ignore
                  Kakao.init(process.env.REACT_APP_KAKAO_CLIENT_ID);
                }
                // @ts-ignore
                Kakao.Link.sendDefault({
                  objectType: "feed",
                  content: {
                    title: "나를 부르는 국내여행지는 어디?",
                    // @ts-ignore
                    description: "여다와 함께 나의 여행성향 알아보기",
                    imageUrl: `https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/${MBTI}_thumbnail.png`,
                    imageWidth: 800,
                    imageHeight: 800,
                    link: {
                      mobileWebUrl: `https://yodatrip.com/mbti`,
                    },
                  },
                  buttons: [
                    {
                      title: "결과보기",
                      link: {
                        webUrl: `https://yodatrip.com/mbti/result/${MBTI}`,
                        mobileWebUrl: `https://yodatrip.com/mbti/result/${MBTI}`,
                      },
                    },
                    {
                      title: "나도하기",
                      link: {
                        webUrl: "https://yodatrip.com/mbti",
                        mobileWebUrl: "https://yodatrip.com/mbti",
                      },
                    },
                  ],
                });
              }}
            >
              <img className="w-full h-full" src={KakaoIcon} />
            </button>
            <div className="h-16 w-16">
              <button
                className="btn"
                data-clipboard-text={`https://yodatrip.com/mbti/result/${MBTI}`}
              >
                <img className="w-full h-full" src={Link} />
              </button>
            </div>
          </div>
          <img className="mt-2 ml-4 h-6 w-auto" src={CaptureGuide} />
          <div className="w-full mb-10">
            <button
              className="mt-10 shadow-lg w-full h-14 rounded-full text-lg text-white font-bold flex items-center justify-center"
              style={{ backgroundColor: getBackgroundColor() }}
              onClick={() => {
                setStep(-1);
              }}
            >
              테스트 다시하기
            </button>
          </div>
        </div>
      </div>
      <div
        className={`absolute w-full h-full top-0 left-0 sm:shadow-2xl sm:rounded-3xl ${
          step === 0
            ? "bg-transparent"
            : step === 3.1 ||
              step === 3.2 ||
              step === 4 ||
              step === 5 ||
              step === 6 ||
              step === 6.6 ||
              step === 6.7
            ? "bg-white"
            : "bg-black"
        }`}
        style={{ zIndex: -2 }}
      ></div>
    </div>
  );
};

export default Questions;
