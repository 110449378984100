import React, {
  Dispatch,
  ReactElement,
  createContext,
  useReducer,
} from "react";

type DialogType = {
  type?:
    | "DialogConfirm"
    | "DialogConfirmBlack"
    | "DialogYesNo"
    | "DialogError"
    | "Dialog"
    | "DialogSwitch";
  content?: ReactElement | string;
  key?: string;
  checkFooter?: boolean;
  className?: string;
  onClickLeft?: () => void;
  onClickRight?: () => void;
  onClickYes?: () => void;
  onClickOuter?: () => void;
  hideYes?: boolean;
  buttonText?: string;
  yesText?: string;
  noText?: string;
};
export type UserType = {
  accessToken?: string;
  birthdate?: string;
  email?: string;
  gender?: number;
  mobile?: string;
  name?: string;
  provider?: string;
  termAgreed?: boolean;
  userId?: string;
};
interface MainState {
  dialog: DialogType;
  user?: UserType;
  getMeDone: boolean;
}
type MainReducer = React.Reducer<MainState, MainAction>;

const initialState: React.ReducerState<MainReducer> = {
  dialog: {},
  user: undefined,
  getMeDone: false,
};

export type MainAction =
  | {
      type: "UPDATE_DIALOG";
      value: DialogType;
    }
  | {
      type: "UPDATE_USER";
      value: UserType;
    }
  | {
      type: "UPDATE_GET_ME_DONE";
      value: boolean;
    }
  | { type: "SIGNOUT" };
const reducer: MainReducer = (state = initialState, action: MainAction) => {
  switch (action.type) {
    case "UPDATE_DIALOG":
      return { ...state, dialog: action.value };

    case "UPDATE_USER":
      return { ...state, user: { ...state.user, ...action.value } };

    case "UPDATE_GET_ME_DONE":
      return { ...state, getMeDone: action.value };

    case "SIGNOUT":
      return { ...state, user: undefined };

    default:
      return state;
  }
};

const MainContext = createContext<[MainState, Dispatch<MainAction>]>([
  initialState,
  () => initialState,
]);

const MainProvider: React.FC<{}> = ({ children }) => {
  const value = useReducer(reducer, initialState);
  return <MainContext.Provider value={value}>{children}</MainContext.Provider>;
};

export { MainContext, MainProvider };
