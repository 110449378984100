import {
  DestFoodActType,
  DestMapCityType,
  DestTextType,
  accommOptions,
  budgetOptions,
  transTimeOptions,
  transportOptions,
} from "../../../util/surveyOptions";
import { getDestOptions, getInterestOptions } from "../../../api/Survey";
import { useCallback, useEffect, useState } from "react";

import SurveyItem from "./SurveyItem";
import { SurveyState } from "../../../contexts/SurveyContext";
import _ from "lodash";

const parkOptions = [
  {
    name: "설악산 국립공원",
    value: 3767,
  },
  {
    name: "치악산 국립공원",
    value: 3927,
  },
  {
    name: "오대산 국립공원(상원사)",
    value: 4022,
  },
];

const Survey = ({ survey }: { survey: undefined | SurveyState }) => {
  const [interestOptions, setInterestOptions] = useState<
    { name: string; value: string }[]
  >([]);
  const [destOptions, setDestOptions] = useState<
    | {
        cities: DestMapCityType[];
        foods: DestFoodActType[];
        acts: DestFoodActType[];
        texts: DestTextType[];
      }
    | undefined
  >();
  useEffect(() => {
    const callGetInterestOptions = async () => {
      try {
        const { data } = await getInterestOptions("");
        setInterestOptions(
          data
            .map((op) => ({
              name: op.name,
              value: op.value,
              children: op.children,
            }))
            .reduce((arr: { name: string; value: string }[], op) => {
              if (op.children.length > 0) {
                arr.push(...op.children);
              } else {
                arr.push(op);
              }
              return arr;
            }, [])
        );
      } catch (e) {
        console.log(e);
      }
    };
    const callGetDestOptions = async () => {
      try {
        const { data } = await getDestOptions("");
        setDestOptions(data);
      } catch (e) {
        console.log(e);
      }
    };
    callGetInterestOptions();
    callGetDestOptions();
  }, []);
  const getDates = useCallback(() => {
    if (
      survey === undefined ||
      (survey.from === "" &&
        survey.to === "" &&
        survey.fromMonth === 0 &&
        survey.length === 0)
    ) {
      return "";
    }

    if (survey.from !== "" && survey.to !== "") {
      return `${survey.from} ~ ${survey.to}`.replace(/-/g, ". ");
    }
    return `${survey.fromMonth}월 - ${
      survey.length === 1
        ? "당일치기"
        : `${survey.length - 1}박${survey.length}일`
    }`;
  }, [survey]);

  const getPartyTypes = useCallback(() => {
    if (
      survey === undefined ||
      survey.adults + survey.kids === 0 ||
      (survey.kids === 1 && survey.kidsMinAge === 0) ||
      (survey.kids > 1 && (survey.kidsMinAge === 0 || survey.kidsMaxAge === 0))
    ) {
      return "";
    }
    const adultsCount = survey.adults > 0 ? `어른 ${survey.adults}명` : "";
    const kidsCount = survey.kids > 0 ? `아이 ${survey.kids}명` : "";
    return `${adultsCount}${adultsCount && kidsCount ? ", " : ""}${kidsCount}`;
  }, [survey]);

  const getDepartLocation = useCallback(() => {
    if (survey === undefined || survey.departLocation === "") {
      return "";
    }
    return survey.departLocation;
  }, [survey]);

  const getTransportTypes = useCallback(() => {
    if (survey === undefined || survey.transportTypes.length === 0) {
      return "";
    }

    let transTime = "";
    if (survey.transportTimes && survey.transportTimes.length > 0) {
      transTime =
        ", " +
        transTimeOptions
          .filter(
            (tT) =>
              survey.transportTimes.findIndex((ctT) => ctT === tT.value) > -1
          )
          .map((tT) => tT.name)
          .join(", ");
    }

    return (
      (transportOptions.find((op) => _.isEqual(op.value, survey.transportTypes))
        ?.name || "") + transTime
    );
  }, [survey]);

  const getDestName = useCallback(() => {
    if (survey === undefined || destOptions === undefined) {
      return "";
    }
    const destFood = destOptions.foods.find(
      (op) => op.poiId === survey.destFood
    )?.description;
    const destAct = destOptions.acts.find((op) => {
      return op.poiId === survey.destAct;
    })?.description;

    if (
      survey.destName === "" &&
      survey.destCity === "" &&
      survey.destType === undefined &&
      (survey.destFood === 0 || destFood === undefined) &&
      (survey.destAct === 0 || destAct === undefined)
    ) {
      return "";
    }

    if (survey.destName !== "") return survey.destName;
    else if (survey.destCity !== "") return survey.destCity;
    else if (destFood) return destFood;
    else if (destAct) return destAct;

    return "여다가 정해주세요!";
  }, [survey, destOptions]);

  const getAccomm = useCallback(() => {
    if (survey === undefined) {
      return "";
    }
    if (survey.accommAddr && survey.accommAddr.length > 0) {
      return survey.accommAddr;
    }
    const accommType = survey.accommTypes
      .map((ty) => accommOptions.find((op) => op.value === ty)?.name)
      .filter((ty): ty is string => ty !== undefined)
      .sort()
      .join(", ");

    const accommBudgetList = survey.accommBudget
      .map((ty) => budgetOptions.find((op) => op.value === ty))
      .filter(
        (
          ty: { name: string; order: number; value: string } | undefined
        ): ty is { name: string; order: number; value: string } =>
          ty !== undefined
      )
      .sort((ty1, ty2) => ty1.order - ty2.order);

    let accommBudget = "";
    if (accommBudgetList.length === 1) {
      accommBudget = `${accommBudgetList[0].name}`;
    } else if (accommBudgetList.length !== 0) {
      accommBudget = `${accommBudgetList[0].name} ~ ${
        accommBudgetList[accommBudgetList.length - 1].name
      }`;
    }

    if (accommType === "" || accommBudget === "") {
      return "";
    }
    return `종류: ${accommType}, 금액대: ${accommBudget}`;
  }, [survey]);

  const getInterests = useCallback(() => {
    if (survey === undefined) {
      return "";
    }
    const interestPriority = survey.interestPriority
      .map((ty) => interestOptions.find((op) => op.value === ty)?.name)
      .filter((ty): ty is string => ty !== undefined);

    const interests = survey.interestTypes
      .map((ty) => interestOptions.find((op) => op.value === ty)?.name)
      .filter((ty): ty is string => ty !== undefined)
      .filter((ty): ty is string => !interestPriority.includes(ty));

    const mergedInterest = [...interestPriority, ...interests];

    const uniqueInterests = mergedInterest.filter(
      (ty, index) => mergedInterest.findIndex((type) => type === ty) === index
    );
    if (uniqueInterests.length === 0) {
      return "";
    }
    return uniqueInterests.join(", ").replace(/\/\n/g, "/");
  }, [survey, interestOptions]);

  return (
    <div className="relative w-full rounded sm:rounded-md break-all">
      <div
        className="w-full overflow-y-auto rounded-t sm:rounded-t-md bg-white pb-5"
        style={{ maxHeight: "calc(100vh - 16rem - 17px)" }}
      >
        <div className="sticky left-0 top-0 px-4 flex items-center bg-yoda-primary">
          <div className="py-2.5 text-xl text-white font-medium">
            내 설문 다시보기
          </div>
          <div className="flex-grow"></div>
        </div>
        <SurveyItem
          whitespace="whitespace-pre-line"
          title="여행 날짜"
          text={getDates()}
        />
        <SurveyItem
          whitespace="whitespace-pre-line"
          title="교통 수단"
          text={getTransportTypes()}
        />
        <SurveyItem
          whitespace="whitespace-pre-line"
          title="출발지"
          text={getDepartLocation()}
        />
        <SurveyItem
          whitespace="whitespace-pre-line"
          title="동행인 정보"
          text={getPartyTypes()}
        />
        <SurveyItem
          whitespace="whitespace-pre-line"
          title="선호 지역"
          text={getDestName()}
        />
        {getAccomm().length > 0 && (
          <SurveyItem whitespace="" title="숙소" text={getAccomm()} />
        )}
        <SurveyItem whitespace="" title="관심 활동" text={getInterests()} />
      </div>
    </div>
  );
};

export default Survey;
