import { ReactElement, useContext, useEffect } from "react";

import DialogConfirm from "../Popups/DialogConfirm";
import DialogConfirmBlack from "../Popups/DialogConfirmBlack";
import DialogError from "../Popups/DialogError";
import DialogSwitch from "../Popups/DialogSwitch";
import DialogYesNo from "../Popups/DialogYesNo";
import { MainContext } from "../../contexts/MainContext";

const DialogContainer = () => {
  const [ctx, dispatch] = useContext(MainContext);

  const closeHandler = () => {
    dispatch({ type: "UPDATE_DIALOG", value: { content: undefined } });
  };

  useEffect(() => {
    if (ctx.dialog.content) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [ctx]);

  if (ctx.dialog.content === undefined) {
    return null;
  }

  switch (ctx.dialog.type) {
    case "DialogConfirm":
      return (
        <div className="fixed top-0 left-0 h-full w-full z-50">
          <div className="absolute opacity-30 w-full h-full bg-yoda-black-1"></div>
          <div className="h-full w-full flex justify-center items-center">
            <DialogConfirm
              content={ctx.dialog.content as ReactElement}
              closeHandler={closeHandler}
              onClickOuter={ctx.dialog.onClickOuter}
              onClickYes={ctx.dialog.onClickYes}
              hideYes={ctx.dialog.hideYes}
              buttonText={ctx.dialog.buttonText}
            />
          </div>
        </div>
      );
    case "DialogConfirmBlack":
      return (
        <div className="fixed top-0 left-0 h-full w-full z-50">
          <div className="absolute opacity-30 w-full h-full bg-yoda-black-1"></div>
          <div className="h-full w-full flex justify-center items-center">
            <DialogConfirmBlack
              content={ctx.dialog.content as ReactElement}
              closeHandler={closeHandler}
              onClickOuter={ctx.dialog.onClickOuter}
              onClickYes={ctx.dialog.onClickYes}
              hideYes={ctx.dialog.hideYes}
            />
          </div>
        </div>
      );
    case "DialogYesNo":
      return ctx.dialog.onClickYes ? (
        <div className="flex justify-center items-center fixed top-0 left-0 h-full w-full z-50">
          <div className="absolute opacity-30 w-full h-full bg-black"></div>
          <DialogYesNo
            content={ctx.dialog.content as ReactElement}
            closeHandler={closeHandler}
            onClickYes={ctx.dialog.onClickYes}
            yesText={ctx.dialog.yesText}
            noText={ctx.dialog.noText}
          />
        </div>
      ) : null;
    case "DialogError":
      return (
        <DialogError
          content={ctx.dialog.content as string}
          closeHandler={() => {
            closeHandler();
            if (ctx.dialog.onClickOuter) {
              ctx.dialog.onClickOuter();
            }
          }}
        />
      );
    case "Dialog":
      return (
        <div className="fixed top-0 left-0 h-screen w-screen z-50">
          <div className="absolute bg-yoda-black-1 opacity-70 w-full h-full"></div>
          {ctx.dialog.content}
        </div>
      );
    case "DialogSwitch":
      return (
        <DialogSwitch
          content={ctx.dialog.content as string}
          closeHandler={() => {
            closeHandler();
            if (ctx.dialog.onClickOuter) {
              ctx.dialog.onClickOuter();
            }
          }}
          onClickYes={ctx.dialog.onClickYes}
        />
      );
    default:
      return null;
  }
};

export default DialogContainer;
