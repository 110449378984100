import { MetaType, SampleMetaType } from "./types";

import ClampLines from "react-clamp-lines";
import Slider from "react-slick";

const Intro = ({
  username,
  meta,
  days,
  covidFree,
}: {
  username: string;
  covidFree?: boolean;
  meta: MetaType | SampleMetaType;
  days: string;
}) => {
  return (
    <div className="relative w-full max-w-3xl rounded-md break-all">
      <div
        className="w-full overflow-y-auto rounded-t-md bg-white pt-5 px-4"
        style={{
          maxHeight: "calc(100vh - 15rem)",
        }}
      >
        <div className="text-center font-medium">
          <div>
            <span className="text-yoda-secondary">{username}</span>님만을 위한
          </div>
          <div>{`${days} ${meta.location} 여행 디자인`}</div>
        </div>
        <div className="flex flex-wrap my-1.5 items-center">
          {covidFree && (
            <div
              key={"covid"}
              className="h-8 mr-1 my-1 px-2.5 text-xs flex items-center rounded-full border border-yoda-primary text-yoda-primary"
            >
              #안심여행지
            </div>
          )}
          {meta.tags &&
            meta.tags.split(",").map((tag) => (
              <div
                key={tag}
                className="h-8 mr-1 my-1 px-2.5 text-xs flex items-center rounded-full border border-gray-400 text-gray-400"
              >
                {`#${tag}`}
              </div>
            ))}
        </div>
        <ClampLines
          id="curation-point"
          className="text-gray-400 text-sm"
          text={meta.descriptions ? meta.descriptions.toString() : ""}
          lines={4}
          ellipsis={"…"}
          buttons={true}
          moreText={"더보기"}
          lessText={"숨기기"}
        />
        <div className="mt-5 w-full text-center">{`${username}님의 이번 여행 하이라이트`}</div>
        <div className="w-full pb-8">
          <Slider
            arrows={false}
            className="mt-2 mx-auto w-full h-64 sm:h-96 shadow-md"
            dots={true}
          >
            {meta.highlights ? (
              meta.highlights.map((highlight, index) => (
                <div className="outline-none">
                  <img
                    src={highlight.mainImageUrl}
                    className="w-full h-40 sm:h-72 object-cover"
                  />
                  <div className="px-2.5">
                    <div className="mt-4 text-gray-900 text-sm">
                      {highlight.name}
                    </div>
                    <ClampLines
                      key={index}
                      id={index.toString()}
                      className="mt-1.5 text-gray-400 text-xs"
                      text={highlight.description}
                      lines={2}
                      ellipsis={"…"}
                      buttons={false}
                    />
                  </div>
                </div>
              ))
            ) : (
              <div></div>
            )}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Intro;
