const PoiBlog = ({
  title,
  link,
  thumbnail,
  id,
}: {
  title: string;
  link: string;
  thumbnail: string;
  id: string;
}) => {
  return (
    <div className="font-medium text-sm mt-3 w-full px-4">
      <a href={link} target="_blank">
        <div className="flex w-full mb-3">
          <div className="flex-1 min-w-0 text-sm mr-3 sm:mr-10 flex flex-col">
            <div className="font-medium text-sm mt-3 truncate">{title}</div>
            <div className="flex-1"></div>
            <div className="link-clamp-2 font-normal text-xs text-gray-400 truncate">
              {link}
            </div>
          </div>
          <img
            className="w-24 h-14 object-cover rounded mt-2"
            src={thumbnail}
            alt="thumbnail"
          ></img>
        </div>
      </a>
    </div>
  );
};

export default PoiBlog;
