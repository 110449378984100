import { FavoriteType, HateCategoryType, updateSurvey } from "../api/Survey";
import { InterestType, ParentInterestType } from "./surveyOptions";

import { Option } from "../components/Inputs/Dropdown";
import { POIType } from "../components/Trip/types";
import { SurveyState } from "../contexts/SurveyContext";
import _ from "lodash";

const getTwoDigits = (num: number) => {
  if (num > 9) {
    return "" + num;
  }
  return "0" + num;
};

const isArraysSame = (array1: any[], array2: any[]) => {
  if (array1.length !== array2.length) {
    return false;
  }
  for (let i = 0; i < array1.length; i++) {
    if (!_.isEqual(array1[i], array2[i])) {
      return false;
    }
  }
  return true;
};

const defaultOptionState: { name: string; value: number | string } = {
  name: "",
  value: 0,
};
const getDefaultOption = (options: Option[], value: number | string) => {
  if (value === undefined) {
    return defaultOptionState;
  }
  const defaultOption = options.find((op) => op.value === value);
  if (defaultOption === undefined) {
    return defaultOptionState;
  }
  return defaultOption;
};
const getDefaultOptions = (options: Option[], values: string[]) => {
  const defaultOptions = options.filter(
    (op) => values.findIndex((com) => com === op.value) > -1
  );
  return defaultOptions;
};
const getInterestDefaultOptions = (
  values: string[],
  interestOptions: ParentInterestType[]
) => {
  const options: string[] = [];
  interestOptions.forEach((op) => {
    if (values.includes(op.value)) {
      options.push(op.value);
    } else if (op.children.length > 0) {
      op.children.forEach((chld) => {
        if (values.includes(chld.value)) {
          options.push(chld.value);
        }
      });
    }
  });
  return options;
};

const getSelectedInterests: (
  contextInterestTypes: string[],
  interestOptions: ParentInterestType[]
) => InterestType[] = (contextInterestTypes, interestOptions) => {
  const interests: InterestType[] = [];
  const interestTypes = getInterestDefaultOptions(
    contextInterestTypes,
    interestOptions
  );

  interestOptions.forEach((category) => {
    category.children.forEach((interest) => {
      interestTypes.findIndex((intr) => interest.value.includes(intr)) > -1 &&
        interests.push(interest);
    });
  });
  return interests;
};

const getDefaultInterestPriority = (
  contextInterestTypes: string[],
  contextInterestPriority: string[],
  interestOptions: ParentInterestType[]
) => {
  let interestPriority: string | undefined;
  getSelectedInterests(contextInterestTypes, interestOptions).forEach((op) => {
    if (contextInterestPriority.includes(op.value)) {
      interestPriority = op.value;
    } else if (op.children.length > 0) {
      op.children.forEach((chld) => {
        if (contextInterestPriority.includes(chld.value)) {
          interestPriority = op.value;
        }
      });
    }
  });
  return interestPriority;
};

const routingToSurvey = (survey: SurveyState) => {
  if (survey === undefined) {
    return "preview";
  }

  if (survey.destFromWeeklyContents) {
    return routingToSurveyFromWeekly(survey);
  }
  if (survey.gender > 0 || survey.age > 0) {
    return "step-5-1";
  } else if (
    survey.favoriteDrinkTypes !== undefined &&
    survey.favoriteDrinkTypes !== undefined &&
    survey.hateFoodTypes !== undefined
  ) {
    return "step-4-2";
  } else if (
    survey.isPopular !== undefined &&
    survey.isInsta !== undefined &&
    survey.isBusy !== undefined
  ) {
    return "step-4-1";
  } else if (
    survey.destName &&
    survey.destName.length > 0 &&
    survey.destType === "anything"
  ) {
    if (survey.destVisitedDegree && survey.destVisitedDegree > 0) {
      return "step-3-5";
    } else {
      return "step-3-4";
    }
  } else if (survey.interestTypes && survey.interestTypes.length > 0) {
    if (survey.interestPriority && survey.interestPriority.length > 0) {
      return "step-3-3";
    } else {
      return "step-3-2";
    }
  } else if (
    (survey.accommAddr && survey.accommAddr.length > 0) ||
    survey.accommCoords !== undefined
  ) {
    return "step-3-1-2";
  } else if (
    (survey.accommTypes && survey.accommTypes.length > 0) ||
    (survey.accommBudget && survey.accommBudget.length > 0)
  ) {
    return "step-3-1-1";
  } else if (survey.destVisitedDegree !== undefined) {
    return "step-2-5";
  } else if (
    survey.destDetermined &&
    survey.destName &&
    survey.destName.length > 0
  ) {
    return "step-2-4-5";
  } else if (
    survey.transportTypes &&
    survey.transportTypes.length > 0 &&
    survey.transportTimes &&
    survey.transportTimes.length > 0
  ) {
    return "step-2-4-4";
  } else if (survey.destAct > 0) {
    return "step-2-4-3";
  } else if (survey.destFood > 0) {
    return "step-2-4-2";
  } else if (survey.destCity && survey.destCity.length > 0) {
    return "step-2-4-1";
  } else if (survey.destType !== undefined) {
    return "step-2-3";
  } else if (
    survey.departLocation &&
    survey.departLocation.length > 0 &&
    survey.departCoords
  ) {
    return "step-2-1";
  } else if (
    survey.adults > 0 ||
    survey.kids > 0 ||
    survey.adultsAge.length > 0 ||
    survey.kidsAge.length > 0 ||
    survey.kidsMinAge > 0 ||
    survey.kidsMaxAge > 0
  ) {
    return "step-1-2";
  } else if (survey.dateFixed !== undefined) {
    if (survey.from || survey.to) {
      return "step-1-1-1";
    } else if (survey.fromMonth > -1 || survey.length > -1) {
      return "step-1-1-2";
    } else {
      return "step-1-1";
    }
  } else {
    return "preview";
  }
};
const routingToSurveyFromWeekly = (survey: SurveyState) => {
  if (
    survey.dateFixed === undefined ||
    (survey.from === "" &&
      survey.to === "" &&
      survey.fromMonth === 0 &&
      survey.length === 0)
  ) {
    return "step-1-1";
  } else if (
    (survey.adults === 0 && survey.kids === 0) ||
    (survey.adultsAge.length === 0 && survey.kidsAge.length === 0)
  ) {
    return "step-1-2";
  } else if (
    survey.departLocation === "" ||
    survey.departCoords === undefined
  ) {
    return "step-2-1";
  } else if (survey.destVisitedDegree === undefined) {
    return "step-2-5";
  } else if (
    _.isEqual(survey.accommTypes, []) ||
    _.isEqual(survey.accommBudget, [])
  ) {
    return "step-3-1-1";
  } else if (_.isEqual(survey.interestTypes, [])) {
    return "step-3-2";
  } else if (
    survey.isPopular === undefined ||
    survey.isInsta === undefined ||
    survey.isBusy === undefined
  ) {
    return "step-4-1";
  } else if (survey.gender === 0 || survey.age === 0) {
    return "step-5-1";
  } else {
    return "preview";
  }
};

const saveSurvey = async (id: string, body: SurveyState) => {
  const token = localStorage.getItem("accessToken");
  if (token && token.length > 0 && !isNaN(parseInt(id))) {
    await updateSurvey(id, body);
  } else {
    localStorage.setItem("survey", JSON.stringify(body));
  }
};

const isSurveyCompleted = (survey: SurveyState) => {
  if (survey.gender > 0 && survey.age > 0) {
    return true;
  }
  return false;
};

const getColorByType = (type: string) => {
  if (type === "관광스팟") return "yoda-todo";
  else if (type === "액티비티") return "yoda-act";
  else if (type === "숙소") return "yoda-accomm";
  else return "yoda-food";
};

const getPoiType = (poi: POIType) => {
  if (poi.poiType === "관광스팟" && poi.detail.tourspotType) {
    return poi.detail.tourspotType;
  } else if (poi.poiType === "액티비티" && poi.detail.activityType) {
    return poi.detail.activityType;
  } else if (poi.poiType === "숙소" && poi.detail.accommodationType) {
    return poi.detail.accommodationType;
  } else if (poi.detail.restaurantType) {
    return poi.detail.restaurantType;
  }
  return poi.poiType;
};

const getDays = (survey: SurveyState) => {
  let title = "";
  if (survey.from && survey.to) {
    const fromD = new Date(survey.from);
    const toD = new Date(survey.to);
    const days = (toD.getTime() - fromD.getTime()) / (24 * 60 * 60 * 1000);
    title = `${days === 0 ? "당일치기" : `${days}박${days + 1}일`}`;
  } else if (survey.length) {
    title = `${
      survey.length === 1
        ? "당일치기"
        : `${survey.length - 1}박${survey.length}일`
    }`;
  }
  return title;
};

const getSimpleDays = (days: number) =>
  // if 'days' is 'trip.length', needs 'trip.length + 1' of days
  days === 1 ? "당일치기" : `${days - 1}박 ${days}일`;

const getDiningText = (poiType: string, type: string | null) => {
  if (type === "act_night" && poiType == "식당") {
    return "술한잔";
  } else if (type === "eat") {
    return "식사";
  } else if (type === "eat_morning") {
    return "아침";
  } else if (type === "eat_afternoon") {
    return "점심";
  } else if (type === "eat_evening") {
    return "저녁";
  }
  return poiType;
};

const getToday = () => {
  const now = new Date();
  const today = `${now.getFullYear()}-${getTwoDigits(
    now.getMonth() + 1
  )}-${getTwoDigits(now.getDate())}`;
  const thisMonth = now.getMonth() + 1;
  return { dayString: today, thisMonth };
};

const getHateCategoryName = (name: string) => {
  if (name === "meat") return "육류";
  else if (name === "seafood") return "수산물";
  return "맛";
};

export {
  getTwoDigits,
  isArraysSame,
  defaultOptionState,
  getDefaultOption,
  getDefaultOptions,
  getInterestDefaultOptions,
  getSelectedInterests,
  getDefaultInterestPriority,
  routingToSurvey,
  saveSurvey,
  isSurveyCompleted,
  getColorByType,
  getPoiType,
  getDays,
  getDiningText,
  getToday,
  getSimpleDays,
  getHateCategoryName,
};
