import ReactGA from "react-ga";

/* eslint-disable */
const callMainPageTracking = () => {
  try {
    /*** 카카오 픽셀 ***/
    kakaoPixel(process.env.REACT_APP_KAKAO_PIXEL_TRACK_ID).pageView("Main");
    kakaoPixel(
      process.env.REACT_APP_KAKAO_PIXEL_TRACK_ID
    ).completeRegistration();

    /*** 페이스북 픽셀 ***/
    fbq("trackCustom", "Main");
  } catch (e) {}
};

const callSignupTracking = () => {
  try {
    /*** 네이버 ***/
    var _nasa = {};
    if (window.wcs) _nasa["cnv"] = wcs.cnv("2", 1); // 전환유형, 전환가치 설정해야함. 설치매뉴얼 참고
    // 공통
    if (!wcs_add) var wcs_add = {};
    wcs_add["wa"] = "s_4b1acbdd6cf";
    if (!_nasa) var _nasa = {};
    if (window.wcs) {
      wcs.inflow("yodatrip.com");

      wcs_do(_nasa);
    }

    /*** 카카오 픽셀 ***/
    kakaoPixel(process.env.REACT_APP_KAKAO_PIXEL_TRACK_ID).pageView();
    kakaoPixel(
      process.env.REACT_APP_KAKAO_PIXEL_TRACK_ID
    ).completeRegistration();

    /*** 페이스북 픽셀 ***/
    fbq("trackCustom", "Signup");
  } catch (e) {}
};

const callPageTracking = () => {
  try {
    /*** 네이버 ***/
    var _nasa = {};
    if (window.wcs) _nasa["cnv"] = wcs.cnv("5", 1); // 전환유형, 전환가치 설정해야함. 설치매뉴얼 참고
    // 공통
    if (!wcs_add) var wcs_add = {};
    wcs_add["wa"] = "s_4b1acbdd6cf";
    if (!_nasa) var _nasa = {};
    if (window.wcs) {
      wcs.inflow("yodatrip.com");

      wcs_do(_nasa);
    }

    /*** 카카오 픽셀 ***/
    kakaoPixel(process.env.REACT_APP_KAKAO_PIXEL_TRACK_ID).pageView();

    /*** 페이스북 픽셀 ***/
    fbq("track", "PageView");
  } catch (e) {}
};

const callPurchaseTracking = (price) => {
  try {
    /*** 네이버 ***/
    var _nasa = {};
    if (window.wcs) _nasa["cnv"] = wcs.cnv("1", "10"); // 전환유형, 전환가치 설정해야함. 설치매뉴얼 참고
    // 공통
    if (!wcs_add) var wcs_add = {};
    wcs_add["wa"] = "s_4b1acbdd6cf";
    if (!_nasa) var _nasa = {};
    if (window.wcs) {
      wcs.inflow("yodatrip.com");

      wcs_do(_nasa);
    }

    /*** 카카오 픽셀 ***/
    kakaoPixel(process.env.REACT_APP_KAKAO_PIXEL_TRACK_ID).pageView();
    kakaoPixel(process.env.REACT_APP_KAKAO_PIXEL_TRACK_ID).purchase();

    /*** 페이스북 픽셀 ***/
    fbq("track", "Purchase", { currency: "KRW", value: price });

    /*** GA ***/
    ReactGA.event({
      category: "일정표",
      action: "purchase",
      label: "일정표_구매",
      value: price,
    });
  } catch (e) {}
};

export {
  callMainPageTracking,
  callSignupTracking,
  callPageTracking,
  callPurchaseTracking,
};
