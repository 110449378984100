import "./mbti.css";

import {
  MBTIType,
  _getBackgroundColor,
  _getBackgroundTop,
  _getCityDesc,
  _getCityDescription,
  _getCityImg,
  _getCityName,
  _getHighlights,
  _getPersonalCharacter,
  _getPersonalCharacterImg,
  _getPersonalCharacterTitle,
  _getPersonalDescription,
  _getResultImg,
} from "./Descriptions";
import { Link as RouteLink, useHistory, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import Capture from "../../assets/MBTI/capture.png";
import CaptureGuide from "../../assets/MBTI/capture_guide.png";
import Carrier from "../../assets/MBTI/carrier.png";
import ClipboardJS from "clipboard";
import KakaoIcon from "../../assets/MBTI/kakao.png";
import Link from "../../assets/MBTI/link.png";
import Logo from "../../assets/MBTI/logo.png";
import ReactGA from "react-ga";
import Twitter from "../../assets/MBTI/twitter.png";

const MBTIarr = [
  "ESTP",
  "ESTJ",
  "ENFJ",
  "ENFP",
  "ENTJ",
  "ENTP",
  "ESFP",
  "ESFJ",
  "INTJ",
  "INTP",
  "INFP",
  "ISFJ",
  "ISTJ",
  "ISTP",
  "ISFP",
  "INFJ",
];
const Result = () => {
  const history = useHistory();
  const { mbti } = useParams<{ mbti: MBTIType }>();
  const [highlights, setHighlights] = useState<
    {
      name: string;
      description: string;
      insta: { link: string; authorName: string; thumbnailUrl: string }[];
    }[]
  >([]);

  const getPersonalCharacterImg = useCallback(() => {
    if (MBTIarr.indexOf(mbti) < 0) {
      return "";
    }

    return _getPersonalCharacterImg(mbti);
  }, [mbti]);

  const getPersonalDescription = useCallback(() => {
    if (MBTIarr.indexOf(mbti) < 0) {
      return "";
    }

    return _getPersonalDescription(mbti);
  }, [mbti]);

  const getBackgroundColor = useCallback(() => {
    if (MBTIarr.indexOf(mbti) < 0) {
      return "";
    }

    return _getBackgroundColor(mbti);
  }, [mbti]);

  const getTextColor = useCallback(() => {
    if (mbti === "INFP") return "#212121";
    return "#FFFFFF";
  }, [mbti]);

  const getCityImg = useCallback(() => {
    if (MBTIarr.indexOf(mbti) < 0) {
      return "";
    }

    return _getCityImg(mbti);
  }, [mbti]);

  const getCityDescription = useCallback(() => {
    if (MBTIarr.indexOf(mbti) < 0) {
      return "";
    }

    return _getCityDescription(mbti);
  }, [mbti]);

  const getResultImg = useCallback(() => {
    if (MBTIarr.indexOf(mbti) < 0) {
      return "";
    }

    return _getResultImg(mbti);
  }, [mbti]);

  useEffect(() => {
    const fetchHighlights = async () => {
      if (MBTIarr.indexOf(mbti) < 0) {
        setHighlights([]);
      } else {
        const hls = _getHighlights(mbti);
        const _highlights: {
          name: string;
          description: string;
          insta: { link: string; authorName: string; thumbnailUrl: string }[];
        }[] = [];
        for (const h of hls) {
          const insta: {
            link: string;
            thumbnailUrl: string;
            authorName: string;
          }[] = [];
          for (const i of h.insta) {
            insta.push({
              link: i.link,
              thumbnailUrl: i.thumbnailUrl,
              authorName: i.authorName,
            });
          }
          _highlights.push({
            name: h.name,
            description: h.description,
            insta,
          });
        }
        setHighlights(_highlights);
      }
    };
    fetchHighlights();
  }, [mbti]);

  useEffect(() => {
    const clipboard = new ClipboardJS(".btn");
    // @ts-ignore
    clipboard.on("success", function (e) {
      alert("테스트 주소가 복사되었습니다!");
      e.clearSelection();
    });
    return () => clipboard.destroy();
  }, []);

  if (MBTIarr.indexOf(mbti) < 0) {
    alert("잘못된 접근입니다.");
    history.push("/");
  }
  return (
    <div className="relative fixed top-0 sm:top-1/2 left-0 sm:left-2/3 h-full sm:max-h-full sm:h-208 w-full sm:w-96 sm:transform sm:-translate-y-1/2 sm:-translate-x-1/3 sm:rounded-3xl">
      <div className="w-full h-full flex flex-col items-center absolute top-0 left-0 sm:rounded-3xl bg-white text-center overflow-y-auto">
        <a
          href="https://yodareactnative.page.link/UkMX"
          className="self-end mr-4 mt-4"
        >
          <div className="bg-yoda-primary py-2 px-3 rounded-full text-white text-sm font-bold">
            여다로 가기
          </div>
        </a>
        <div className="w-full">
          <img src={getPersonalCharacterImg()} className="w-full h-auto" />
        </div>
        <div
          className="w-full px-10 sm:rounded-b-3xl"
          style={{
            backgroundColor: getBackgroundColor(),
            color: getTextColor(),
          }}
        >
          <div className="w-full whitespace-pre-wrap text-left text-base">
            {getPersonalDescription()}
          </div>
          <div
            className="mt-10 w-full flex items-center justify-start text-left pl-6 py-3 bg-white rounded-t-3xl"
            style={{
              marginLeft: "-2.5rem",
              width: "calc(100% + 5rem)",
            }}
          >
            <div
              className="border-l-4 mr-2 h-4 rounded"
              style={{ borderColor: getBackgroundColor() }}
            ></div>
            <div
              className="text-2xl font-bold text-white"
              style={{ color: getBackgroundColor() }}
            >
              나랑 잘 어울리는 여행지
            </div>
          </div>
        </div>
        <img className="w-full h-full" src={getCityImg()} />
        <div className="w-full px-6">
          <div
            className="w-full text-base mt-6 text-left whitespace-pre-line px-4"
            dangerouslySetInnerHTML={{ __html: getCityDescription() }}
          ></div>
          <div className="w-full mt-12 px-12">
            <div
              className="border-2 rounded"
              style={{ borderColor: getBackgroundColor() }}
            ></div>
          </div>
          {highlights.map((highlight: any) => (
            <div className="mt-8 mb-4 w-full">
              <div className="mt-10 w-full flex items-center justify-start text-left">
                <div
                  className="border-l-4 mr-2 h-4 rounded"
                  style={{ borderColor: getBackgroundColor() }}
                ></div>
                <div
                  className="text-2xl font-bold"
                  style={{ color: getBackgroundColor() }}
                >
                  {highlight.name}
                </div>
              </div>
              <div
                className="flex w-full overflow-x-auto mt-3 h-64"
                style={{ marginLeft: "-1.5rem", width: "calc(100% + 3rem)" }}
              >
                {highlight.insta.map((insta: any, index: number) => (
                  <a
                    className={`inline-block flex-shrink-0 ${
                      index === 0 ? "ml-6" : "ml-3"
                    } ${index === highlight.insta.length - 1 ? "pr-6" : ""}`}
                    href={insta.link}
                    target="_blank"
                  >
                    <div className="w-60 h-60 relative">
                      <img
                        className="w-full h-full rounded-md object-cover"
                        src={insta.thumbnailUrl}
                      />
                      <div className="absolute bottom-2 right-2 text-white text-xs">
                        {insta.authorName}
                      </div>
                    </div>
                  </a>
                ))}
              </div>
              <div
                className="w-full mt-3 text-left bg-yoda-gray-0 rounded-2xl py-3 px-4"
                dangerouslySetInnerHTML={{ __html: highlight.description }}
              ></div>
            </div>
          ))}
        </div>
        <a
          className="w-full"
          href="https://yodareactnative.page.link/UkMX"
          onClick={() =>
            ReactGA.event({
              category: "MBTI유입",
              action: "from_mbti",
              label: "MBTI_유입",
            })
          }
        >
          <div
            className="w-full mt-8 pt-8 pb-10"
            style={{ backgroundColor: "#A7CC66" }}
          >
            <img src={Logo} className="mx-auto w-9 h-auto" />
            <div
              className="mt-7 text-2xl text-white font-bold"
              style={{ textShadow: "0px 3px 6px #00000031" }}
            >
              내 성향과 딱 맞는 여행일정,
              <br />
              여다가 3분만에 만들어드려요!
            </div>
            <div className="mt-6 w-full px-12">
              <img className="w-full" src={Carrier} />
            </div>
            <div className="w-full h-14 px-6 mt-12 flex items-center justify-center">
              <div
                className="bg-white shadow-lg rounded-full text-lg font-bold flex items-center justify-center sizing-animate"
                style={{ color: "#326E06" }}
              >
                여다에서 나만의 여행일정 만들기
              </div>
            </div>
          </div>
        </a>
        <div className="w-full px-6">
          <div className="w-full mt-8 px-12">
            <div
              className="border-2 rounded"
              style={{ borderColor: getBackgroundColor() }}
            ></div>
          </div>
          <div
            className="mt-8 text-xl font-bold"
            style={{ color: getBackgroundColor() }}
          >
            친구와 테스트 공유하기
          </div>
          <div className="mt-6 w-full flex items-center justify-between">
            <a href={getResultImg()} download={mbti} title={mbti}>
              <img className="w-16 h-16" src={Capture} />
            </a>
            <a
              href="https://twitter.com/intent/tweet?text=<MBTI 여다 여행성향 테스트> 나를 부르는 국내 여행지는 어디? 여다와 함께 알아봐요!&url=https://yodatrip.com/mbti&hashtags=여행테스트,여행성향테스트,여행을열다,여다"
              target="_blank"
            >
              <img className="h-16 w-16" src={Twitter} />
            </a>
            <button
              className="h-16 w-16"
              onClick={() => {
                // @ts-ignore
                if (!Kakao.isInitialized()) {
                  // @ts-ignore
                  Kakao.init(process.env.REACT_APP_KAKAO_CLIENT_ID);
                }
                // @ts-ignore
                Kakao.Link.sendDefault({
                  objectType: "feed",
                  content: {
                    title: "나를 부르는 국내여행지는 어디?",
                    // @ts-ignore
                    description: "여다와 함께 나의 여행성향 알아보기",
                    imageUrl: `https://yodatrip-public-assets.s3.ap-northeast-2.amazonaws.com/mbti-sounds/${mbti}_thumbnail.png`,
                    imageWidth: 800,
                    imageHeight: 800,
                    link: {
                      mobileWebUrl: `https://yodatrip.com/mbti`,
                    },
                  },
                  buttons: [
                    {
                      title: "결과보기",
                      link: {
                        webUrl: `https://yodatrip.com/mbti/result/${mbti}`,
                        mobileWebUrl: `https://yodatrip.com/mbti/result/${mbti}`,
                      },
                    },
                    {
                      title: "나도하기",
                      link: {
                        webUrl: "https://yodatrip.com/mbti",
                        mobileWebUrl: "https://yodatrip.com/mbti",
                      },
                    },
                  ],
                });
              }}
            >
              <img className="w-full h-full" src={KakaoIcon} />
            </button>
            <div className="h-16 w-16">
              <button
                className="btn"
                data-clipboard-text={`https://yodatrip.com/mbti/result/${mbti}`}
              >
                <img className="w-full h-full" src={Link} />
              </button>
            </div>
          </div>
          <img className="mt-2 ml-4 h-6 w-auto" src={CaptureGuide} />
          <div className="w-full mb-10">
            <RouteLink to="/mbti">
              <div
                className="mt-10 shadow-lg w-full h-14 rounded-full text-lg text-white font-bold flex items-center justify-center"
                style={{ backgroundColor: getBackgroundColor() }}
              >
                테스트 다시하기
              </div>
            </RouteLink>
          </div>
        </div>
      </div>
      <div
        className={`absolute w-full h-full top-0 left-0 sm:shadow-2xl sm:rounded-3xl`}
        style={{ zIndex: -2 }}
      ></div>
    </div>
  );
};

export default Result;
